import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {ReactComponent as ArrowDown} from "@assets/img/arrow_down.svg";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as createTariffByLocationsActions} from "@sagas/tariff/tariffByLocations/create";
import DeleteModal from "@common/modal/deleteModal/deleteModal";
import HtmlTooltip from "@common/htmlTooltip";
import {actions as locationTariffActions} from "@sagas/tariff/tariffByLocations/index";
import {CopyIcon, DeleteRowIcon, EditRowIcon} from "@assets/SvgComponents";
import ModalComponent from "@common/modal";
import ButtonComponent from "@common/button";
import {useUrlParams} from "@src/utils/useUrlParams";

const LocationAccordion = (props) => {
	const {
		children,
		name,
		match,
		classNames,
		accordionClassNames,
		accordionDatesClassNames,
		accordionHeaderClassNames,
		accordionLocationsClassNames,
		accordionItemsClassNames,
		accordioniconsClassNames,
		id,
		setLocationId,
		from,
		to,
		locations,
		deleteTarrifTableOnState,
		index,
		item,
		setShowModal,
		fillTarriffTable,
		disable,
		deleteTarrifTable,
		deleteLocationFromArray,
		receivedCommission,
		accordionCommissionClassNames,
		showReceivedCommission = true,
		addDeletedtableOnState,
		expandable,
	} = props;
	const {t} = useTranslation();
	const {setModal, getUrlParams} = useUrlParams();
	const {modal, id: modalId} = getUrlParams("modal", "id");

	const click = (id) => {
		if (setLocationId) {
			setLocationId([id]);
		}
	};
	const handleDelete = (item) => {
		if (item?.isNew) {
			deleteTarrifTableOnState(item);
			addDeletedtableOnState(item);
		} else {
			deleteLocationFromArray(index);
			deleteTarrifTable({
				id: `${modalId}`,
				tariffId: match.params.tariffId,
				index,
			});
		}
		handleModal(false);
	};

	const handleEdit = (copy) => {
		if (copy) {
			setShowModal("copy", modalId);
		} else {
			setShowModal("table");
			fillTarriffTable({...item, index});
		}
	};
	const handleModal = (open, modalId) => setModal(open, modalId);

	return (
		<div className={classNames} onClick={() => click([id])}>
			{`${modalId}` === item?.tableUUID && (
				<DeleteModal
					setShowDeleteModal={() => handleModal(false)}
					confirm={() => handleDelete(item)}
					open={modal === "delete"}
					handleClose={() => handleModal(false)}
				/>
			)}
			<ModalComponent open={modal === "copyModal"} handleClose={() => handleModal(false)}>
				<div className="delete__modal__component">
					<h1>{t("AreUSureCopy")}</h1>
					<div className="delete__modal__component__button">
						<ButtonComponent
							classNames="cancel__button"
							buttonText={t("Cancel")}
							onClick={() => {
								handleModal(false);
							}}
						/>
						<ButtonComponent
							classNames="confirm__button"
							buttonText={t("Confirm")}
							onClick={() => {
								handleEdit(true);
							}}
						/>
					</div>
				</div>
			</ModalComponent>
			<Accordion expanded={!expandable ? false : undefined}>
				<AccordionSummary
					expandIcon={expandable && <ArrowDown />}
					aria-controls="panel1a-content"
					className={accordionClassNames}
				>
					<div className={accordionHeaderClassNames}>
						<Typography>{name}</Typography>
						{showReceivedCommission && (
							<div className={accordionCommissionClassNames}>
								<span>{`${receivedCommission?.toFixed(2)} €`}</span>
							</div>
						)}
						{/* <Typography>{t('locations')}:</Typography> */}
						<div className={accordionLocationsClassNames}>
							<div className={accordionItemsClassNames}>
								{locations?.length < 5 ? (
									locations?.map((item, index) => (
										<span key={index}>#{item?.name}</span>
									))
								) : (
									<HtmlTooltip
										placement="bottom-end"
										title={locations?.map((item, index) => (
											<span
												style={{width: 400, color: "#0E88F2"}}
												key={index}
											>
												{`#${item?.name}, `}
											</span>
										))}
									>
										<div className={accordionItemsClassNames}>
											{locations?.map(
												(item, index) =>
													index < 5 && (
														<span key={index}>#{item?.name}</span>
													),
											)}
											<span>...</span>
										</div>
									</HtmlTooltip>
								)}
							</div>
						</div>
						{/* eslint-disable */}
						{to === null && from === null ? null : (
							<div className={accordionDatesClassNames}>
								<span>{from ? moment(from).format('DD/MM/YYYY') : ''}</span>
								{isNaN(to) || to === null ? (
									<span>{''}</span>
								) : (
									<>
										<span>{t('Until')}</span>
										<span>{moment(to).format('DD/MM/YYYY')}</span>
									</>
								)}
							</div>
						)}
						{/* eslint-enable */}
						{!disable && (
							<div className={accordioniconsClassNames}>
								{item?.tableUUID && (
									<div style={{display: "flex"}}>
										<HtmlTooltip
											placement="bottom"
											title={<span>{t("Copy")}</span>}
										>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}
												onClick={() =>
													handleModal("copyModal", item?.tableUUID)
												}
											>
												<CopyIcon width={20} height={20} />
											</div>
										</HtmlTooltip>
									</div>
								)}
								<div>
									<HtmlTooltip
										placement="bottom"
										title={<span>{t("Delete")}</span>}
									>
										<div onClick={() => handleModal("delete", item?.tableUUID)}>
											<DeleteRowIcon />
										</div>
									</HtmlTooltip>
								</div>
								<HtmlTooltip placement="bottom" title={<span>{t("Edit")}</span>}>
									<span onClick={() => setShowModal("table", item?.tableUUID)}>
										<EditRowIcon />
									</span>
								</HtmlTooltip>
							</div>
						)}
					</div>
				</AccordionSummary>
				{children}
			</Accordion>
		</div>
	);
};

const mapDispatchToProps = {
	deleteTarrifTableOnState: createTariffByLocationsActions.deleteTarrifTableOnState,
	deleteTarrifTable: createTariffByLocationsActions.deleteTarrifTable,
	fillTarriffTable: createTariffByLocationsActions.fillTarriffTable,
	deleteLocationFromArray: createTariffByLocationsActions.deleteLocationFromArray,
	addDeletedtableOnState: locationTariffActions.addDeletedtableOnState,
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocationAccordion));
