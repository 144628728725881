import * as yup from "yup";
import i18n from "@src/i18n";
import _ from "lodash";
const FILE_SIZE = 1024 * 1024;

export const billingDetailsSchema = yup.object().shape({
	tenantLogo: yup
		.mixed()
		.test("fileSize", i18n.t("FileSizeError1MB"), (value) => {
			if (value && value.size > FILE_SIZE) {
				return false;
			}
			return true;
		})
		.nullable(),
});

export const publicProfileSchema = yup.object().shape({
	firstName: yup
		.string()
		.min(2, i18n.t("TooShort"))
		.max(50, i18n.t("TooLong"))
		.required(i18n.t("FirstNameIsReq"))
		.label(i18n.t("firstName")),
	lastName: yup
		.string()
		.min(2, i18n.t("TooShort"))
		.max(50, i18n.t("TooLong"))
		.label(i18n.t("lastName")),
	city: yup.string().min(2, i18n.t("TooShort")).max(50, i18n.t("TooLong")).label(i18n.t("City")),
	phoneNumber: yup
		.string()
		.min(2, i18n.t("TooShort"))
		.max(50, i18n.t("TooLong"))
		.label(i18n.t("PhoneNumber")),
});

const publicProfileInputs = [
	{
		label: i18n.t("FirmaName"),
		disabled: true,
		field: "tenantName",
		parent: "CompanyName",
	},
	{
		label: i18n.t("FirstName"),
		field: "firstName",
		parent: "PersonalInformation",
	},
	{
		label: i18n.t("LastName"),
		field: "lastName",
		parent: "PersonalInformation",
	},
	{
		label: i18n.t("City"),
		field: "city",
		parent: "PersonalInformation",
	},
	{
		label: i18n.t("PhoneNumber"),
		field: "phoneNumber",
		parent: "PersonalInformation",
		inputType: "number",
	},
];

export const groupedInputs = _.groupBy(publicProfileInputs, "parent");

const WIDTH_VALUES = {
	"1/3": "calc((100% / 3) - 20px)",
	"1/2": "calc((100% / 2) - 20px)",
};

const billingDetailsInputs = [
	{
		label: i18n.t("Title"),
		field: "title",
		width: WIDTH_VALUES["1/3"],
		parent: 1,
	},
	{
		label: i18n.t("CompanyName"),
		field: "companyName",
		width: WIDTH_VALUES["1/3"],
		parent: 1,
	},
	{
		label: i18n.t("Address"),
		field: "address",
		width: WIDTH_VALUES["1/3"],
		parent: 1,
	},
	{
		label: i18n.t("PhoneNumber"),
		field: "phoneNumber",
		width: WIDTH_VALUES["1/2"],
		parent: 2,
	},
	{
		label: i18n.t("EmailAddress"),
		field: "email",
		width: WIDTH_VALUES["1/2"],
		parent: 2,
	},
	{
		label: i18n.t("BankAccount"),
		field: "bankNumber",
		width: WIDTH_VALUES["1/2"],
		parent: 3,
	},
	{
		label: i18n.t("Bank Name"),
		field: "bankName",
		width: WIDTH_VALUES["1/2"],
		parent: 3,
	},
	{
		label: i18n.t("SWIFT-Code"),
		field: "swiftCode",
		width: WIDTH_VALUES["1/2"],
		parent: 3,
	},
	{
		label: i18n.t("BIC-Code"),
		field: "bicCode",
		width: WIDTH_VALUES["1/2"],
		parent: 3,
	},
	{
		label: i18n.t("taxNumber"),
		field: "taxNumber",
		width: WIDTH_VALUES["1/2"],
		parent: 4,
	},
	{
		label: i18n.t("ManagingDirectors"),
		field: "firstDirector",
		width: WIDTH_VALUES["1/2"],
		parent: 5,
	},
	{
		label: i18n.t("ManagingDirectors"),
		hideLabel: true,
		field: "secondDirector",
		width: WIDTH_VALUES["1/2"],
		parent: 5,
	},
	{
		label: i18n.t("Jurisdiction"),
		field: "firstJurisdiction",
		width: WIDTH_VALUES["1/2"],
		parent: 6,
	},
	{
		label: i18n.t("Jurisdiction"),
		hideLabel: true,
		field: "secondJurisdiction",
		width: WIDTH_VALUES["1/2"],
		parent: 6,
	},
	{
		label: i18n.t("CompanyWebsite"),
		field: "website",
		width: WIDTH_VALUES["1/2"],
		parent: 7,
	},
];

export const groupedBillingDetailsInputs = _.groupBy(billingDetailsInputs, "parent");

export const colorPickers = [
	{
		title: i18n.t("mainColor"),
		field: "primaryColor",
		defaultValue: "#1F2147",
	},
	{
		title: i18n.t("secondaryColor"),
		field: "secondaryColor",
		defaultValue: "#31508A",
	},
	{
		title: i18n.t("hoverColor"),
		field: "hoverColor",
		defaultValue: "#C5D5F3",
	},
];
export const emailTemplatesElems = [
	{
		label: i18n.t("RegisterTemplate"),
		value: "registerTemplate",
	},
	{
		label: i18n.t("RestoreTemplate"),
		value: "restoreTemplate",
	},
];

const emailInputs = [
	{
		title: i18n.t("EmailAddress"),
		inputType: "text",
		required: true,
		field: "email",
	},
];

const passwordInputs = [
	{
		title: i18n.t("OldPassword"),
		inputType: "password",
		field: "currentPassword",
		required: true,
	},
	{
		title: i18n.t("NewPassword"),
		inputType: "password",
		field: "password",
		required: true,
	},
	{
		title: i18n.t("ConfirmPassword"),
		inputType: "password",
		field: "confirmPassword",
		matchField: "password",
		required: true,
	},
];

export const modalData = {
	recoverPassword: {
		inputs: emailInputs,
		title: i18n.t("RecoverPassword"),
	},
	email: {
		inputs: emailInputs,
		title: i18n.t("ChangeEmail"),
	},
	password: {
		inputs: passwordInputs,
		title: i18n.t("ChangePassword"),
	},
};

export const MODALS = ["email", "password", "recoverPassword"];

export const billingDetailsValidationSchema = yup.object().shape({
	generateSettlementId: yup
		.string()
		.matches(/^(?:[^,\s]+|\s*,\s*)+$|^$/, i18n.t("settlementNumberFormat"))
		.nullable(), // Allows empty values
});
