/* eslint-disable max-len */
import React, {useState, useRef, useEffect, useMemo} from "react";
import BigSearchBar from "@common/bigSearchBar/BigSearchBar";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {actions as usersActions} from "@sagas/users/users";
import {actions as userActions} from "@sagas/users/users/create";
import {actions as deleteUserActions} from "@sagas/users/users/delete";
import {actions as deletedUsersActions} from "@sagas/users/users/deleted";
import {actions as restoreUsersActions} from "@sagas/users/users/restore";
import {useTranslation} from "react-i18next";
import {actions as createTeamActions} from "@sagas/teams/create";
import {actions as teamActions} from "@sagas/teams";
import {actions as deleteTeamActions} from "@sagas/teams/delete";
import {actions as salesOrgActions} from "@sagas/salesOrganizations/index";
import {actions as navigation} from "@sagas/navigation";
import _ from "lodash";
import {checkRoles} from "@utils/checkRoles";
import HtmlTooltip from "@common/htmlTooltip";
import Button from "@common/components/button";
import Tabs from "@common/components/tabs";
import {DownloadFile, TeamPlusIcon, UserPlusIcon, UsersIcon} from "@assets/SvgComponents";
import {useUrlParams} from "@src/utils/useUrlParams";
import {Dropdown} from "antd";
import Form from "@src/common/Form";
import {Storage} from "@src/utils/local-storage";
import ReactToPrint from "react-to-print";
import PrintTable from "@src/common/components/print-table";
import plusIcon from "../../../assets/img/plusIconBackground.svg";
import {usersColumnsCSV} from "../columns";
import DeleteModal from "../../../common/modal/deleteModal/deleteModal";
import downloadIcon from "../../../assets/img/downloadicon.svg";
import Monitoring from "../Monitoring";
import Table from "./Table";
import ConditionsWrapper from "./ConditionsWrapper";
import {exportCsvHandler, allRoles} from "./helper";
import SalesOrganizations from "./SalesOrganizations";
import Structures from "../Structures";
import {formatGroupOptions} from "../sales-tracker/components/helper";
import {salesorgColumns, salesorgMembersColumns} from "./SalesOrganizations/helper";
import {baseTabsArray} from "./staticData";

const GET_USER_SP_ROLES = ["AGENCY"];

function Users(props) {
	const {
		getUsers,
		deleteUser,
		restoreUser,
		restoreUsersData,
		deletedUsersData,
		fetchDeletedUsers,
		setSelectedUsers,
		getUsersSp,
		addTeam,
		getTeams,
		teamsEditSize,
		deleteTeam,
		navigate,
		fillUsers,
		getEmployeeSp,
		deactivateUser,
		getUsersNoTeam,
		fetchAgencies,
		deleteLoading,
		teamLoading,
		clearTeamValues,
		editPage,
		editPageDeleted,
		teamsEditPage,
		getTeamById,
		salesOrgState,
		createSalesOrganization,
		getSalesOrganizationsById,
		deleteSalesOrganization,
		editSalesOrganization,
		getUserDropdown,
		usersState,
		teamsState,
		createTeamsState,
		editAgency,
	} = props;
	const {
		endpointPermissions,
		usersOptions: usersOptionsDropdown,
		loggedUser,
		noTeamUsers,
		loading,
		page,
		size,
		totalSize,
	} = usersState;
	const {teams, agencies, page: teamsPage, size: teamsSize, teamsModalLoading} = teamsState;
	const {teamInitialValues, loading: createLoading} = createTeamsState;
	const {modalLoading, singlesalesOrganization} = salesOrgState;

	const {t} = useTranslation();
	const wrapperRef = useRef();
	const componentRef = useRef();
	const [groupedOptions, setGroupedOptions] = useState([]);
	const [membersOptions, setMembersOptions] = useState([]);
	const [agencySpInitialValues, setAgencySpInitialValues] = useState(null);
	const tenantFeatures = Storage.get("features");
	const tabs = useMemo(() => {
		const newTabsArray = [];
		if (checkRoles("seeUsers")) {
			newTabsArray.push("users");
		}
		if (checkRoles("seeTeams")) {
			newTabsArray.push("teams");
		}
		if (checkRoles("seeAgencies")) {
			newTabsArray.push("agency");
		}
		if (checkRoles("addConditions")) {
			newTabsArray.push("Conditions", "Monitoring");
		}
		if (checkRoles("seeSalesOrganizations") && tenantFeatures?.Sales_Organization) {
			newTabsArray.push("SalesOrganizations");
		}
		if (checkRoles("seeEnergy") && tenantFeatures?.Energy) {
			newTabsArray.push("DistributionChannel");
		}
		return newTabsArray;
	}, [checkRoles, tenantFeatures]);

	const resetPage = () => {
		if (tab === "users") {
			if (tableTab === "active") {
				editPage(1);
			} else {
				editPageDeleted(1);
			}
		} else {
			teamsEditPage(1);
		}
	};
	const {getUrlParams, setUrlParams, removeParams, setQuery, setModal} = useUrlParams(resetPage);
	const {tab, tableTab, conditionType, query, modal, id, userName, agencyId} = getUrlParams(
		"tab",
		"table-tab",
		"condition-type",
		"query",
		"modal",
		"id",
		"userName",
		"agencyId",
	);

	const tableRef = useRef();
	const [reEditPage, setReEditPage] = useState(true);
	useEffect(() => {
		if (tab === "users" || !tab) {
			setUrlParams(
				"users",
				"tab",
				{
					paramName: "table-tab",
					paramValue: tableTab || "active",
				},
				{
					paramName: "query",
					paramValue: {search: ""},
				},
			);
		}
		if (tab === "users" || tab === "SalesOrganizations") {
			getTeams({isTeam: false});
		}
		if (tab !== "Conditions") {
			getUsersSp();
			getUsersNoTeam();
			// call this endpoint if role is not included in GET_USER_SP_ROLES
			if (!_.isEmpty(loggedUser) && !loggedUser?.role?.name?.includes(GET_USER_SP_ROLES)) {
				getEmployeeSp();
			}
		}
		getUserDropdown(allRoles?.map((item) => item?.name));
		if (modal === "salesOrg" && id) {
			getSalesOrganizationsById(id);
		}

		return () => {
			resetPage();
		};
	}, [loggedUser, tab]);
	const handleSearch = (value) => setQuery({search: value.target.value});
	useEffect(() => {
		if (tab === "users") {
			if (tableTab === "active") {
				getUsers({search: query?.search});
			}
		} else {
			teamsEditSize(30);
		}
	}, [page, size, query?.search, tab, tableTab]);
	useEffect(() => {
		if (tab === "agency" || tab === "SalesOrganizations") {
			fetchAgencies({isTeam: true, search: query?.search});
		} else if (tab === "teams" || tab === "SalesOrganizations") {
			getTeams({isTeam: false, search: query?.search});
		}
	}, [tab, teamsSize, teamsPage, query?.search]);
	useEffect(() => {
		const items = formatGroupOptions(teams, agencies, usersOptionsDropdown, "obj");
		setGroupedOptions(items);
	}, [teams, agencies, usersOptionsDropdown]);
	useEffect(() => {
		if (tableTab === "inactive") {
			fetchDeletedUsers({search: query?.search});
		}
	}, [
		tableTab,
		deletedUsersData?.page,
		deletedUsersData?.size,
		deletedUsersData?.totalSize,
		query?.search,
	]);

	const teamInputs = [
		{
			title: t("name"),
			field: "name",
			inputType: "text",
			required: true,
		},
		{
			title: [t("StartDate"), t("EndDate")],
			field: null,
			fields: ["from", "to"],
			inputType: "rangePicker",
			required: [true, false],
			picker: "date",
			type: "day",
			disabled: [id, false],
			disableEndDateOnEdit: id,
		},
		{
			title: t("HeadOfDepartment"),
			placeholder: t("HeadOfDepartment"),
			value: "name",
			field: "headOfDepartment",
			inputType: "teamDropdown",
			options: usersOptionsDropdown
				?.map((item) => ({
					...item,
					name: `${item?.firstName} ${item?.lastName}`,
					value: `${item?.firstName} ${item?.lastName}`,
					key: item?.id || item?.userId,
				}))
				.filter((item) =>
					endpointPermissions.makeHeadOfDepartment?.includes(item?.role?.id),
				),
			multiple: false,
			required: false,
			show: true,
			inputValue: "obj",
			filterOptions: "teamMembers",
			dependentFrom: "from",
			getOptionsBy: ["from", "to"],
			optionFilterProp: "value",
			entityToGetOptionsBy: teams,
		},
		{
			title: t("Members"),
			placeholder: t("AddUser"),
			field: "teamMembers",
			inputType: "teamDropdown",
			options: usersOptionsDropdown
				?.map((item) => ({
					...item,
					name: `${item?.firstName} ${item?.lastName}`,
					value: `${item?.firstName} ${item?.lastName}`,
					key: item?.id || item?.userId,
				}))
				.filter((item) =>
					endpointPermissions.makeHeadOfDepartment?.includes(item?.role?.id),
				),
			required: true,
			multiple: true,
			customRender: true,
			inputValue: "obj",
			dependentFrom: "from",
			filterOptions: "headOfDepartment",
			newClassName: "salesOrg__select",
			getOptionsBy: ["from", "to"],
			optionFilterProp: "value",
			entityToGetOptionsBy: teams,
		},
	];
	const inputs = [
		{
			title: t("name"),
			field: "name",
			inputType: "text",
			required: true,
		},
		{
			title: [t("StartDate"), t("EndDate")],
			field: null,
			fields: ["startDate", "endDate"],
			inputType: "rangePicker",
			required: [true, false],
			picker: "date",
			type: "day",
		},
		{
			title: t("HeadOfSales"),
			placeholder: t("SetHeadOfSales"),
			value: "name",
			field: "headOfSales",
			inputType: "teamDropdown",
			options: usersOptionsDropdown
				?.map((item) => ({
					...item,
					name: `${item?.firstName} ${item?.lastName}`,
					value: `${item?.firstName} ${item?.lastName}`,
					key: item?.id || item?.userId,
				}))
				.filter(
					(item) =>
						item?.role?.name !== "AGENCY-SP" ||
						item?.role?.name !== "MANAGEMENTINFO" ||
						item?.role?.name !== "BACK-OFFICE",
				),
			multiple: false,
			required: true,
			show: true,
			inputValue: "obj",
			filterOptions: "salesOrganizationUsers",
			optionFilterProp: "value",
		},
		{
			title: t("Members"),
			placeholder: t("AddUser"),
			field: "salesOrganizationUsers",
			inputType: "groupedSelect",
			options: groupedOptions,
			required: true,
			multiple: true,
			customRender: true,
			inputValue: "obj",
			dependentFrom: "headOfSales",
			filterOptions: "headOfSales",
			newClassName: "salesOrg__select",
		},
	];
	const agencyInputs = [
		{
			field: "spName",
			title: t("FullName"),
			inputType: "text",
			required: true,
			disabled: true,
		},
		{
			inputType: "checkbox",
			field: "isGroupLeader",
			title: t("MakeGroupLeader"),
			dependentFields: ["agencySpUsers"],
		},
		{
			title: t("Members"),
			field: "agencySpUsers",
			inputType: "dropdown",
			required: true,
			multiple: true,
			options: membersOptions,
			dependentFrom: "isGroupLeader",
			dependentFromValue: [true],
		},
	];
	const openTeamModal = () => {
		clearTeamValues();
		const data = tableRef.current?.dataManager?.data?.filter(
			(e) => e?.tableData?.checked === true,
		);
		setSelectedUsers(data);
		setModal("team");
	};

	const createTeamAndClearUser = () => {
		navigate("/admin/users/create");
		fillUsers({});
	};
	useEffect(() => {
		if ((tab === "teams" || tab === "users") && noTeamUsers?.length > 0) {
			if (modal === "team" && id) {
				getTeamById(id);
			}
		}
		if (modal === "agency" && id) {
			const checkGroupLeaderId = (it, selectedUser) =>
				!it.groupLeaderId || it.groupLeaderId === selectedUser.id;
			const agency = agencies.find((i) => i.id === agencyId);
			const selectedUser = agency?.members?.find((i) => i.id === id);
			const membersOptions = agency?.members?.filter(
				(it) =>
					it.id !== agency.teamLeaderId &&
					it?.id !== id &&
					!it.isGroupLeader &&
					checkGroupLeaderId(it, selectedUser),
			);
			if (membersOptions && selectedUser) {
				const members = agency?.members.filter((i) => i.groupLeaderId === selectedUser.id);
				const {name, isGroupLeader, id} = selectedUser || null;
				setMembersOptions(membersOptions?.map((i) => ({...i, label: i.name, value: i.id})));
				setAgencySpInitialValues({
					spName: name,
					agencySpUsers: members?.map((i) => i.id) || [],
					isGroupLeader,
					agencyGroupLeaderId: id,
				});
			}
		}
	}, [modal, id, teams, tab, noTeamUsers?.length]);
	if (!tab || (tab === "users" && !tableTab)) return null;
	const handleTabClick = (item) => {
		if (item === tab) return;
		if (item === "users") {
			setUrlParams(item, "tab", {
				paramName: "table-tab",
				paramValue: "active",
			});
		} else {
			setUrlParams(item);
			removeParams("table-tab");
		}
		if (item !== "Conditions") {
			removeParams("condition-type");
		}
		if (item !== "Monitoring") {
			removeParams("monitoring-type");
		}
		setQuery({search: ""});
	};
	const items = [
		{
			key: "createUser",
			label: t("CreateUser"),
			onClick: () => createTeamAndClearUser(),
			icon: <UserPlusIcon stroke="#000" />,
		},
		...(tab !== "agency" && tab !== "Conditions" && checkRoles("createTeam")
			? [
					{
						key: "createTeam",
						label: t("CreateTeam"),
						onClick: () => openTeamModal(),
						icon: <TeamPlusIcon stroke="#000" />,
					},
			  ]
			: []),
	];
	const modalConfig = {
		deactivate: {
			confirm: () => deactivateUser({id, setModal}),
			word: `${userName}`,
			open: modal === "deactivate",
			disabled: deleteLoading,
		},
		restore: {
			confirm: () => restoreUser({id, setModal}),
			word: `${userName}`,
			open: modal === "restore",
			modalType: "activate",
			disabled: restoreUsersData.loading,
		},
		deleteTeam: {
			confirm: () => deleteTeam({id, setModal}),
			word: t("ThisTeam"),
			open: modal === "deleteTeam",
			disabled: teamLoading,
		},
		delete: {
			confirm: () => deleteUser({id, setPermanantDeleteModal: setModal}),
			word: `${userName}`,
			modalType: "permanantDelete",
			open: modal === "delete",
			disabled: loading,
		},
		deleteSalesOrganization: {
			confirm: () => deleteSalesOrganization({id, setModal}),
			word: t("ThisSalesOrganization"),
			modalType: "permanantDelete",
			open: modal === "deleteSalesOrganization",
			disabled: modalLoading,
		},
	};
	const buttonConfig = {
		Conditions: {
			type: conditionType,
			show: checkRoles("addConditions"),
			withPrint: false,
		},
		SalesOrganizations: {
			type: "salesOrg",
			show: checkRoles("addSalesOrganization"),
			withPrint: checkRoles("printSalesOrganization"),
		},
		DistributionChannel: {
			type: "structure",
			show: true,
			withPrint: false,
		},
	};
	const activeButtonConfig = tab ? buttonConfig[tab] : null;
	const formData = {
		inputs,
		id,
		title: id ? t("EditSalesOrganization") : t("CreateSalesOrganization"),
		initialValues: singlesalesOrganization,
		submitFn: (values) =>
			id
				? editSalesOrganization({values, setModal})
				: createSalesOrganization({values, setModal}),
	};
	const teamFormData = {
		inputs: teamInputs,
		id,
		title: id ? t("EditTeam") : t("CreateTeam"),
		initialValues: teamInitialValues,
		submitFn: (values) => addTeam({values, setModal}),
	};
	const agencyFormData = {
		inputs: agencyInputs,
		id,
		title: t("EditAgencySP"),
		initialValues: agencySpInitialValues,
		submitFn: (values) =>
			editAgency({
				values: {
					isGroupLeader: values?.isGroupLeader,
					agencyGroupLeaderId: values?.agencyGroupLeaderId,
					agencySpUsers: values?.isGroupLeader ? values.agencySpUsers : [],
				},
				agencyId,
				setModal,
			}),
	};

	const activeModalConfig = modal ? modalConfig[modal] : null;
	return (
		<>
			{activeModalConfig && (
				<DeleteModal
					setShowDeleteModal={setModal}
					handleClose={() => setModal(false)}
					confirm={activeModalConfig.confirm}
					word={activeModalConfig.word}
					open={activeModalConfig.open}
					modalType={activeModalConfig.modalType}
					disabled={activeModalConfig.disabled}
				/>
			)}
			<Form
				open={modal === "team"}
				handleClose={() => setModal(false)}
				loading={createLoading}
				formLoading={teamsModalLoading}
				formData={teamFormData}
				modal="team"
				innerModal={{
					fieldToSetValue: "teamMembers",
					title: [t("StartDate"), t("EndDate")],
					fields: ["startDate", "endDate"],
					disableDatesFields: ["from", "to", "trainer"],
					picker: "date",
					type: "day",
					dropDownPlaceholder: t("ChooseTrainer"),
				}}
			/>
			<Form
				open={modal === "agency"}
				handleClose={() => setModal(false)}
				formData={agencyFormData}
			/>

			<Form
				open={modal === "salesOrg"}
				handleClose={() => setModal(false)}
				loading={modalLoading}
				formData={formData}
				modal="salesOrg"
				id={id}
				innerModal={{
					fieldToSetValue: "salesOrganizationUsers",
					title: [t("StartDate"), t("EndDate")],
					fields: ["startDate", "endDate"],
					disableDatesFields: ["startDate", "endDate"],
					picker: "date",
					type: "day",
				}}
			/>
			<div className="userSpClass">
				<div className="dashboard__container">
					<div ref={wrapperRef} className="header-component">
						<div className="dashboard__left__text-header">
							<div className="header-component-wrapper">
								<UsersIcon />
								<span className="header-component-text">{t("Users")}</span>
							</div>
							{loggedUser?.role?.name !== "AGENCY" && (
								<Tabs
									items={tabs}
									onClick={(_, item) => handleTabClick(item)}
									value={t(tab)}
									wrapperRef={wrapperRef}
								/>
							)}
						</div>
						<div className="dashboard__left__text-actions">
							<div className="createuseractions-Wrapper">
								{tab !== "Monitoring" && (
									<>
										{checkRoles("addUsers") && baseTabsArray.includes(tab) && (
											<Dropdown
												menu={{items}}
												overlayClassName="createuseractions_popover__actions-wrapper"
												placement="bottomRight"
												trigger={["click"]}
											>
												<div className="three-dots-icon">
													<Button
														icon={plusIcon}
														leftRounded={false}
														rightRounded={false}
														smallButtonWithTooltip
														hideTooltip
													/>
												</div>
											</Dropdown>
										)}
										{activeButtonConfig && activeButtonConfig.show && (
											<>
												<Button
													icon={plusIcon}
													leftRounded={false}
													rightRounded={false}
													smallButtonWithTooltip
													text={t("AddNewRow")}
													handleChange={() =>
														setModal(activeButtonConfig.type)
													}
												/>
												{activeButtonConfig?.withPrint && (
													<ReactToPrint
														copyStyles={true}
														trigger={() => (
															<div className="print-icon">
																<DownloadFile stroke="#fff" />
															</div>
														)}
														content={() => componentRef.current}
													/>
												)}
											</>
										)}
										{tab !== "Conditions" &&
											tab !== "DistributionChannel" &&
											checkRoles("searchUsers") && (
												<BigSearchBar
													handleSearch={handleSearch}
													headerSearch
													value={query?.search}
												/>
											)}
										{baseTabsArray.includes(tab) && (
											<div className="createuserExport">
												<HtmlTooltip
													placement="top"
													title={
														<div>
															{t("Download")} {t("UserList")}
														</div>
													}
												>
													<img
														className="download_button"
														onClick={() =>
															exportCsvHandler(
																usersColumnsCSV,
																page,
																totalSize,
																query?.search,
															)
														}
														alt="download"
														src={downloadIcon}
													/>
												</HtmlTooltip>
											</div>
										)}
									</>
								)}
							</div>
							{/* )} */}
						</div>
					</div>
					{baseTabsArray?.includes(tab) && (
						<Table
							setReEditPage={setReEditPage}
							reEditPage={reEditPage}
							tableRef={tableRef}
						/>
					)}
					{tab === "Conditions" && <ConditionsWrapper />}
					{tab === "Monitoring" && <Monitoring />}
					{tab === "SalesOrganizations" && (
						<>
							<PrintTable
								printTableRef={componentRef}
								data={salesOrgState?.salesOrganizations}
								columns={salesorgColumns}
								childrenColumns={salesorgMembersColumns}
								childrenField="salesOrganizationUserViewList"
							/>
							<SalesOrganizations setModal={setModal} search={query?.search} />
						</>
					)}
					{tab === "DistributionChannel" && <Structures />}
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	usersState: state.app.users.index,
	deletedUsersData: state.app.users.deleted,
	restoreUsersData: state.app.users.restore,
	teamsState: state.app.teams.index,
	createTeamsState: state.app.teams.create,
	teamLoading: state.app.teams.delete.loading,
	deleteLoading: state.app.users.deleteUser.loading,
	salesOrgState: state.app.salesOrganizations.index,
});

const mapDispatchToProps = {
	getUsers: usersActions.getUsers,
	getEmployeeSp: usersActions.getEmployeeSp,
	fetchDeletedUsers: deletedUsersActions.fetch,
	fillUsers: userActions.fillUsers,
	restoreUser: restoreUsersActions.restoreUser,
	deleteUser: deleteUserActions.deleteUser,
	deactivateUser: deleteUserActions.deactivateUser,
	getUsersNoTeam: usersActions.getUsersNoTeam,
	getUsersSp: usersActions.getUsersSp,
	getTeams: teamActions.fetchTeams,
	teamsEditSize: teamActions.editSize,
	fetchAgencies: teamActions.fetchAgencies,
	addTeam: createTeamActions.addTeam,
	clearTeamValues: createTeamActions.clearTeamValues,
	deleteTeam: deleteTeamActions.deleteTeam,
	setSelectedUsers: createTeamActions.setSelectedUsers,
	navigate: navigation.navigate,
	editPage: usersActions.editPage,
	editPageDeleted: deletedUsersActions.editPage,
	teamsEditPage: teamActions.editPage,
	getTeamById: teamActions.getTeamById,
	createSalesOrganization: salesOrgActions.createSalesOrganization,
	getSalesOrganizationsById: salesOrgActions.getSalesOrganizationsById,
	deleteSalesOrganization: salesOrgActions.deleteSalesOrganization,
	editSalesOrganization: salesOrgActions.editSalesOrganization,
	getUserDropdown: usersActions.getUserDropdown,
	editAgency: teamActions.editAgency,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
