/* eslint-disable max-len */
import {DeleteStatusIcon, EditRowIcon} from "@src/assets/SvgComponents";
import BigSearchBar from "@src/common/bigSearchBar/BigSearchBar";
import Button from "@src/common/components/button";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {actions as leadStatusesActions} from "@sagas/salesTracker/leadStatus";
import {actions as orderStatusActions} from "@sagas/orders/statuses";
import {actions as goalsViewsActions} from "@sagas/analytics/goals/views";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useUrlParams} from "@src/utils/useUrlParams";
import plusLogo from "../../../../../assets/img/plusIconBackground.svg";
const CreateStatusActions = (props) => {
	const {
		leadStatuses,
		orderStatuses,
		deleteLeadStatus,
		loading,
		handleVisibility,
		type = "leadStatuses",
		deleteOrderStatus,
		goalsViews,
		deleteGoalViewType,
		query,
		resetPage,
	} = props;
	const {t} = useTranslation();
	const entities = {leadStatuses, orderStatuses, goalsViews};
	const language = localStorage.getItem("language");
	const {setModal} = useUrlParams();
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [statusToDelete, setStatusToDelete] = useState(null);
	const handleModal = (id) => {
		setShowDeleteModal(true);
		setStatusToDelete(id);
	};
	const handleConfirm = () => {
		if (type === "leadStatuses") {
			deleteLeadStatus(statusToDelete);
		} else if (type === "goalsViews") {
			deleteGoalViewType(statusToDelete);
		} else {
			deleteOrderStatus({id: statusToDelete, query, resetPage});
		}
		setShowDeleteModal(false);
	};
	useEffect(() => {
		if (searchQuery !== "") {
			setFilteredOptions(
				entities?.[type].filter((item) =>
					item?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
				),
			);
		} else {
			setFilteredOptions(entities?.[type]);
		}
	}, [searchQuery, entities?.[type]]);
	const openModal = (type, id, status) => {
		const paramArray = status ? [{status}] : [];
		setModal(type, id, paramArray);
		handleVisibility(false);
	};
	return (
		<>
			<DeleteModal
				open={showDeleteModal}
				setShowDeleteModal={setShowDeleteModal}
				confirm={handleConfirm}
				modalType="permanantDelete"
				disabled={loading}
			/>
			<div className="lead-statuses__popover">
				<div className="lead-statuses__popover-header">
					<BigSearchBar
						handleSearch={(e) => setSearchQuery(e.target.value)}
						value={searchQuery}
						widthSearchBar="318px"
					/>
					<Button
						text=""
						borderRadius="8px"
						border="1px solid #979797"
						color="#979797"
						width="30px"
						height="30px"
						icon={plusLogo}
						leftRounded={false}
						handleChange={() => openModal(type)}
						hideTooltip
						smallButtonWithTooltip
					/>
				</div>
				<div className="leadStatus-container">
					{filteredOptions?.[0] ? (
						filteredOptions?.map((item, index) => (
							<div key={index} className="leadStatus-container-wrapper">
								<span className="leadStatus-container-wrapper-name">
									{language === "en" ? item?.name : item?.nameDe || item?.name}
								</span>
								<div className="leadStatus-container-wrapper-icons">
									<span
										className="leadStatus-container-wrapper-icons-background"
										onClick={() => openModal(type, item?.id, item.status)}
									>
										<EditRowIcon stroke="#fff" width="14" height="14" />
									</span>
									{!item?.isBase && (
										<span
											className="leadStatus-container-wrapper-icons-background"
											onClick={() => handleModal(item?.id)}
										>
											<DeleteStatusIcon
												stroke="#fff"
												width="14"
												height="14"
											/>
										</span>
									)}
								</div>
							</div>
						))
					) : (
						<span className="leadStatus-container-noResults">{t("NoData")}</span>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	// leads
	loading: state.app.salesTracker.leadStatus.loading,
	leadStatuses: state.app.salesTracker.leadStatus.leadStatuses,
	// orders
	loadingOrders: state.app.orders.statuses.loading,
	orderStatuses: state.app.orders.statuses.statuses,
	// goals
	goalsViews: state.app.analytics.view.views,
	loadingGoals: state.app.analytics.view.loading,
});
const mapDispatchToProps = {
	// leads
	deleteLeadStatus: leadStatusesActions.deleteLeadStatus,
	// orders
	deleteOrderStatus: orderStatusActions.deleteOrderStatus,
	// goals
	deleteGoalViewType: goalsViewsActions.deleteView,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStatusActions);
