import {axiosRequests} from "@src/utils/rateServiceAxios";
import {useEffect, useState} from "react";

export default function useRateServiceData() {
	const [clients, setClients] = useState([]);
	const [tariffs, setTariffs] = useState({});
	async function fetchClients() {
		try {
			const {data} = await axiosRequests.rateService({
				url: `rateService/availableRates`,
				method: "get",
			});
			const clients = data?.data?.result || [];
			const clientsArray = clients?.map(({providerId, providerName}) => ({
				label: providerName,
				value: providerId,
			}));
			setClients(clientsArray);
			const newObj = clients.reduce((acc, {rateList, providerId}) => {
				acc[providerId] =
					rateList?.map(({rateId, rateName}) => ({
						id: rateId,
						name: rateName,
					})) || [];
				return acc;
			}, {});
			setTariffs(newObj);
		} catch (error) {
			return error;
		}
	}

	useEffect(() => {
		fetchClients();
	}, []);
	return [clients, tariffs];
}
