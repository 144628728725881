import React from "react";
import BottomTableStatistik from "./components/bottomTableStatistik/bottomTableStatistik";
import TopHeaderStatistik from "./components/topHeaderStatistik/topHeaderStatistik";

const Statistik = (props) => {
	const {filterUsersWithStatus, statistikStatusCount, cleanQuery} = props;
	return (
		<>
			<TopHeaderStatistik
				filterUsersWithStatus={filterUsersWithStatus}
				statistikStatusCount={statistikStatusCount}
			/>
			<BottomTableStatistik cleanQuery={cleanQuery} />
		</>
	);
};

export default Statistik;
