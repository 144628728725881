import React, {useEffect, useState} from "react";
import ModalComponent from "@src/common/CustomModal";
import {useTranslation} from "react-i18next";
import {useUrlParams} from "@src/utils/useUrlParams";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as clientActions} from "@sagas/client";
import {actions as locationActions} from "@sagas/locations";
import {actions as productActions} from "@sagas/products";
import {actions as tariffActions} from "@sagas/tariff";
import {actions as createOrderActions} from "@sagas/orders/create";
import {actions as categoryActions} from "@sagas/projectCategories/clientCategory";
import {useFormik} from "formik";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import useGenericLocations from "@src/utils/useGenericLocations";
import Loader from "@src/common/Loader";
import useEmailValidation from "@src/utils/useEmailValidation";
import {
	generateYupSchema,
	generateInitialValues,
	preparePayload,
	VIEWS,
	prepareEditPayload,
} from "./helpers";
import Form from "./Form";
import MobileView from "./components/MobileView";
import TabletView from "./components/TabletView";
import Tabs from "./components/Tabs";
import Header from "./components/Header";

const PreviewOrderForm = ({
	orderFieldsState,
	getOrderFields,
	getLocations,
	getProjectCategories,
	locations,
	projectCategories,
	getTariffs,
	tariffs,
	products,
	getProducts,
	createOrder,
	loading,
	language,
	withTabs = true,
	clientId = null,
	open = false,
	onClose = () => {},
	orderInitialValues,
	loader = false,
	modalTitle = "orderForm",
	editLoading,
	order,
	editOrder,
}) => {
	const {fieldOptions, groupedFields, client, editedOrderFields} = orderFieldsState;
	const {t} = useTranslation();
	const {options, retrieveDataFromGetAg} = useGenericLocations();
	const {validateEmail, state, setState} = useEmailValidation();
	const {setModal, getUrlParams} = useUrlParams();
	const [emailErrors, setEmailErrors] = useState({});
	const {modal} = getUrlParams("modal");
	const isOpen = open || modal === "preview";
	const [collapsedSections, setCollapsedSections] = useState({});
	const [activeView, setActiveView] = useState(withTabs ? VIEWS.mobile : VIEWS.desktop);
	const clientID = clientId || client?.id;
	const editMode = !!orderInitialValues;

	const handleView = (view) => setActiveView(view);
	const handleClose = () => {
		setModal(false);
		onClose();
	};

	const handleCollapse = (id) => setCollapsedSections((prev) => ({...prev, [id]: !prev[id]}));

	const BASE_FIELDS = [
		{
			name: "clientName",
			defaultValue: client?.clientName,
			labelEn: "Client",
			labelDe: "Produktgeber",
			inputType: "TEXT",
			isDisabled: true,
			hide: editMode || order?.clientContractType === "ENERGY",
		},
		{
			name: "contractId",
			defaultValue: `${client?.clientId}${client?.clientContractId + 1}`,
			labelEn: "Contract Id",
			labelDe: "Auftrags-ID",
			inputType: "TEXT",
			isDisabled: true,
			hide: editMode || order?.clientContractType === "ENERGY",
		},
		{
			name: "orderExternalId",
			defaultValue: language === "en" ? "External ID" : "Versorger Externe ID",
			labelEn: "External ID",
			labelDe: "Versorger Externe ID",
			inputType: "TEXT",
			isDisabled: true,
			hide: editMode || order?.clientContractType === "ENERGY",
		},
		{
			name: "locationId",
			options: locations,
			labelEn: "Municipality",
			labelDe: "Gemeinde",
			inputType: "DROPDOWN",
			isRequired: true,
			dependencyFields: ["tariffId", "productIds"],
			hide: order?.clientContractType === "ENERGY",
		},
		{
			name: "tariffId",
			dependentFrom: "locationId",
			dependencyFields: ["productIds"],
			options: tariffs,
			labelEn: "Tariff",
			labelDe: "Tarif",
			inputType: "DROPDOWN",
			isRequired: true,
			hide: order?.clientContractType === "ENERGY",
		},
		{
			name: "productIds",
			options: products,
			labelEn: "Product",
			labelDe: "Zusatzprodukt",
			inputType: "DROPDOWN",
			isMultiple: true,
			dependentFrom: "tariffId",
			hide: order?.clientContractType === "ENERGY",
		},
		{
			name: "projectCategoryId",
			options: projectCategories,
			labelEn: "Category",
			labelDe: "Projektkategorie",
			inputType: "DROPDOWN",
			isRequired: true,
			hide: order?.clientContractType === "ENERGY",
		},
	];
	const mergedArray = [...BASE_FIELDS, ...editedOrderFields];
	const initialValues = orderInitialValues || generateInitialValues(mergedArray);
	const validationSchema =
		editedOrderFields?.length > 0 ? generateYupSchema(mergedArray, emailErrors, order) : {};
	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		enableReinitialize: true,
		onSubmit: (values, actions) => {
			if (activeView === VIEWS.desktop) {
				if (editMode) {
					// send edit request
					const payload = prepareEditPayload({order, values, fields: editedOrderFields});
					const foundedTariff = tariffs?.find((tariff) =>
						values?.tariffId
							? values?.tariffId === tariff.value
							: tariff.value === order.tariffId.value,
					);
					payload.tariffName = foundedTariff?.label;
					editOrder({values: payload, actions});
				} else {
					// 	const data = preparePayload(editedOrderFields, values, clientID);
					// createOrder({data, handleClose});
				}
			}
		},
	});

	const {values, resetForm} = formik;

	useEffect(() => {
		if (clientID && isOpen) {
			getOrderFields(clientID);
			getLocations(clientID);
			getProjectCategories(clientID);
		} else if (!isOpen) {
			resetForm();
		}
	}, [clientID, isOpen]);

	useEffect(() => {
		if (values?.locationId) {
			getTariffs(values.locationId);
		}
	}, [values?.locationId]);

	useEffect(() => {
		if (values?.tariffId) {
			getProducts(values.tariffId);
		}
	}, [values?.tariffId]);

	if (!isOpen) {
		return null;
	}
	const formProps = {
		formik,
		baseFields: BASE_FIELDS,
		groupedFields,
		handleCollapse,
		collapsedSections,
		fieldOptions,
		editedOrderFields,
		retrieveDataFromGetAg,
		options,
		loading,
		editMode,
		editLoading,
		order,
		emailErrors,
		setEmailErrors,
		validateEmail,
		setState,
		state,
	};
	return (
		<ModalComponent
			open={isOpen}
			handleClose={handleClose}
			title={t(modalTitle)}
			customClasss="order__form__modal"
			width={activeView === VIEWS.tablet ? 720 : 500}
		>
			{loader ? (
				<div className="form__loader">
					<Loader />
				</div>
			) : (
				<>
					{editMode && <Header />}
					{withTabs && <Tabs handleTab={handleView} activeTab={activeView} />}
					{activeView !== VIEWS.desktop ? (
						<div className="order__form__view">
							{activeView === VIEWS.mobile && <MobileView {...formProps} />}
							{activeView === VIEWS.tablet && <TabletView {...formProps} />}
						</div>
					) : (
						<Form {...formProps} />
					)}
				</>
			)}
		</ModalComponent>
	);
};

const mapStateToProps = (state) => ({
	orderFieldsState: state.app.client.index,
	locations: state.app.locations.index.allLocations,
	projectCategories: state.app.projectCategories.clientCategory.categoriesOptions,
	tariffs: state.app.tariffs.index.tariffByLocations,
	products: state.app.products.index.productsByTariff,
	loading: state.app.orders.create.loading,
	language: state.app.language.index.language,
	editLoading: state.app.orders.edit.loading,
	order: state.app.orders.edit.order,
});
const mapDispatchToProps = {
	getOrderFields: clientActions.fetchGenericOrderByID,
	getLocations: locationActions.fetchAllLocations,
	getProjectCategories: categoryActions.getClientCategoriesDropdown,
	getTariffs: tariffActions.fetchTariffsByLocationId,
	getProducts: productActions.fetchProductsByTariff,
	createOrder: createOrderActions.createOrder,
	editOrder: editOrderActions.editOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreviewOrderForm));
