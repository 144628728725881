import React from "react";
import {
	ApprovedIcon,
	BonusIcon,
	BonusSettledIcon,
	CancelledIcon,
	DeliveredIcon,
	DraftIcon,
	EmployeeIcon,
	EuroSign,
	FinishedIcon,
	HeadOfDepartmentIcon,
	IconBonus,
	IconDelivered,
	IconFinished,
	InProgressIcon,
	InternIcon,
	PaidIcon,
	PendingIcon,
	ProgressIcon,
	PublishedIcon,
	ReactivatedIcon,
	ReactivatedStatus,
	ScheduleIcon,
	SettledIcon,
	SignatureIcon,
	SingatureMissIcon,
	SuccessIcon,
	TeamLeaderIcon,
	TrainerIcon,
	WarningIcon,
} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";

export const CONDITION_ENUMS_FOR_CALCULATIONS = [
	"PersonnelDevelopment",
	"OwnContracts",
	"InductionBonus",
	"MobilityBonus",
	"Overhead",
];

export const statusData = {
	[i18n.t("Warning")]: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("Warning"),
	},
	[i18n.t("Success")]: {
		icon: <SuccessIcon />,
		color: "#6DB070",
		label: i18n.t("Success"),
	},
	[i18n.t("Imported")]: {
		icon: <SuccessIcon />,
		color: "#6DB070",
		label: i18n.t("Imported"),
	},
	[i18n.t("Failed")]: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("Failed"),
	},
	[i18n.t("In Process")]: {
		icon: <ProgressIcon />,
		color: "#EECB64",
		label: i18n.t("In Process"),
	},
	[i18n.t("Revert Success")]: {
		icon: <SuccessIcon />,
		color: "#6DB070",
		label: i18n.t("Revert Success"),
	},
	[i18n.t("Revert In Process")]: {
		icon: <ProgressIcon />,
		color: "#EECB64",
		label: i18n.t("Revert In Process"),
	},
	[i18n.t("InProgress")]: {
		icon: <InProgressIcon />,
		color: "#FF8A00",
		label: i18n.t("InProgress"),
	},
	[i18n.t("PUBLISHED")]: {
		icon: <PublishedIcon />,
		color: "#157AE5",
		label: i18n.t("Published"),
	},
	[i18n.t("DRAFT")]: {
		icon: <DraftIcon />,
		color: "#EC8C3A",
		label: i18n.t("Draft"),
	},
	[i18n.t("Scheduled")]: {
		icon: <ScheduleIcon fill="#fff" />,
		color: "#3B505D",
		label: i18n.t("Scheduled"),
	},
	[i18n.t("Trainer")]: {
		icon: <TrainerIcon />,
		color: "#68ACE8",
		label: i18n.t("Trainer"),
	},
	[i18n.t("Internship")]: {
		icon: <InternIcon />,
		color: "#B6B6B6",
		label: i18n.t("Internship"),
	},
	[i18n.t("HeadOfDepartment")]: {
		icon: <HeadOfDepartmentIcon />,
		color: "#6DB070",
		label: i18n.t("HeadOfDepartment"),
	},
	[i18n.t("Employee")]: {
		icon: <EmployeeIcon />,
		color: "#9A729E",
		label: i18n.t("Employee"),
	},
	[i18n.t("TeamLeader")]: {
		icon: <TeamLeaderIcon />,
		color: "#DA6D6D",
		label: i18n.t("Employee"),
	},
	[i18n.t("Agency")]: {
		icon: <HeadOfDepartmentIcon />,
		color: "#5b6bc6",
		label: i18n.t("Agency"),
	},
	[i18n.t("AgencySP")]: {
		icon: <EmployeeIcon />,
		color: "#32b2a6",
		label: i18n.t("AgencySP"),
	},
	[i18n.t("GroupLeader")]: {
		icon: <TeamLeaderIcon />,
		color: "#93c494",
		label: i18n.t("GroupLeader"),
	},
};

export const baseOrderStatusesObj = {
	PENDING: {
		icon: <ProgressIcon />,
		color: "#EECB64",
		label: i18n.t("Pending"),
		dropdownIcon: <PendingIcon />,
	},
	PAID: {
		icon: <EuroSign stroke="#fff" />,
		color: "#6DB070",
		label: i18n.t("Paid"),
		dropdownIcon: <PaidIcon />,
	},
	SETTLED: {
		icon: <SettledIcon />,
		color: "#5D7E93",
		label: i18n.t("Settled"),
		dropdownIcon: <></>,
	},
	CANCELLED: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("cancelled"),
		dropdownIcon: <CancelledIcon />,
	},
	FINISHED: {
		icon: <FinishedIcon />,
		color: "#9A729E",
		label: i18n.t("Finished"),
		dropdownIcon: <IconFinished />,
	},
	CONTRACT_REACTIVATED: {
		icon: <ReactivatedStatus />,
		color: "#2A3CAE",
		label: i18n.t("ContractReactivated"),
		dropdownIcon: <ReactivatedIcon />,
	},
	NOSIGNATURE: {
		icon: <SingatureMissIcon />,
		color: "#B6B6B6",
		label: i18n.t("NoSignature"),
		dropdownIcon: <SignatureIcon />,
	},
	DELIVERED: {
		icon: <DeliveredIcon />,
		color: "#68ACE8",
		label: i18n.t("Delivered"),
		dropdownIcon: <IconDelivered />,
	},
	APPROVED: {
		icon: <SuccessIcon />,
		color: "#5673C2",
		label: i18n.t("approved"),
		dropdownIcon: <ApprovedIcon />,
	},
	BONUS: {
		icon: <BonusIcon />,
		color: "#C57BC4",
		label: i18n.t("Bonus"),
		dropdownIcon: <IconBonus />,
	},
	BONUS_SETTLED: {
		icon: <BonusSettledIcon />,
		color: "#008C8C",
		label: i18n.t("bonusSettled"),
		dropdownIcon: <BonusSettledIcon stroke="#252525" />,
	},
	EXCLUDE_SETTLEMENT: {
		icon: <WarningIcon />,
		color: "#DA6D6D",
		label: i18n.t("excludeFromSettlement"),
		dropdownIcon: <CancelledIcon />,
	},
};

export const baseOrderStatusesArray = Object.keys(baseOrderStatusesObj)?.map((key) => {
	const status = baseOrderStatusesObj[key];
	return {
		label: status.label,
		value: key,
	};
});

export const SETTLED_ACTION_STATUSES = ["SETTLED", "BONUS_SETTLED", "CONTRACT_REACTIVATED"];
