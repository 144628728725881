import {
	DownloadRowIcon,
	EditRowIcon,
	EuroSign,
	PreviewRowIcon,
	ThreeDotsIcon,
} from "@src/assets/SvgComponents";
import i18n from "@src/i18n";
import {checkRoles} from "@src/utils/checkRoles";
import {Dropdown} from "antd";
import React from "react";

const commonActionItemStyles = {
	width: "20px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};
export const actionsFormatter = ({row, handleAction, tableTab}) => {
	const items = [
		{
			key: "preview",
			label: i18n.t("PreviewOrder"),
			icon: (
				<div style={commonActionItemStyles}>
					<PreviewRowIcon color="#252525" />
				</div>
			),
			onClick: () => handleAction("preview", row),
		},
		row?.systemStatusView?.orderStatus === "PAID" &&
			tableTab === "PAID" &&
			checkRoles("editManualCorr") && {
				key: "setPrice",
				label: i18n.t("SetPrice"),
				icon: (
					<div style={commonActionItemStyles}>
						<EuroSign />
					</div>
				),
				onClick: () => handleAction("price", row),
			},
		checkRoles("editOrder")
			? {
					key: "edit",
					label: i18n.t("EditOrder"),
					icon: (
						<div style={commonActionItemStyles}>
							<EditRowIcon strokeWidth={1.7} stroke="#252525" />
						</div>
					),
					onClick: () => {
						handleAction("edit", row);
					},
			  }
			: "",
		checkRoles("downloadOrder") &&
			row?.exportPdf && {
				key: "download",
				label: i18n.t("Download"),
				icon: (
					<div style={commonActionItemStyles}>
						<DownloadRowIcon strokeWidth={1.7} />
					</div>
				),
				onClick: () => handleAction("download", row),
			},
	];
	return (
		<Dropdown
			menu={{items}}
			overlayClassName="popover__actions-wrapper"
			placement="bottomLeft"
			trigger={["click"]}
		>
			<div className="three-dots-icon">
				<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
			</div>
		</Dropdown>
	);
};

export const getByIdFormatter = ({row, handleAction}) => (
	<div
		style={{
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			height: "100%",
		}}
		onClick={() => handleAction("navigate", row)}
	>
		<PreviewRowIcon />
	</div>
);
