/* eslint-disable max-len */
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {Storage} from "./utils/local-storage";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

// USE THIS CHECK TO CHECK POINTS WORD USED FOR DIFFERENT TENANTS
const features = Storage.get("features") || {};
const MPC = features?.MPC;

const resources = {
	en: {
		translation: {
			EditOrder: "Edit Order",
			AllClients: "All Clients",
			Commission: "Commission",
			CommissionIsReq: "Commission is required",
			Continue: "Continue",
			"Welcome to React": "Welcome to React and react-i18next",
			Register: "Register",
			Account: "Account",
			"Personal Information": "Personal Information",
			HaveAccount: "Have an account?",
			ForgotPassowrd: "Forgot Password?",
			PasswordRecovery: "Recover password",
			EnterRecoveryEmail: "Enter email address for recovery",
			RecoveryEmail: "Recovery Email Address",
			EmailSent: "Email Sent",
			Send: "Send",
			Dashboard: "Dashboard",
			salesHistory: "Sales History",

			AllDocuments:
				"All documents relating to the project are stored here. You will find updated or crate new one.",
			TotalRevenue: "Total Revenue",
			Sales: "Sales",
			Deliveries: "Deliveries",
			LastProductSales: "Last Product Sales",
			SaleStatistics: "Sale Statistics",
			Provision: "Provision",
			Product: "Product",
			NumOfContracts: "Number of Contracts",
			Value: "Value",
			Bonus: "Bonus",
			EditBonus: "Edit Bonus",
			EditCommisson: "Edit Commisson",
			CreateBonus: "CREATE BONUS",
			Monthly: "MONTHLY",
			Custom: "CUSTOM",
			Frequency: "Frequency",
			from: "From",
			to: "To",
			send: "Send",
			ClientsList: "Clients List",
			CreateClient: "Create Client",
			editClient: "Edit Client",
			deleteClient: "Deactivate Client",
			Delete: "Delete",
			thisClinnt: "this client",
			clientName: "Client Name",
			name: "Name",
			email: "Email",
			projectCategories: "Project Categories",
			productName: "Product Name",
			lastUpdate: "Last Update",
			create: "CREATE",
			editCategory: "Edit Category",
			Deactivated: "Deactivated",
			deleteCategory: "Deactivate Category",
			thisCategory: " this category",
			categoryId: "Category ID",
			categoryName: "Category Name",
			subCategoryName: "Sub Category",
			category: "Category",
			products: "Products",
			comissions: "Comissions",
			search: "Search",
			cancellationReserve: "Cancellation Reserve",
			editUser: "Edit User",
			percentage: "PERCENTAGE",
			cValue: "VALUE",
			comission: "Comission",
			cancellationReserveType: "Cancellation Reserve Type",
			customerType: "Customer Type",
			tariffId: "Tariff Id",
			editProduct: "Edit Product",
			deleteProduct: "Deactivate Product",
			thisProduct: "this product",
			lastUpdated: "Last Updated",
			date: "Date ",
			ProductName: "Product Name",
			TheLocation: "Location",
			ProjectCategory: "Project Category",
			Created: "Created",
			Paid: "Paid",
			Cancelled: "Cancelled",
			Deliveried: "Deliveried",
			Filename: "Filename",
			OrderID: "Order ID",
			PartnerID: "Partner ID",
			ProductID: "Product ID",
			TariffName: "Tariff Name",
			SalesPartner: "Sales Partner",
			Team: "Team",
			teams: "Team",
			ProjectName: "Project Name",
			UserSP: "User (SP)",
			UserSPName: "Sales Partner",
			UserTitle: "Title",
			CreateUser: "Create User",
			ThisUser: "this user",
			ThisTeam: "this team",
			TeamDeletedSuccesfully: "Team deleted successfully!",
			EditUser: "Edit User",
			DeleteUser: "Deactivate User",
			Username: "Username",
			Role: "Role",
			role: "Role",
			Email: "E-Mail",
			Client: "Client ",
			PhoneNumber: "Phone Number",
			Address: "Address",
			FirstName: "First Name",
			LastName: "Last Name",
			EmailAddress: "Email Address",
			User: "User",
			Users: "Users",
			users: "Users",
			TypeToSearch: "Search",
			Orders: "Orders",
			orders: "Orders",
			CreateOrder: "Create Order",
			Add: "Add",
			Edit: "Edit",
			Cancel: "Cancel",
			Confirm: "Confirm",
			AreYouSureDeactivate: "Are you sure you want to deactivate ",
			AreYouSureDraft: "Are you sure you want to draft ",
			AreYouSureActivate: "Are you sure you want to activate ",
			AreYouSureLeave: "Are you sure you want to leave ?",
			Settings: "Settings",
			UserDetails: "User Details",
			STATISTIC: "STATISTIC",
			Ongoingcases: "Ongoing cases",
			Settledcases: "Settled cases",
			Totalcases: "Total cases",
			Lastproductsales: "Last product sales",
			Tariff: "Tariff",
			CancelationReserve: "Cancellation Reserve",
			DetailsUpperCase: "DETAILS",
			Accountsettings: "Account Settings",
			PublicPofile: "Public Profile",
			AccDetails: "Account Settings",
			PublicProfile: "Public Profile",
			EnterEmail: "Enter Email",
			ConfirmChange: "Confirm Change",
			RecoverPassword: "Recover Password",
			ChangeEmail: "Change Email",
			YourEmailAdress: "Your email address is",
			UploadNewProfilePic: "Upload new profile picture",
			Change: "Change",
			CantRemember: "Can't remember your current password? ",
			Password: "Password",
			ResetYourPassword: "Reset your password",
			OldPassword: "Old Passowrd",
			NewPassword: "New Password",
			ConfirmPassword: "Confirm new password",
			Save: "Save",
			Company: "Company",
			ThisTenant: "Tenant",
			TenantList: "Tenant List",
			CreateTenant: "Create Tenant",
			TenantName: "Tenant Name",
			TenantDomain: "Tenant Domain",
			Domain: "domain",
			Submit: "Submit",
			FirstNameIsReq: "First name is required",
			LastNameIsReq: "Last name is required",
			EmailIsRequred: "Enter a valid email",
			PhoneIsReq: "Phone Number is required",
			TenantNameIsReq: "Tenant name is required",
			AddressIsReq: "Address is required",
			NameIsReqired: "Name is required",
			TenantDomainIsReq: "Tenant domain is required",
			ThisTariff: "this tariff",
			Tariffs: "Tariffs",
			CreateTariff: "CREATE TARIFF",
			SelectDate: "Please select a range date",
			DeactivateTariff: "Deactivate Tariff",
			Calculation: "Calculations",
			Contracts: "Contracts",
			statisticAndDocuments: "Statistic And Documents",
			Total: "Total",
			TotalPayout: "Total Payout",
			TotalCancellationPayout: "Total Cancellation Payout",
			ID: "ID",
			Approved: "Approved",
			Pending: "Pending",
			Status: "Status",
			CreatedAt: "Created At",
			ChangePassword: " Change Password",
			DoYouWant: "Do you want to change your password?",
			calculationData: "Calculation Data",
			MobilePhone: "Mobile Phone",
			mobilePhone: "Mobile Phone",
			Fax: "Fax",
			General: "General",
			Communication: "Communication",
			DistributionChannel: "Distribution Channel",
			Salutation: "Salutation",
			Career: "Career",
			ExtVpNr: "Ext.VP-Nr",
			FixedLevel: "Fixed Level",
			IntConsultant: "Int Consultant",
			Street: "Street",
			LegalForm: "Legal Form",
			TaxNumber: "Tax Number",
			AdvertisedBy: "Advertised By",
			Nr: "Nr",
			PostCode: "Post Code",
			Country: "Country",
			DateOfBirth: "Date Of Birth",
			Branch: "Branch",
			FromCentralRe: "From Central Re",
			AccountNumber: "Account Number",
			VatRequired: "Vat Required",
			City: "City",
			AreYouSureSettle: "Are you sure you want to settle orders?",
			Settlement: "Settlement",
			settlementDetails: "Settlement Details",
			contractInformation: "Contract Information",
			Complete: "Complete",
			TotalPrice: "Total Price",
			ProductDescription: "Product Description",
			Draft: "Draft",
			Published: "Published",
			draft: "Draft",
			published: "Published",
			ViewStats: "View Stats",
			AddTemplate: "Add Template",
			Template: "Template",
			Back: "Back",
			Publish: "Publish",
			AddPhoto: "Add Photo",
			AddTitle: "Add Title",
			Title: "Title",
			CannotSave: "Cannot save an empty note",
			AreYoutSure: "Are you sure?",
			AllDataLost: "All data will be lost!",
			Agree: "Agree",
			Disagree: "Disagree",
			Description: "Description",
			RemoveFields: "Remove Fields",
			AddFields: "Add Fields",
			Download: "Download",
			Loading: "Loading...",
			ChangeStatus: "Change Status",
			DateCreated: "Date Created",
			ActivateUser: "Activate User",
			ActivateCategory: "Activate Category",
			ActivateClient: "Activate Client",
			EditTariff: "Edit Tariff",
			ActivateTariff: "Activate Tariff",
			ActivateProduct: "Activate Product",
			Trainings: "Trainings",
			Messages: "Messages",
			Logout: "Logout",
			EnterRefisteredEmail: "Please enter a registered email address",
			PasswordRequired: "Password is required",
			PasswordMustBe: "Password must have at least 6 characters",
			YourEmailOrPassword: "Your email or password is wrong!",
			EmailSentSuccessfully: "Email sent successfully!",
			PasswordReset: "Password reset successfully!",
			PleaseSelectAction: "Please select an action",
			ClientNameIsReq: "Client Name is required",
			TariffIsReq: "Tariff is required",
			ProvisionIsReq: "Provision is required",
			ComissionIsReq: "Commission is required",
			CancellationReserveIsReq: "Cancellation Reserve is required",
			CancellationReserveTypeISReq: "Cancellation Reserve Type is required",
			NumOfContractsIsReq: "Number Of Contracts is required",
			ValueIsReq: "Value is required",
			DateIsReq: "*Date is required",
			TitleIsReq: "Title is required",
			BonusEditSuccess: "Bonus edited successfully!",
			BonusDeleteSuccess: "Bonus deleted successfully!",
			CilentUpdateSuccess: "Client updated successfully!",
			ClientCreateSuccess: "Client created successfully!",
			ClientDeleteSuccess: "Client deactivated successfully!",
			ClientRestoreSuccess: "Client activated successfully!",
			ComisionUpdateSuccess: "Commision updated successfully!",
			ComisionCreateSucces: "Comission created successfully!",
			OrderCreateSuccess: "Order created successfully!",
			StatusChangeSuccess: "Status change in progress check history tab",
			ProductCreateSuccess: "Product created successfully!",
			ProductUpdateSuccess: "Product edited successfully!",
			ProductDeactiveSuccess: "Product deactivated successfully!",
			ProductDeleteSuccess: "Product deleted successfully!",
			ProductRestoreSuccess: "Product activated successfully!",
			CategoryUpdateSuccess: "Category edited successfully!",
			CategoryCreateSuccess: "Category created successfully!",
			CategoryDeactiveSuccess: "Category deactivated successfully!",
			CategoryDeleteSuccess: "Category deleted successfully!",
			CategoryRestoreSuccess: "Category activated successfully!",
			TariffCreateSuccess: "Tariff created successfully!",
			TariffUpdateSuccess: "Tariff edited successfully!",
			CannotUpdateTables: "*You cannot update tables who doesn't have locations assigned",
			TariffDeactiveSuccess: "Tariff deactivated successfully!",
			TariffDeleteSuccess: "Tariff deleted successfully!",
			TariffRestoreSuccess: "Tariff activated successfully!",
			TenantCreateSuccess: "Tenant created successfully!",
			TenantUpdateSuccess: "Tenant updated successfully!",
			TenantDeleteSuccess: "Tenant deactivated successfully!",
			ImageChangeSuccess: "Image changed successfully!",
			ClientSettingsSuccess: "Client Settings updates successfully",
			DetailsChangeSuccess: "You changed your details successfully!",
			EmailChangeSuccess: "You changed your email successfully!",
			UserEditSuccess: "User edited successfully!",
			UserCreateSuccess: "User created successfully!",
			UserDeleteSuccess: "User deleted successfully!",
			UserRestoreSuccess: "User activated successfully!",
			NoRecords: "No records to display",
			InvalidToken: "Sorry, this token is invalid",
			RequestNewToken: "Click here to request a new token",
			ConfirmPasswordReq: "Confirm Password is required",
			PasswrodMatch: "Passwords must match!",
			UserList: "User List",
			CreateTeam: "Create Team",
			Actions: "Actions",
			DescriptionIsReq: "Description is required",
			TotalPriceReq: "Total Price is required",
			ProductNameReq: "Product Name is required",
			ProductDescriptionReq: "Product Description is required",
			TooShort: "Too short!",
			TooLong: "Too long!",
			CountryReq: "Country is required",
			CityReq: "City is required",
			EmailReq: "Email is required",
			SeeMore: "See More",
			ProductAddOn: "Product Add-on",
			RemoveRole: "Remove Role",
			DeactiveUser: "Deactive User",
			ZipCode: "Zip Code",
			TariffCreatedSuccessfully: "Tariff created successfully!",
			TeamCreatedSuccessfully: "Team created successfully!",
			TeamUpdatedSuccessfully: "Team updated successfully!",
			PrivacyPolicy: "Privacy Policy",
			editLocation: "Edit Location",
			deleteLocation: "Delete Location",
			deactivateLocation: "Deactivate Location",
			thisLocation: " this location!",
			LocationDeleteSuccess: "Location deleted successfully!",
			LocationDeactivateSuccess: "Location deactivated successfully!",
			UserDeactivateSuccess: "User deactivated successfully!",

			ClickHereToSelectUsers: "Click here to select users",
			Price: "Price",
			PriceReq: "Price is required",
			Yes: "Yes",
			No: "No",
			VATEntitlement: "VAT-entitlement",
			VATNumber: "VAT Number",
			BankAccount: "Bank Account",

			BankAccountHolder: "Name of Bank Account Holder",
			Info: "Info",
			Bank: "Bank",
			Uploads: "Uploads",
			PassportNumber: "Passport Number",
			contractId: "Contract Id",
			NoData: "No Data",
			PleaseAddUsers: "Please add at least one  user",
			CompanyIsReq: "Company is Required",
			BirthdateIsReq: "Birthdate is required",
			PostCodeIsReq: "Post Code Is required",
			CompanyName: "Company Name",
			CompanyNameIsReq: "Company Name is required",
			TaxNumberReq: "Tax Number is required",
			MobilePhoneReq: "Mobile Phone is required",
			AFileIsReq: "A File is required",
			PassportNumberReq: "Passport Number is required",
			PassportValidUntil: "Passport valid until: ",
			PassportValidUntilReq: "Passport valid until is required",
			TaxOffice: "Tax Office",
			TaxOfficeReq: "Tax Office is required",
			BankAccountReq: "Bank Account is required",
			BankNameReq: "Bank Name is required",
			BankAccountHolderReq: "Name of Bank Account Holder is required",
			VATEntitlementReq: "VAT-entitlement is required",
			VATNumberReq: "VAT Number is required",
			Iban: "IBAN",
			IbanReq: "IBAN is required",
			Bic: "BIC",
			BicReq: "BIC is required",
			UserRoles: "User Roles",
			UserRolesReq: "User Roles is required",
			ExtVpNrReq: "Ext.VP-Nr is required",
			GeneralInformation: "General Information",
			AreYouUserCompany: "Company?",
			PassportInformation: "ID Information",
			Passport: "Passport",
			TaxingInformation: "Taxing Information",
			SelectFile: "Select File",
			VATNumberQ: "VAT Number (if applicable)",
			VATEntitlementQ: "VATEntitlement ?",
			TaxOfficeQ: "Name of TAX office in charge",
			PersonalInformation: "Personal Information",
			Completed: "Completed",
			Managment: "Managment",
			Settle: "Settle",
			TeamLeader: "Team Leader",
			"TEAM LEADER": "Team Leader",
			MakeTeamLead: "Make Team Leader",
			Remove: "Remove",
			AddUser: "Add User",
			LeaveChanel: "Leave Chanel",
			FileUploaded: "File Uploaded",
			CopyOfRegister: "Copy of Register",
			AddTariff: "Add Tariff",
			Channels: "Channels",
			DirectMessage: "Direct Message",
			Member: "Member",
			Members: "Members",
			AddUsersToChannel: "Add Users to Channel",
			Select: "Select",
			SendMessage: "Send Message",
			SendMessageTo: "Send Message to ",
			Of: "of",
			Rows: "rows",
			RowsPerPage: "Rows per page: ",
			FirstPage: "First Page",
			PreviousPage: "Previous Page",
			NextPage: "Next Page",
			LastPage: "Last Page",
			ActivateLocation: "Activate Location",
			ProjectCategories: "Project Categories",
			SP: "SP",
			Employee: "Employee",
			EMPLOYEE: "Employee",
			Agency: "Agency",
			agency: "Agency",
			AGENCY: "Agency",
			TEAMLEADER: "Teamleader",
			AgencySP: "Agency SP",
			AGENCYSP: "Agency SP",
			CategoryNameIsReq: "Category name is required",
			CreateCategory: "Create category",
			Browse: "Browse",
			ConfirmUpload: "Confirm and start uploading",
			CsvUploadedSuccessfully: "CSV was uploaded succesfully",
			OnlyLoading: "Loading",
			Imports: "Imports",
			BankInformation: "Bank Information",
			Locations: "Locations",
			subLocation: "Sub-Location",
			ZipCodeIsReq: "Zip Code is required",
			CreateChannel: "Create Channel",
			CopyOfRegisterNumber: "Copy of Register Number",
			TradeLicense: "Trade License",
			TopicNameIsReq: "Topic Name is required",
			ChannelName: "Channel Name",
			AddChannel: "Add Channel",
			CreateTemplate: "Create Template",
			TemplateName: "Template Name",
			TemplateNameIsReq: "Template Name is required",
			NumberOfCodes: "Number of Codes",
			NumberOfCodesIsReq: "Number of Codes is required",
			You: "(you)",
			UploadAnImage: "uploaded an image:",
			Upload: "Upload",
			Contract: "Contract",
			Show: "Show Errors",
			InvalidCsv: "Invalid CSV",
			Close: "Close",
			Valid: "Valid",
			Gross: "Gross",
			Up: "Up",
			Down: "Down",
			cancellations: "Cancellations",
			AddCommissionValue: "Please add a commission value to ",
			EmployeeComission: "Employee Comission",
			EmployeeProvision: "Employee Provision",
			SPComission: "SP Comission",
			SPProvision: "SP Provision",
			AgencyComission: "Agency Comission",
			AgencyProvision: "Agency Provision",
			AgencySPComission: "Agency SP Comission",
			AgencySPProvision: "Agency SP Provision",
			TeamLeaderComission: "Team Leader Comission",
			TeamLeaderProvision: "Team Leader Provision",
			EmployeeComissionIsReq: "Employee Comission is required",
			EmployeeProvisionIsReq: "Employee Provision is required",
			SPComissionIsReq: "SP Comission is required",
			SPProvisionIsReq: "SP Provision is required",
			AgencyComissionIsReq: "Agency Comission is required",
			AgencyProvisionIsReq: "Agency Provision is required",
			AgencySPComissionIsReq: "Agency SP Comission is required",
			AgencySPProvisionIsReq: "Agency SP Provision is required",
			TeamLeaderComissionIsReq: "Team Leader Comission is required",
			TeamLeaderProvisionIsReq: "Team Leader Provision is required",
			ProvisionCategory: "Project Category",
			TariffComission: "COMMISSION",
			TariffAgencySP: "AGENCY SP",
			TariffEmployee: "EMPLOYEE",
			TariffAgency: "AGENCY",
			TariffSP: "SALES PARTNER",
			TariffLeader: "TEAM LEADER",
			AddClient: "Add Client",
			EditClient: "Edit Client",
			EditTeam: "Edit Team",
			ContactPersonFirstName: "Contact Person First Name",
			ContactPersonLasttName: "Contact Person Last Name",
			ContactPersonEmail: "Contact Person Email",
			LocationName: "Location Name",
			LocationNameIsReq: "Location Name is required",
			AddCategory: "Add Category",
			EditCategory: "Edit Category",
			AddProduct: "Add Product",
			EditProduct: "Edit Product",
			EditChannel: "Edit Channel",
			thisClient: "this Client",
			OnlyLettersAllowed: "Only letters are allowed for this field",
			LocationAdded: "Location added successfully!",
			AddLocation: "Add Location",
			AddLocations: "Add Location",
			positiveNum: "Number must be positive",
			FirmaName: "Company Name",
			EditTenant: "Edit Tenant",
			OldPasswordRequired: "Old Password is required",
			PermanentDeleteCategory: "Delete Project Category",
			PermanentDeleteLocation: "Delete Location",
			PermanentDeleteUser: "Delete User",
			AreYouSureDelete: "Are you sure you want to permanently delete ",
			PermanentDeleteTariff: "Delete Tariff",
			PermanentDeleteProduct: "Delete Product",
			APPROVED: "APPROVED",
			approved: "Approved",
			PAID: "PAID",
			PENDING: "PENDING",
			CANCELLED: "CANCELLED",
			cancelled: "Cancelled",
			SETTLED: "SETTLED",
			ClientOrderID: "Client Order ID",
			PleaseWriteThreeChars: "Please only type 3 characters",
			TrainingPhotoUploaded: "Training Cover Photo Uploaded!",
			ClientOrderIDIsReq: "Client ID is required",
			categoryRelatedTo: "Category related to",
			HouseNumber: "House Number",
			BirthDay: "Birth Day",
			Telephone: "Telephone",
			ContactPersonName: "Contact Person Name",
			Addition: "Addition",
			BankHolder: "Bank Holder",
			SingatureDate: "Singature Date",

			BankSalutation: "Bank Salutation",
			BankName: "Bank Name",
			BankZipCode: "Bank Zip Code",
			BankLocation: "Bank Location",
			BankStreet: "Bank Street",
			BankHouseNumber: "Bank House Number",
			BankIban: "Bank Iban",
			BankBic: "Bank Bic",
			BankSignatureDate: " Bank Signature Date",

			AddressSalutation: "Address Salutation",
			AdrressFullName: "Address Full Name",
			AddressZipCode: "Address Zip Code",
			AddressStreet: "Address Street",
			AddressEmail: "Address Email",
			AddressBirthdate: "Address Birthdate",
			AddressAddition: "Address Addition",
			AddressHousrNr: "Address House Number",
			ProviderSalutation: "Provider Salutation",
			ProviderFirstName: "Provider First Name",
			ProviderLastName: "Provider Last Name",
			ProviderStreet: "Provider Street",
			ProviderHouseNumber: "Provider House Number",
			ProviderAddition: "Provider Addition",
			ProviderZipCode: "Provider Zip Code",
			ProviderLocation: "Provider Location",
			FullName: "Full Name",
			Place: "Place",
			BankCode: "Bank Code",
			BankCreditCardNumber: "Bank Credit Card Number",
			AddressCity: "Address City",

			ActivePhoneNumber: "Active Phone Number",
			ProjectLocation: "Project Location",
			ContractDate: "Date of Contract",
			SettlementNumber: "Settlement Number",
			SettlementDate: "Settlement Date",
			OrderedDate: "Ordered Date",
			NumberofCanceled: " Canceled",
			SettlmentProvision: "Provison",
			SettlementId: "Settlement-ID",
			SettlementTarif: "Tariff/Product",
			SettlementStatus: "Status",
			UploadTenantLogo: "Upload New Logo",
			LogoChangeSuccess: "Logo changed successfully!",
			clientSettings: "Client Settings",
			VPNumber: "VP Number",
			Details: "Details",
			SelectTeamLeader: "Please assign a Team Leader or Head of Department to proceed.",
			FormerProviderPorting: "Previous Provider Porting",
			FormerProviderName: "Provider Name",
			RowsSelected: "row(s) selected",
			DownloadPDF: "Download PDF",
			selectCompanyError: "Please select one company to download as CSV file!",
			clientNoProduct: "Client of this Order has no Products",
			Next: "Next",
			CompleteTraining: "Complete",
			TrainingUpdateSuccess: "Training updated successfully!",
			VPNumberIsReq: "VP Number is required",
			LastNameCompany: "Last Name (Company)",
			Prefix: "Prefix",
			Extension: "Extension",
			Delivered: "Delivered to Client",
			DELIVERED: "Delivered to Client",
			AdditionalSalutation: "Additional Contractor Salutation",
			AditionalFirstName: "Additional Contractor First Name",
			AditionalLastName: "Additional Contractor Last Name",
			AdditionalZipCode: "Additional Contractor Zip Code",
			AdditionalStreet: "Additional Contractor Street",
			AdditionalEmail: "Additional Contractor E-mail",
			AdditionalBirthdate: "Additional Contractor Birthdate",
			AdditionalAddition: "Additional Contractor Addition",
			AdditionalHousrNr: "Additional Contractor Hause Nr.",
			AdditionalPlace: "Additional Contractor Place",
			AdditionalTelephone: "Additional Contractor Phone Number",
			AdditionalMobile: "Additional Contractor Mobile Number",
			OneFamilyHouse: "One Family House",
			MoreFamilyHouse: "Apartment Building",
			HomeOwner: "Home Owner",
			HomeCoOwner: "Home Co-Owner",
			Renter: "Renter",
			NamePropertyOwner: "Name of Property Owner",
			TransferNumber: "Transfer Number",
			TermsAndConditions: " Terms and Conditions",
			EmailNewsLetter: "Newsletter-E-Mail",
			SendNewsLetter: "Send Newsletter",
			WriteMessage: "Write the message",
			FaildToDownload: "Faild to download!",
			ClientLogo: "Client Logo",
			exportPDF: "Export PDF?",
			OrderProductNumber: "Order Product Number",
			DateOfSignature: "Date Of Signature",
			RowsSelectedOrder: "Selected All",
			VPName: "VP Name",
			OnlyAvailablePdfsCanBeDownloaded: "Only available PDF`s can be downloaded",
			PickAColorForYourPDFTemplate: "Pick a color for your PDF template!",
			YourCurrentColor: "Your current color",
			TitleBankdescription: "Title: Bank description:",
			TitleEmaildescription: "Title: Email description:",
			TitleConfirmationdescription: "Title: Confirmation description:",
			Writeyourparagraphsfortitleshere: "Write your paragraphs for titles here:",
			AddDescription: "Add Description",
			RemoveDescription: "Remove Description",
			// SNGLE ORDER OBJECT
			orderId: "Order ID",
			provision: "Provision",
			createdAt: "Created At",
			tariffName: "Tariff Name",
			salesPartnerName: "Sales Partner Name",
			projectCategoryId: "Project Category ID",
			contractUrl: "Contract Url",
			addons: "Products",
			totalPayOut: "Total Pay Out",
			totalCancellationReservePayOut: "Total Cancellation Reserve Pay Out",
			clientId: "Client ID",
			homeOwner: "Home Owner",
			iAmHomeOwner: "I am the sole owner of the land and building",
			termsAnConditions: "Terms And Conditions",
			formerProviderCheck: "Former Provider Check",
			uuid: "UUID",
			tenantName: "Tenant Name",
			clientOrderId: "Client Order Id",
			orderDetailsData: "Order Details Data",
			userTitle: "User Title",
			firstName: " First Name",
			lastName: "Last Name",
			dateOfBirth: "Date Of Birth",
			company: "Company",
			userEmail: "User Email",
			viewOrderDetails: "View Order Details",
			id: "ID",
			salutation: "Salutation",
			street: "Street",
			houseNumber: "House Number",
			zip: "Zip",
			place: "Place",
			dayOfBirth: "Day Of Birth",
			dayOfBirth1: "Day Of Birth",
			phone: "Phone",
			fax: "Fax",
			mobileNumber: "Mobile Number",
			signatureDate: "Signature Date",
			signatureDate1: "Signature Date",
			addition: "Addition",
			tenantId: "Tenant ID",
			viewBankAccount: "View Bank Account",
			bankState: "Bank State",
			bankIban: "Bank Iban",
			bankBic: "Bank Bic",
			bankCreditCardNr: "Bank Credit Card Nr",
			bankCode: "Bank Code",
			bankName: "Bank Name",
			location: "Location",
			viewAddressForInvoice: "View Address For Invoice",
			abwRAState: "Abw RA State",
			abwRACity: "Abw RA City",
			fullName: "Full Name",
			contactPersonFirma: "Contact Person Firma",
			viewFormerProvider: "View Former Provider",
			formerProvider: "Former Provider",
			formerProviderName: "Former Provider Name",
			locationPrefix: "Location Prefix",
			activePhoneNumber1: "Active Phone Number 1",
			activePhoneNumber2: "Active Phone Number 2",
			activePhoneNumber3: "Active Phone Number 3",
			activePhoneNumber4: "Active Phone Number 4",
			activePhoneNumber5: "Active Phone Number 5",
			activePhoneNumber6: "Active Phone Number 6",
			providerExtension: "Provider Extension",
			additionalContractor: "Additional Contractor",
			telephone: "Telephone",
			onThePhone: "On The Phone",
			firma: "Firma",
			activity: "Activity",
			entryOnThePhone: "Entry On The Phone",
			sendNewsLetter: "Send News Letter",
			emailNewsLetter: "Email News Letter",
			groundFloor: "Ground Floor",
			underground: "Underground",
			upstairs: "Upstairs",
			floor: "Floor",
			oneFamilyHouse: "One Family House",
			apartmentBuilding: "Apartment Building",
			costumerType: "Costumer Type",
			locationName: "Location Name",
			projectCategoryName: "Project Category Name",
			clientFullName: "Client Full Name",
			pdfEmail: "Pdf Email",
			addonsViews: "Preview Contract",
			PreviewOrder: "Preview Order",
			BackOffice: "Back-Office",
			All: "All",
			CompanyTypes: "Company Type",
			Distributionchannel: "Distribution Channel",
			FinancialInformation: "Financial Information",
			CompanyTypesIsReq: "Company Type is required",
			GenderReq: "Gender is required",
			BusinessLicense: "Business License",
			IDNumber: "ID Number",
			PassportFront: "Front",
			PassportBack: "Back",
			Proof: "Proof",
			HrNumberAuszug: "HR-Extract",
			HrNumber: "HR-Number",
			AddNewTable: "Add New Table",
			CreateTable: "Create Table",
			TableName: "Table Name",
			StartDate: "Start Date",
			EndDate: "End Date",
			Until: "until",
			LocationIsReq: "Location is required",
			AgencyLogo: "Agency Logo",
			CalculationType: "Calculation Type",
			CalculationTypeReq: "Calculation Type is required",
			Simple: "Simple",
			Detailed: "Detailed",
			CanNotAddTableCheckDates: "Please don't use the same dates in the same locations!",
			TariffTableDeactivatedSuccesss: "Tariff Table Deactivated Successfully",
			DeactivatedTables: "Deactivated Tables",
			AgencyWith: "Calculation Type",
			SignIn: "Sign In",
			Login: "Login",
			findAccount: "Find your account",
			toContinue: "to continue",
			stepOneTitle: "Let’s take a tour STEP1",
			stepOneDescription:
				"The goal of user onboarding is to get the users to understand the key principles and show them how it will improve their lives.",
			stepTwoTitle: "Let’s take a tour STEP2",
			stepTwoDescription:
				"The goal of user onboarding is to get the users to understand the key principles and show them how it will improve their lives.",
			stepThreeTitle: "Let’s take a tour STEP3",
			stepThreeDescription:
				"The goal of user onboarding is to get the users to understand the key principles and show them how it will improve their lives.",
			stepFourTitle: "Let’s take a tour STEP4",
			stepFourDescription:
				"The goal of user onboarding is to get the users to understand the key principles and show them how it will improve their lives.",
			ForgotPassword: "Forgot password?",
			STEP: "STEP",
			OF: "OF",
			sendBtn: "Send",
			Hi: "Hi",
			myCases: "My Cases",
			statistics: "Statistics",
			Week: "Week",
			This: "This",
			Last: "Last",
			myMonthlyIncome: "My Monthly Income",
			vpNR: "VP NR.",
			successful: "successful",
			issue: "issue",
			files: "files",
			uploaded: "uploaded",

			My: "My",

			Location: "Location",

			Order: "Order",

			Name: "Name",
			Project: "Project",
			Category: "Category",

			Partner: "Partner",
			Entry: "Entry",

			ClientId: "Client ID",
			reqInfoHere: "If there are several orders per customer, enter each order separately!",
			Your: "Your",
			Answer: "Answer",
			Signature: "Signature",
			writeSignature: "Please write your signature here:",
			writeSignatureOrder: "Order summary confirmation",
			writeSignatureNumber: "Request number portability",
			writeSignaturePlacement: "Order placement",
			Sign: "Sign",
			reSign: "Re-Sign",
			noProdTariff: "This product does not have tariff",

			Choose: "Choose",
			selectOneClient: "Please select one client",
			Clear: "Clear",
			signAbove: "Sign above the dashed line",

			helpCenter: "Help Center",
			Notifications: "Notifications",
			Allow: "Allow",
			Notification: "Notification",
			allowNtfRing: "Allow the Notification Rings",
			Version: "Version",
			userProfile: "User Profile",

			providerFirstName: "Former Provider First Name",

			bankLastNameFirma: "Last Name (Company)",
			providerLastName: "Former Provider Last Name",

			Security: "Security",
			changePw: "Change Password",
			confPw: "Confirm Password",
			saveBtn: "Save",

			prjCategory: "Project Category",

			Gender: "Gender",

			confirmOrder: "Confirm Order",
			orderSummary: "Order Summary",
			prjCategorySelect1: "Demand Marketing", // here translate to english
			prjCategorySelect2: "Building Marketing", // here translate to english
			prjCategorySelect3: "Post Marketing", // here translate to english

			reqFirstName: "Please enter a first name",
			reqLastName: "Please enter a last name",
			reqEmail: "Please enter an email",
			reqRegEmail: "Please enter a registered email",
			isEmail: "Enter a valid email",
			minPw: "Password must have at least 8 characters",
			matchPW: "Passwords must match!",
			searchPlaceholder: "Search for file",
			wrongEmailPw: "Your email or password is wrong!",
			isRequired: "is a required field",
			Language: "Language",

			houseNr: "House Number",
			Surname: "Surname",

			birthDate: "Birth Date",

			Mr: "Mr",
			Mrs: "Mrs",
			Zipcode: "Zip code",

			phoneNr: "Phone Number",
			mobileNr: "Mobile Number",
			bankAccHolder: "First and Last name of the account holder",

			totalContacts: "Total Contracts",
			payOut: "Pay Out",
			cancelPayOut: "Cancelation Pay Out",

			projectCategorieTitle: "Projektkategorie ankreuzen", // here translate to english
			projectCategorieText: "In welcher Projektphase wurde der Vertrag abgeschlossen", // here translate to english
			tariffTitle: "Tarif auswählen (Professional-Tarife nur für zugelassene VP)", // here translate to english
			tariffText:
				"Bei mehreren Verträgen pro Kunde (z.B. Mehrfachanschlüsse), musst Du für jeden Tarif eine weiteren Vertrag anlegen", // here translate to english
			productTitle: "Anschlussort", // here translate to english
			productCategoryText: "Bei mehreren Verträgen pro Kunde (z.B. Mehrfachanschlüsse)", // here translate to english
			dgRefNr: "DG - Referenz-Nr.", // here translate to english
			Chat: "Chat",
			myPotentialIncome: "My Potential Income",
			ContractId: "Contract Id",

			changeDate: "Change Date",
			filesUploaded: "Files Uploaded",

			SignInToContinue: "Sign in to Continue",

			MyIncome: "My Income",
			ChooseTariff: "Choose Tariff",
			ChooseCategory: "Choose Category",
			ChooseProduct: "Choose Product",
			SelectClient: "Select Client",
			ChooseTitle: "Choose Title",
			ClientRequired: "Client is Required",
			OrderEntry: "Order Entry",
			OrderEntry2: "Order Entry",
			ClearSignature: "Clear Signature",

			Internet: "Internet",
			Tools: "Tools",
			ConfPassRequired: "Confirm Password is required",
			AllowNotification: "Allow Notification",
			WeSentNewPassword: "We have send you a new password",
			WrongEmail: "Your email is wrong!",
			YouMadeError: "You have made an order",
			SomethingWrong: "Something went wrong please try again!",
			MyOrders: "My Orders",
			YourSignature: "Your Signature",

			UserTitleReq: "User Title is required",
			BirthdateReq: "Birthdate is required",
			StreetReq: "Street is required",
			HouseNrReq: "House Number is required",
			ZipcodeReq: "Zipcode is required",
			MobileReq: "Mobile Number is required",
			BankAccReq: "First and Last name of the account holder is required",

			TariffReq: "Tariff is required",
			ProjectCategoryReq: "Project Cateogry is required",
			SignatureReq: "Signature is required",
			SelectBirhtday: "Select Your Birth Date",
			NameOrCompany: "Full Name or Company Name",
			NameOrCompanyReq: "Full Name or Company Name is required",
			channelNameReq: "Channel Name is required",
			contactIdReq: "Contract Id is required",
			locationReq: "Location is required",
			subLocationReq: "Sub-Location is required",
			pdfReq: "*Pdf File is required",
			teamReq: "Team is required",
			uploadFile: "Upload a file",

			Search: "Search",

			Create: "Create",

			channelName: "Channel Name",
			NewMessage: "New Message",

			members: "members",
			member: "member",
			addMember: "Add Member",
			addPeople: "Add People",

			leaveChannel: "Leave Channel",
			saySmth: "Say Something",
			Message: "Message",
			MessageReq: "Please write a message!",

			areYourSure: "Are you sure ?",
			you: "You",

			chooseLocation: "Choose Location",
			chooseMunicipality: "Choose Municipality",
			municipality: "Municipality",
			chooseTeam: "Choose Team",
			noData: "No data at the moment!",

			To: "To",
			uploadPdf: "Upload PDF",
			emailExist: "User with this email exists!",
			Titles: "Title",
			kontoNumber: "Konto number",
			blz: "BLZ",
			hrNumber: "HR Number",
			extraContract: "Extra Contract House Connection",

			prefix: "Prefix",
			extension: "Extension",

			telephoneNumber: "Telephone Number",
			telephoneNumberReq: "Telephone Number is required",

			bankInformation: "Bank Information",
			bankInfo: "Bank Info",
			generalInfo: "General Info",
			formerInfo: "Former Provider Info",
			addressInfo: "Address Info",
			InvoicingAddress: "Different Invoicing Address",
			yes: "Yes",
			no: "No",

			ChooseSalutation: "Choose Salutation",

			transferNum: "Transfer Number",
			notTransferNum: "I don't need a number transfer",

			notHomeOwner: "I am NOT the sole owner of the property and/or building",
			termsAndConditions: "Accept Terms and Conditions",
			termAndCnd: "Terms and Conditions",
			contactPerson: "Contact Person",
			contactPersonReq: "Contact Person is required",

			unfilled: "Unfilled",
			Business: "Business",
			PrivateClient: "Private",
			providerName: "Former Provider Name",
			providerNameReq: "Former Provider Name is required",

			entryOnThePhoneCheckbox: "Entry In The Phone",

			noAdditionalProducts: "No additional products available at the moment",
			SubscribeToNewsletter: "Subscribe To Newsletter Email",
			DoYouWantToSubscribeToNewsletter: "Do you want to subscribe to newsletter?",

			youHaveChange: "You have change your ",

			apartmentHabitations: "Apartment Habitations",
			apartmentHabitationsReq: "Apartment Habitations is required",

			floorReq: "Floor is required",

			left: "Left",
			right: "Right",
			middle: "Middle",
			leftChannelMsg: "You left the channel",
			invalidIban: "IBAN is invalid",
			owner: "Owner",
			renter: "Renter",
			coOwner: "Co-owner",
			nameOfPropOwner: "Name of property manager/owner",
			userAddSuccess: "Users added successfully",
			orderSignature: "Date of Signature",
			orderProductNumber: "Order-Product-Number",
			orderProductNumberIsReq: "Order-Product-Number is required",
			orderProductNumberLength: "Order-Product-Number must be 9 digits",
			orderClipLength: "Order-Clip must be 9 digits",
			syncData: "Syncing Local Data",
			offlineOrders: "Offline Orders",
			mustBeNumbers: "Must be only digits",
			orderSignatureIsReq: "Date of Signature is required",
			TeamMembers: "Team Members",
			CalculationPreview: "Calculation Preview",
			OrderInfo: "Order Info",
			OrderEditSuccess: "Order edited successfully",
			OrderEditError: "Order could not update!",
			ManagementInfo: "Management Info",
			reqSalutation: "Salutation is required!",
			DatePaid: "Date Paid",
			SetdateorderSuccess: "Order date selected Successfully",
			Setdateordererror: "Please select a date!",
			TrainingDeletedSuccessfully: "Training Deleted Successfully",
			TrainingDraftedSuccessfully: "Training Drafted Successfully",
			Couldnotdeletetraining: "Could not delete training",
			Couldnotdrafttraining: "Could not draft training",
			AreUSureCalculation: "Are u sure u want to add this date?",
			Settled: "Settled",
			TariffUpdateErrorProvision: "Cancelation Reserve can't be bigger than provison!",
			PaidContracts: "Paid Contracts",
			AgencyLogoReq: "Agency Logo is required!",
			Cantchangeteamdatesreq: "Can't change team on this date!",
			Schreiber: "Writer",
			AgencyName: "Agency Name",
			AllTeams: "Teams",
			AllAgencies: "Agencies",
			SubTotal: "Subtotal",
			InfoChef: "Info-Chef",
			businessLicense: "Business License",
			infoChef: "Info-Chef",
			DeleteTeam: "Delete Team",
			ChosePDF: "Chose PDF",
			keepPhoneNumber: "Keep Phone Number",
			newNumber: "New Number",
			secondVoiceChannel: "Second Voice Channel",
			fullData: "Full Data",
			abbreviateData: "Abbreviate Data",
			deleteData: "Delete Data",
			dataPolicy: "Storage of the connection data",
			none: "None",
			shortened: "Shorten",
			completly: "Completly",
			homeOwnerFirstName: "Name",
			homeOwnerLastName: "Last Name",
			homeOwnerPlace: "Place",
			homeOwnerForm: "Home Owner Info",
			orderPlacement: "Order Placement",
			advertisingConsent: "Advertising Consent (optional)",
			contractSummary: "Contract Summary",
			ChoosePDF: "Choose Pdf",
			DoYouWantToCreateAccount: "Do you want to create account ?",
			firstEmail: "Offer Email",
			secondEmail: "Order Email",
			HelpAndSupport: "Help & Support",
			Finished: "Finished",
			FINISHED: "FINISHED",
			AddProjectCategories: "You dont have project categories for this client",
			NoSignature: "Signature Missing",
			NOSIGNATURE: "SIGNATURE MISSING",
			TotalOrders: "Total Orders",
			TotalApproved: "Total Approved",
			TotalPending: "Total Pending",
			OrdersperProvider: "Orders per Provider",
			Male: "Male",
			Female: "Female",
			reqFloor: "Please enter a floor",
			reqAddressId: "Please enter a address Id",
			StockWerk: "Floor",
			AddressID: "Address ID",
			month: "Month",
			accessCancellationReserve: "Access Cancellation Reserve",
			standCancellationReserve: "Stand Cancellation Reserve",
			payout: "Payout",
			day: "Day",
			year: "Year",
			topUp: "Top Up",
			topDown: "Top Down",
			topM: "Top M",
			topF: "Top F",
			"AGENCY-SP": "Agency-Sp",
			January: "Jan",
			February: "Feb",
			March: "Mar",
			April: "Apr",
			May: "May",
			June: "June",
			July: "July",
			August: "Aug",
			September: "Sep",
			October: "Oct",
			November: "Nov",
			December: "Dec",
			Monday: "Monday",
			Tuesday: "Tuesday",
			Wednesday: "Wednesday",
			Thursday: "Thursday",
			Friday: "Friday",
			Saturday: "Saturday",
			Sunday: "Sunday",
			JANUARY: "JANUARY",
			FEBRUARY: "FEBRUARY",
			MARCH: "MARCH",
			APRIL: "APRIL",
			MAY: "MAY",
			JUNE: "JUNE",
			JULY: "JULY",
			AUGUST: "AUGUST",
			SEPTEMBER: "SEPTEMBER",
			OCTOBER: "OCTOBER",
			NOVEMBER: "NOVEMBER",
			DECEMBER: "DECEMBER",
			GraphByClients: "Graph By Clients",
			contractImage: "Photo Connection Contract",
			orderClip: "Order Clip",
			orderClipReq: "Order Clip is required",
			custom: "Custom",
			AddNew: "Add",
			Zuordnung: "Assignment",
			SalesTracker: "Sales Tracker",
			Import: "Import",
			InProgress: "In Progress",
			Declined: "Declined",
			Success: "Success",
			AddACard: "Add a Card",
			CreateNew: "Create New",
			ShowDetails: "Show Details",
			WriteAComment: "Write a comment",
			AddStatus: "Add Status",
			Update: "Update",
			NoUsersSelected: "No Users Selected*",
			ChooseUsers: "Choose Users",
			AreuSureDuplicate: "Are you sure u want to duplicate this lead?",
			ChooseStatus: "Choose Status",
			ChooseAgency: "Choose Agency",
			ChooseClient: "Choose Client",
			ChooseProject: "Choose Project",
			ImportedFrom: "Imported From",
			totalOrders: "Total Orders",
			Agencies: "Agencies",
			SalesPerson: "Sales Person",
			projectCatergory: "Project Category",
			viaET: "HAA via ET when entering the contract",
			viaMFH: "HAA via Wowi/MFH",
			viaPapier: "HAA by paper",
			postCode: "Post Code",
			NameOfEmployer: "Name Of Employer",
			PreviousHealthCompanyNumber: "Previous Health Company Number",
			PreviousHealthCompany: "Previous Health Company",
			PlaceOfBirth: "Place Of Birth",
			MaritalStatus: "Marital Status",
			CountryOfBirth: "Country Of Birth",
			BirthName: "Birth Name",
			HVSignature: "HV Signature",
			FileSizeError: "File size must not exceed 3MB",
			Max3MB: "(Max file size 3MB)",
			GenerateExternalID: "Generate External ID",
			Tousethesethreedifferentcharacters:
				"To use these three different characters, follow these instructions:",
			TouseThisCharachter: "To use this character, follow these instructions:",
			Usetheverticalbarcharacter:
				"Use the vertical bar character (|) to enclose any text that you want to include.",
			UseSquareBracketsCharacter:
				"Use the square brackets ([ ]) to enclose any text that you want to include.",
			Usethehashcharacter:
				"Use the hash character (#) to indicate that you will be typing an ID, and then type the ID immediately after the hash character.",
			Usethedollarsigncharacter:
				"Use the dollar sign character ($) to indicate that you will be typing an enum, and then type one of the enums listed below this text, enclosed in another set of dollar signs.",
			Herearetheavailableenums:
				"Here are the available enums: CURRENT_DATE, CURRENT_DAY, CURRENT_MONTH or CURRENT_YEAR",
			example: "Example: |companyName|, $CURRENT_DATE$, #00001#",
			FileUploadError: "File format invalid,  reupload import file",
			LEADID: "LEAD ID",
			Lead: "Lead",
			paidOrders: "Paid Contracts",
			Leads: "Leads",
			LeadsLocation: "Leads Location",
			Active: "Active",
			Filters: "Filters",
			LEAD: "LEAD",
			ImportLead: "Import Lead",
			next: "Next",
			AllUnsavedWillBeLost: "All unsaved changes will be lost",
			PAIDUPPERCASE: "PAID",
			AddPrice: "Add Price",
			EditPrice: "Edit Price",
			Leaderboard: "Leaderboard",
			SetPrice: "Set Price",
			SetPriceSuccess: "Price is set Successfully",
			SetPriceError: "Price couldn't be set",
			DRAFT: "DRAFT",
			PUBLISHED: "PUBLISHED",
			ChooseAction: "Choose Action",
			PreviewPDF: "Preview PDF",
			AreuSurePublish: "Are you sure you want to Publish?",
			Today: "Today",
			Yesterday: "Yesterday",
			fileSizeError: "File size should be less than 10MB",
			FileTypeError:
				"File format should be between .pdf , .png , .jpg , powerpoint , word or .mp4",
			NewMessages: "New Messages",
			Media: "Media",
			ShowAll: "Show All",
			GroupInfo: "Group Info",
			ContactInfo: "Contact Info",
			ChatAdmins: "Chat Admins",
			Participants: "Participants",
			ClearChat: "Clear Chat",
			DeleteChat: "Delete Chat",
			ReportUser: "Report",
			DeleteChannel: "Delete Channel",
			SearchMessages: "Search Messages",
			AreYouSureToRemoveFromChannel:
				"Are you sure you want to remove this user from channel?",
			AreYouSureToAllowWrite:
				"Are you sure you want to allow this user to write in the channel?",
			AreYouSureToDenyWrite:
				"Are you sure you want to deny this user to write in the channel?",
			ChooseTrainer: "Choose your trainer",
			Trainer: "Trainer",
			Internship: "Internship",
			RemoveAsTrainer: "Remove as Trainer",
			RemoveAsIntern: "Remove as Intern",
			Action: "Action",
			AddTrainer: "Add Trainer",
			AddIntern: "Add Intern",
			CreateTrainer: "Create Trainer",
			CreateIntern: "Create Intern",
			vatEntitlementUrl: "Vat Entitlement Url",
			trainerReq: "Trainer is required",
			UploadLengthInfo: "You can't upload more than 5 files",
			AreYouSureToLeaveChannel: "Are you sure you want to leave channel?",
			OrderList: "Order List",
			Billing: "Billing",
			SubLocation: "Sub-Location",
			NotReached: "Not reached",
			NoInterest: "No Interest",
			FollowUp: "Follow-up",
			Appointment: "Appointment",
			Comments: "Comments",
			BlackList: "Blacklist",
			CommissionOverview: "Commission statement overview",
			HotlineQueries: "Hotline for queries",
			BillingNumber: "Billing number",
			BillingDate: "Billing Date",
			InformationReserveAccount: "Information reserve account - cancellation reserve",
			BalanceBeforeBilling: "Balance before billing",
			NewAdditionCancellationReserves: "New addition cancellation reserves",
			CancellationDeductions: "Cancellation Deductions",
			Withdrawals: "Withdrawals",
			BalanceNEW: "Balance NEW",
			Cancellation: "Cancellation",
			total: "Total",
			ProductProvider: "Product Provider",
			TypeOfRevenue: "Type of Revenue",
			NewContracts: "New Contracts",
			AdditionalProd: "Additional Prod.",
			OwnSales: "Own Sales",
			TotalCommission: "Total Commission",
			PaymentOfReserves: "Payment of Reserves",
			TotalSettlement: "Total settlement amount",
			VAT: "VAT",
			WithdrawalAmount: "Withdrawal Amount",
			allOrder: "All Order",
			postcode: "Postcode",
			additive: "Additive",
			WeGrouping: "WE grouping",
			surname: "Surname",
			ValueAddedTax: "Value added tax according to",
			DeatilsOnBilledContracts:
				"Details on billed contracts & products - see the associated Excel spreadsheet!",
			StatementInfos:
				"This statement is for your preliminary information and is not suitable for tax and/or accounting purposes. Corresponding credit notes will be sent separately",
			and: "and",
			others: "others",
			allUsersChannel: "all users",
			EditGroup: "Edit Group",
			newAdminReq: "New admin must be selected",
			AssignNewAdmin: "Assign new Admin",
			AreUSureCopy: "Are you sure you want to copy this table?",
			Copy: "Copy",
			NoLeadsInfo: "You have no leads to export",
			BillingDetails: "Billing Details",
			ManagingDirectors: "Managing Directors",
			Jurisdiction: "Place of Jurisdiction",
			CompanyWebsite: "Company Website",
			BankDetails: "Bank Details",
			EmailSettings: "Email Settings",
			RegisterTemplate: "Register Template",
			RestoreTemplate: "Restore Password Template",
			BillingDetailsUpdated: "Billing Details were successfully updated",
			EmailSettingsUpdated: "Email Settings were successfully updated",
			FileSizeError1MB: "File size must not exceed 1MB",
			uploadLengthInfo: "You can't upload more than 3 files",
			EmailSubject: "Email Subject",
			idNumberUrlBack: "Id number url back",
			EmailDescription: "Email Description",
			SendEmailForOrder: "Send Email For Order",
			IncludeGeneratedPDF: "Include Generated PDF",
			herearetheavailableenums:
				"Here are the available enums: firstName, lastName, clientName or clientId",
			EXAMPLE: "Example: [firstName], [lastName], [clientName] , [clientId], [email]",
			TenantLogo: "Tenant Logo",
			CreateCustomField: "Create Custom Field",
			EditCustomField: "Edit Custom Field",
			FieldName: "Field Name",
			FieldNameDe: "Field Name in German",
			FieldNameEn: "Field Name in English",
			ChooseInputType: "Choose Input Type",
			ShowField: "Show Field",
			agencyLogo: "Agency Logo",
			Fields: "Fields",
			FieldNameReq: "Field Name is required",
			FieldNameDeReq: "Field Name in German is required",
			FieldNameEnReq: "Field Name in English is required",
			InputTypeReq: "Input Type is required",
			CustomFieldUpdated: "Custom Field Updated Successfully",
			CustomFieldCreated: "Custom Field Created Successfully ",
			NameDeReq: "Name in German is required",
			PickAColor: "Please Pick a Color",
			StatusReq: "Status is required",
			LeadStatusCreated: "Lead status created successfully",
			LeadStatusUpdated: "Lead status updated successfully",
			LeadStatusDeleted: "Lead status deleted successfully",
			StatusDeleted: "Status deleted successfully",
			StatusCreated: "Status created successfully",
			StatusEdited: "Status edited successfully",
			CreateLeadStatus: "Create Lead Status",
			EditLeadStatus: "Edit Lead Status",
			EditStatus: "Edit Status",
			CreateStatus: "Create Status",
			LeadStatuses: "Lead Statuses",
			StatusName: "Status Name",
			StatusNameDe: "Status Name in German",
			ChooseLeadStatus: "Choose Lead Status",
			ChooseLeadStatusType: "Choose Lead status type",
			Selected: "Selected",
			MoveRight: "Move Right",
			MoveLeft: "Move Left",
			LeadStatusTypeReq: "Lead Status type is required",
			ReplaceUsers: "Replace Users",
			RemoveUsers: "Remove Users",
			SelectUser: "Select User",
			Replace: "Replace",
			AreYouSureRemove: "Are you sure u want to remove",
			fromSelectedLeads: " from selected leads?",
			ReplaceLeadFrom: "Replace Leads from ",
			ColorHex: "Color Hex",
			CantEditWithoutUsers: "You can not edit leads without users assigned",
			SelectLeadsToViewActions: "Select one or more leads to view actions*",
			SortBy: "Sort by...",
			SortByStreet: "Sort by street",
			DeleteStatus: "Delete Status",
			statusHistory: "Status History",
			Warning: "Warning",
			ProgressStatus: "Progress Status",
			uploadedBy: "Uploaded By",
			startTime: "Start Time",
			endTime: "End Time",
			UpdatedStatus: "Updated Status",
			AreuSureDeleteSettlement: "Are you sure you want to delete this settlement ?",
			DeleteSettlement: "Delete Settlement",
			"Revert Success": "Revert Success",
			"Revert In Process": "Revert In Process",
			Failed: "Failed",
			OrderStatusCantChange: "Order status can not be changed to a lower status.",
			FinishedOrderError: "Finished order can only be changed to cancelled",
			DeliveredOrderError: "Orders that are not delivered can not change status to finished.",
			WrongStatus: "Wrong status",
			CancelledOrderError: "Can't change status of Cancelled Orders",
			Options: "Options",
			Cancellations: "Cancellations",
			UserExistsInLeads: "This user already exists in these leads",
			UserExistsInLead: "This user already exists in this lead",
			StatusChangePending: "You have an action in progress wait till finished",
			numOfOrders: "Number of Orders",
			SameOrdersError: "Order already has this status",
			Reverted: "Reverted",
			PriceDeleted: "Price has been deleted successfully",
			PriceEdited: "Price has been edited successfully",
			DeletePrice: "Delete Price",
			Brand: "Brand",
			ExternalID: "External ID",
			EMPLOYEE_SHOP: "Employee Shop",
			TariffEmployeeShop: "EMPLOYEE-SHOP",
			EmployeeShopProvision: "Employee Shop Provision",
			PermanentDeleteLeads: "Delete Leads",
			LeadDeleted: "Lead has been successfully deleted",
			LeadsDeleted: "Leads have been successfully deleted",
			YouCantDeleteSuccess: "You can't delete leads who have a Success Status.",
			TariffComissionNotFound: "Tariff commission not found",
			city: "City",
			TariffComissionTableNotFound: "Tariff commission table not found",
			TenantNotFound: "Tenant not found",
			UserNotFound: "User not found",
			RoleNotFound: "Role not found",
			TariffNotFound: "Tariff not found",
			ComisionNotFound: "Commission not found",
			FileTypesError: "You can upload only .xlsx or .csv files",
			UploadLengthInfo1: "You can't upload more than 1 file",
			Revert: "Revert",
			Permissions: "Permissions",
			ShowWeb: "Show on Web",
			ShowMobile: "Show on Mobile",
			manualBookings: "Manual Bookings",
			deactivatedBy: "Deactivated By",
			deactivatedAt: "Deactivated At",
			FileSizeError2MB: "File size must not exceed 2MB",
			UploadLengthInfo10: "You can't upload more than 10 files",
			TotalCancelled: "Total Cancelled",
			SelectCorrectCell: "Please select a correct cell",
			ExcelTypeInfo: "Invalid file type. Please select an Excel file.",
			ClientColor: "Client Color",
			NoDataDownload: "No Data Available",
			taxNumber: "Tax Number",
			AreYouSureToDeleteImport: "Are you sure you want to delete this import?",
			EditInernDates: "Edit Intern Dates",
			EditTrainerDates: "Edit Trainer Dates",
			idNumberUrlFront: "Id Number Url Front",
			InternshipDatesInfo: "Internship's dates should be inside its Trainer's range",
			Conditions: "Conditions",
			SelfProduction: "Self Production",
			MinimumContracts: "Minimum proprietary contracts",
			MinimumContractsTeamMember: "Minimum contracts per team",
			IndividualContract: "Remuneration per individual contract",
			MaxTeamSize: "Maximum team size",
			leaderTrainerCompensation: "Team leader / trainer compensation overhead",
			leaderTrainerProduction: "Team leader / trainer self production",
			EditTable: "Edit Table",
			ConditionTableDeleted: "Condition Table deleted successfully",
			hrNumberUrl: "HR Number Url",
			NoPdfsAvailable: "No PDF is available",
			SelectOrderStatus: "Select Order Status",
			overhead: "Overhead",
			ImportOrder: "Import Order",
			CheckErrors: "Check Errors",
			ImportWithWarnings: "Import with Warnings",
			SelectAllOptionsInfo:
				"You need to select all options from dropdown in order to continue",
			YouCantHaveActions: "You cannot have other actions while import is still in progress",
			FileId: "File Id",
			ImportOrderData: "Import Order Data",
			analytics: "Analytics",
			TeamIsReq: "Please select at least 1 team",
			autoFillInput: "Auto Fill Input",
			OrderSettings: "Order Settings",
			NoSpacesFileName: "File name should not contain spaces",
			assignInputTo: "Assign input to:",
			SaveFields: "Save Fields",
			DeleteFields: "Delete Fields",
			CantDeleteWithoutDate: "You cannot delete fields without a choosen date",
			ChooseDate: "Choose a date",
			SubjectIsReq: "Subject is required",
			userDetails: "User Details",
			SaveFilesOnServer: "Do you want to save attached files on server?",
			Connect: "Connect",
			TestConnection: "Test Connection",
			ClientUserName: "Client User Name:",
			ClientPassword: "Client Password:",
			ClientURL: "Client URL:",
			ClientPort: "Client Port:",
			NumOfDaysReq: "Number of days is required",
			URLReq: "URL is required",
			EmployeeTL: "Employee TL",
			EMPLOYEE_TL: "Employee TL",
			"EMPLOYEE_TL/TRAINER": "Employee TL / Trainer",
			EmployeeAB: "Employee AB",
			EMPLOYEE_AB: "Employee AB",
			"EMPLOYEE_AB/TRAINER": "Employee AB / Trainer",
			Agency70: "Agency 70",
			AGENCY_70: "Agency 70",
			"AGENCY_70/TRAINER": "Agency 70 / Trainer",
			EmployeeTLRequired: "Employee TL is required",
			EmployeeABRequired: "Employee AB is required",
			Agency70Required: "Agency 70 is required",
			selectedTargetClient: "Selected Target Client",
			selectedTargetTariff: "Selected Target Tariff",
			selectedTargetProduct: "Selected Target Product",
			selectedTargetLocation: "Selected Target Location",
			Performance: "Performance",
			// Analytics
			predictiveInsights: "Predictive Insights",
			visualData: "Visual Data",
			activityReports: "Activity Reports",
			customizeInsights: "Customize Insights",
			costumizeAlerts: "Customize Alerts",
			showOnDashboard: "Show on Dashboard",
			removeFromDashboard: "Remove from Dashboard",
			editCard: "Edit Card",
			deleteCard: "Delete Card",
			userIsReq: "Please select at least one user",
			clientIsReq: "Please select at least one client",
			companyType: "Company Type",
			teamIsReq: "Please select at least one team",
			agencyIsReq: "Please select at least one agency",
			txtTooLong: "Text is too long",
			titleReq: "Title is required",
			goalReq: "Goal is required",
			amountReq: "Amount is required",
			priorityReq: "Priority is required",
			orderStatusReq: "Order Status is required",
			financialInformation: "Financial Information",
			descriptionReq: "Description is required",
			priorityNone: "None",
			agencyType: "Agency Type",
			vatNumber: "Vat Number",
			priorityLow: "Low Priority",
			priorityMedium: "Medium Priority",
			priorityHigh: "High Priority",
			Teams: "Teams",
			Clients: "Clients",
			userList: "User List",
			clientList: "Client List",
			agenciesList: "Agencies List",
			teamList: "Team List",
			addUsers: "Add Users",
			addClients: "Add Clients",
			addAgencies: "Add Agencies",
			addTeams: "Add Teams",
			danger: "Danger",
			warning: "Warning",
			exitDate: "Exit Date",
			good: "Good",
			excellent: "Excellent",
			success: "Success",
			repeat: "Repeat",
			goal: "Goal",
			amount: "Amount",
			targetClients: "Target Clients",
			targetTarrifs: "Target Tariffs",
			targetProducts: "Target Products",
			targetLocations: "Target Locations",
			priority: "Priority",
			phoneNumber: "Phone Number",
			txtColor: "Text Color",
			bgColor: "Background Color",
			previewCard: "Preview Card",
			selectStatus: "Select Status",
			goalInformation: "Goal Information",
			selectType: "Select Type",
			statistic: "Statistic",
			statusType: "Status Type",
			dateType: "Date Type",
			sendEmail: "Send Email",
			gender: "Gender",
			saveOnServer: "Save on Server",
			ChooseDays: "Choose number of days",
			ClientFieldsInfo:
				"This section will serve to manage which fields will be deleted on a given date",
			FieldsDeletionDays:
				"Choose a number which will define after how many days order fields on a given status will be deleted",
			FieldDeletionSettings: "Field deletion Settings",
			SFTPServerSettings: "SFTP Server Settings",
			SFTPServerInfo: "This section will serve to configure SFTP Server connection",
			PDFDeletionSettings: "PDF Deletion Settings",
			PDFDeletionInfo:
				"This section will determine when the generated PDFs (from our database) will be deleted.",
			PDFDeletionDays:
				"Choose a number which will define after how many days generated PDFs on a given status will be deleted",
			ConnectedSuccess: "Connected Succesfully",
			CheckCredentials: "Check credentials",
			ContractPdfSettings: "Contract PDF Settings",
			ContractPdfInfo:
				"This section will determine in which status generated PDFs will be sent on client's server",
			PleaseSelectStatus: "Please Select a status",
			ServiceInactive: "Service Inactive",
			EmailSentTo: "Confirmation Email sent to:",
			sent: "Sent:",
			ProductPrice: "Product Price",
			ProductPriceIsReq: "Product Price is required",
			TariffPrice: "Tariff Price",
			TariffPriceIsReq: "Tariff Price is required",
			TariffPriceNoNegatives: "Tariff price cannot have a negatvie value",
			ShowAs: "Show as",
			assignProductsToCalc: "Select Products for Calculation",
			AssignProduct: "Assign Product",
			sendOfferEmail: "Send Offer Email",
			OfferEmailSentTo: "Offer email sent to:",
			saveOnClientServer: "Save on Client Server",
			SendEmailForOrderInfo:
				"This field will determine if there'll be an email sent when order is created",
			IncludeGeneratedPDFInfo:
				"This field will determine if pdfs will be generated when order is downloaded",
			firstEmailInfo:
				"This field will determine if this client is selectable on offer email section on mobile app",
			secondEmailInfo:
				"This field will determine if there'll be sent an email on create order",
			exportPDFInfo: "This field will determine if this client's orders can be downloaded",
			LeadsInfo: "This field will determine if this leads can be assigned to this client",
			LeadsLocationInfo:
				"This field will determine if leads of this client will use geolocation",
			CancellationCustomer: "Cancelled customer",
			iban: "IBAN",
			CANCELLATIONCUSTOMER: "CANCELLATION_CUSTOMER",
			CancelledByProvider: "Cancelled provider",
			CANCELLEDBYPROVIDER: "ORDER_CANCELLED_BY_PROVIDER",
			OrderIncorrect: "Cancelled incorrect order",
			ORDERINCORRECT: "ORDER_INCORRECT",
			ContractReactivated: "Contract Reactivated",
			CONTRACTREACTIVATED: "Settled",
			changeColorThemes: "Change color themes",
			mainColor: "Main Color",
			secondaryColor: "Secondary Color",
			hoverColor: "Hover-Farbe",
			EditSchedule: "Edit Schedule",
			simpleView: "Simple View",
			PublishNow: "Publish Now",
			ScheduleDate: "Schedule Date",
			DownloadInProcess:
				"Download is being completed on background (don't reload untill the download is finished)...",
			DownloadDone: "Download completed",
			DownloadFailed: "Download failed!",
			EntryDate: "Entry Date",
			ExpiryDate: "Expiry Date",
			Export: "Export",
			SelectMonth: "Select month",
			EmployeeStatus: "Employee status",
			Scheduled: "Scheduled",
			entryDate: "Entry Date",
			NumOfPoints: `Number of ${!MPC ? "points" : "MPC"}`,
			ProvisionNew: "Commission New",
			StornoNew: "Cancellation new",
			OverheadBonuses: "Overhead bonuses",
			OldCancellationReserve: "Info cancellation reserve old",
			NewCancellationReserve: "Info cancellation reserve new",
			BillingStatus: "Billing status",
			ManualBookings: "Manual bookings",
			PayoutCancellationReserve: "Payout cancellation reserve",
			DepositCancellationReserve: "Deposit cancellation reserve",
			ValueAddedtax: "value added tax",
			extVpNr: "Vp Number",
			NoDateForThisPeriod:
				"Sorry, we couldn't generate the file. There's no data available for the selected period.",
			Points: !MPC ? "Points" : "MPC",
			NoNegativePoints: `Please enter a positive value for ${!MPC ? "points" : "MPC"}`,
			PointsAchieved: `${!MPC ? "Points" : "MPC"} Achieved`,
			PointTarifAndProduct: `${!MPC ? "Points" : "MPC"} tariff and products`,
			TypeIsReq: "Type is required",
			MinimumPoints: `Minimum proprietary ${!MPC ? "points" : "MPC"}`,
			MinimumPointsTeamMember: `Minimum ${!MPC ? "points" : "MPC"} per team`,
			MonthlyMonitoringReport:
				"Monthly monitoring report on personnel development and qualifications.",
			MonitoringTrainer: "Qualification Monitoring Requirements Trainer:",
			MonitoringLeader: "Qualification Monitoring Requirements Team Leader:",
			MonitoringHoD: "Qualification Monitoring Requirements Head of Departament:",
			MaintainStatusTrainer: "Keep monitoring Maintain Status Trainer:",
			MaintainStatusLeader: "Keep monitoring status team leader:",
			MaintainStatusHoD: "Keep monitoring Status Head of Department:",
			fullname: "First & Last Name",

			PointsLastMonth: `${!MPC ? "Points" : "MPC"} Last Month`,
			PointsLast3Months: "Average Last 3 Months",
			QualificationTrainer: "Qualification Trainer",
			PointsLastMonthTrainer: `Trainee with 40-71 ${
				!MPC ? "points" : "MPC"
			} per month, last month`,
			sizeInterns: `Trainee with 40-71 ${
				!MPC ? "points" : "MPC"
			} per month since starting trainer`,
			averageOnTrainerPoints: `Your own ${
				!MPC ? "points" : "MPC"
			} per day Average last months`,
			NrTeamMembers: "Number of Team Members",
			trainersLongerThanSixMonths: "Of which trainer longer than 6 months",
			teamTurnOver: "Team turnover per month after appointment as HoD",
			qualificationHeadOfDepartment: "Qualification HoD",
			trainersLongerThanSixMonthsSecond:
				"Trainee with 40-71 per month last 6 months on average",
			statusConfirmed: "Status Confirmed",
			teamTurnOverLeader: `Team turnover per day and team member last month in ${
				!MPC ? "points" : "MPC"
			}`,
			teamTurnOverSinceBeginning: `Team turnover per day and team member last month in ${
				!MPC ? "points" : "MPC"
			} on av.`,
			teamTurnOverSixMonthsPerMonth: `Team turnover per month last 6 months in ${
				!MPC ? "points" : "MPC"
			} on average`,
			Monitoring: "Monitoring",
			taxOffice: "Tax Office",
			MinPoints: `Minimum ${!MPC ? "Points" : "MPC"}`,
			MinPointsIsReq: `Minimum ${!MPC ? "Points" : "MPC"} is a required field`,
			MaxPoints: `Maximum ${!MPC ? "Points" : "MPC"}`,
			MaxPointsIsReq: `Maximum ${!MPC ? "Points" : "MPC"} is a required field`,
			LeadDuplicated: "Lead duplicated successfully",
			AreYouSureToShow: "Are you sure you want to show",
			AreYouSureToHide: "Are you sure you want to hide",
			SelectHeadOfDepartment: "Select Head of Departament",
			HeadOfDepartment: "Head of Departament",
			TeamInfo: "Team information",
			TeamName: "Team Name",
			SelectedUsers: "Selected Users",
			MobilityBonus: "Graduated Bonus",
			InductionBonus: "Induction Bonus",
			PersonnelDevelopment: "Personnel Development",
			AddNewRow: "Add New Row",
			EditRow: "Edit Row",
			DeleteRow: "Delete Row",
			Production: "Production",
			Specification: "Specification",
			MinNrOfTP: "Minimum number of team production",
			amountTeam: "Amount per contract/point team",
			minNrTeamPoints: `Minimum number of Team ${!MPC ? "Points" : "MPC"}`,
			amountPointTeam: "Amount per contract/point team",
			BranchManager: "Head of Department",
			"HEAD OF DEPARTMENT": "Head of Department",
			withProductionTrainee: "With production trainee",
			withoutProductionTrainee: "Without production trainee",
			paymentAmountOnce: "Payment amount once",
			startTime2: "Start",
			canceledOrders: "Canceled Orders",
			EndTime: "End",
			oneTimeAmount: "One-off amount",
			startConditionBonus: "Start Bonus",
			countInternFrom: "Production Trainee from",
			countInternTo: "Production Trainee to",
			selectionTeam: "Selection Team",
			selectionInstructors: "Selection of instructors",
			selectionTrainees: "Selection of trainees",
			duplicateRow: "Duplicate Row",
			beginIntern: "Begin Intern",
			beginInternPlus1Month: "Begin Intern + 31 days",
			OwnContracts: "Own Contracts",
			TeamMember: "Team Member",
			TrainerOrInternAssigned:
				"Trainers or interns are assigned to another row on these dates",
			Copied: "Copied",
			promotionTrainer: "Promotion instructor",
			promotionLeader: "Promotion team leader",
			mustBeGreaterThan: "must be greater than",
			monthlyFix: "Fixed monthly",
			unexpectedError: "An unexpected error occurred.",
			chooseDates: "Choose Dates",
			categoryEdit: "Edit Category",
			clearFilters: "Clear Filters",
			monthlyFixedFee: "Monthly Fixed Amount",
			selectionWho: "Selection Who",
			Produktion: "Production",
			selectionWhat: "Select What",
			inHouseProduction: "In-house production",
			minInHouseProduction: "Min. In-house production",
			until: "Until",
			paymentAmount: "Payment amount",
			totalAmount: "Total selection amount!",
			uploadNew: "Upload New",
			fileName: "File Name",
			ChooseType: "Choose Type",
			Dragdata: "Drag data here or",
			receivedCommission: "Received Commission",
			receivedCommissionIsReq: "Received Commission is required",
			receivedCommissionNoNegative: "Received Commission cannot have a negative value",
			contractDocuments: "Contract Documents",
			additionalAgreements: "Additional Agreements",
			TableSavedSuccessfully: "Table has been saved",
			ContactName: "Contact person at the provider",
			ContactEmail: "Email address of the contact person",
			ContactPhone: "Telephone number of the contact person",
			AllWithoutCategory: "All Without Category",
			Dashboard2: "Dashboard2",
			Dashboard1: "Dashboard1",
			autoIndicator: "Automatic Indicator",
			priorityType: "Priority type",
			validFrom: "Valid From",
			dateExpiry: "Date Of Expiry",
			documentUploads: "Document Uploads",
			revenueComissions: "Revenue Comissions",
			canceledContracts: "Canceled Contracts",
			additionalRewards: "Additional Rewards",
			totalPerMonth: "Total Per Month",
			selectYear: "Select Year",
			dashboardAdmin: "Dashboard Admin",
			dashboardManagment: "Dashboard Management",
			firstNameNonEmpty: "First name cannot be empty.",
			lastNameNonEmpty: "Last name cannot be empty.",
			Support: "Support",
			DangerBehindTheTarget:
				"Danger: Behind the target, urgent action is needed to meet the goal.",
			WarningFallingShortOfTarget:
				"Warning: Falling short of the target, requires attention to avoid missing the goal.",
			GoodProgressingWellToWardsTheTarget:
				"Good: Progressing well towards the target, keep up the momentum.",
			ExcellentOnTrackToMeetOrExceedTheTarget:
				"Excellent: On track to meet or exceed the target, continue current efforts.",
			SuccessGoalAchievedOrSurpassed:
				"Success: Goal achieved or surpassed, congratulations on reaching the target!",
			DownloadAll: "Download All",
			ChangedAt: "Changed At",
			dateOfContract: "Date Of Contract",
			Marketplace: "Marketplace",
			apiKeyMissing: "API Key Missing",
			apiKeyMissingInfo:
				"Oops! It looks like you haven't provided an API key. To continue using this feature, please add your API key in the marketplace.",
			addNewApiKey: "Add new API Key",
			Hide: "Hide",
			SecondShow: "Show",
			areYouSureDeleteMsg: "Are you sure you want to delete this message?",
			delete: "Delete",
			copy: "Copy",
			reply: "Reply",
			RowEditSuccess: "Row edited successfully!",
			RowDeleteSuccess: "Row deleted successfully!",
			RowCreateSuccess: "Row created successfully!",
			settleOrders: "Settle Orders",
			chooseYear: "Choose Year",
			chooseMonth: "Choose Month",
			yearReq: "Year is required.",
			monthReq: "Month is required.",
			descriptionMaxLen: "Description must be at most 70 characters.",
			allSettlements: "All Calculations",
			LockedText: "Please contact your administrator to request access.",
			LockedAccount: "Locked Account",
			TenantLockSuccess: "Tenant Locked Successfully!",
			AreYouSureToLocked: "Are you sure you want to lock the Tenant?",
			LockClient: "Lock Client",
			UnlockClient: "Unlock Client",
			allOrders: "All Orders",
			companyInfo: "Company information",
			billingDetailsInfo: "Billing Information, Upload Logo, Themes",
			emailTemplate: "Email Template",
			profilePic: "Profile Picture",
			changeLang: "Change Language",
			selectLang: "Select your preferred language",
			emailTemplatesUpdated: "Email templates have been successfully updated!",
			upload: "Upload",
			UploadedFiles: "Uploaded Files",
			ChooseMatchType: "Choose Match Type",
			noNegative: "cannot have a negative value",
			fieldNonEmpty: "The field cannot contain only spaces.",
			productInfo: "Products provision",
			tariffInfo: "Tariff",
			ChangePhoto: "Change Photo",
			Statuses: "Statuses",
			checkStatusHistory: "Check status history",
			inProgress: "IN_PROGRESS",
			youDeletedMsg: "You deleted a message",
			otherDeleteMsg: "Other user deleted a message",
			OrdersStatus: "Orders Status",
			CreateOrders: "Create Orders",
			CreateLeads: "Create Leads",
			ChangeOrderByImport: "Change order statuses by import",
			CreateOrdersByImport: "Create orders by import",
			CreateLeadsByImport: "Create leads by import",
			ChangeOrderStatus: "Change Order Status",
			AddImport: "Add Import",
			ImportedContracts: "Imported Contracts",
			ActualContracts: "Actual Contracts",
			Proceed: "Proceed",
			tariffProvision: "Tariff provision",
			lastModified: "Last modified by: ",
			ImportPublished: "Import has been published",
			orderHaveToRevert:
				"First, the order should be reverted. Then, it can be set to 'Contract Reactivated'.",
			ValidDatesError: "Dates field should be in standard format DD.MM.YYYY",
			orderStatus: "Status",
			AreYouSureUnMatch: "Are you sure you want to unmatch",
			categoryIsReq: "Category is required",
			subCategoryIsReq: "Sub category is required",
			reactivateContract: "Reactivate Contract",
			reactivate: "Reactivate",
			newStatus: "Incoming Status",
			bonusIsReq: "Bonus is required",
			bonusSettled: "Bonus Settled",
			targetClientsReq: "Target Clients is Required",
			ShowChanges: "Show changes",
			CurrentVersion: "current version",
			chooseBonus: "Choose Bonus",
			Relations: "Relations",
			totalLeads: "Total Leads",
			chooseSettlementNumber: "Choose Settlement Number",
			bonusSettleRules: `To create settlements for these Bonus contracts, please follow these steps:
			1. Filter by client and select a project category.
			2. Then, filter by user to proceed.
			`,
			BONUS_SETTLED: "Bonus Settled",
			MatchFieldsError: "Match all fields from import to proceed",
			EXPORTIERT: "DELIVERED",
			ANGENOMMEN: "APPROVED",
			"UNTERSCHRIFT-FEHLT": "NOSIGNATURE",
			SalesOrganizations: "Sales Organizations",
			thisCategoryIsDeleted: "This category has been deleted!",
			TrainingCreateSuccess: "Training created successfully!",
			ThisFieldIsReq: "This field is required",
			CantCreateSales: "Can't create Sales Organization",
			CantEditSales: "Can't edit Sales Organization",
			EditSalesOrganization: "Edit Sales Organization",
			DeleteSalesOrganization: "Delete Sales Organization",
			CreateSalesOrganization: "Create Sales Organization",
			HeadOfSales: "Head of Sales Organization",
			SetHeadOfSales: "Set Head of Sales",
			DDMMYYYY: "DD:MM:YYYY",
			Deduction1: "GB fee §4 (1) Deduction",
			Deduction2: "GB fee §4 (2) Deduction",
			CompensationIncomingCommission: "Compensation Incoming commission",
			AmountPerContract: "Amount per contract",
			LumpSumAmount: "Lump sum amount",
			ConditionsHeadOfSalesOrganisation: "Conditions Head Of Sales Organisation",
			ToBeUsed: "To be used for",
			ManagerVO: "Manager VO",
			chooseClientType: "Select the Type of Client",
			addRow: "Add New Row",
			settleSalesOrgRules: `To create settlements for Head Of Sales, please follow these steps:
			1. Filter by Order Status "Settled" and by "Sales Organization".
			2. Select contracts.`,
			fibreOrders: "Fibre Orders",
			energyOrders: "Energy Orders",
			supervisor: "Supervisor",
			supervisorIsReq: "Supervisor is required",
			stufeIsReq: "Stufe is required",
			structureIsReq: "Structure is required",
			fibreSettlements: "Fibre Calculations",
			energySettlements: "Energy Calculations",
			SalesEdited: "Sales Organization has been edited",
			SalesDeleted: "Sales Organization has been deleted",
			SalesCreated: "Sales Organization has been created",
			ThisSalesOrganization: "This Sales Organization",
			standardTariff: "Standard (Show all tariffs)",
			heatingCurrentPump: "Heating current heat pump",
			heatingNight: "Heating electricity night storage",
			chargingElectricity: "Charging electricity / car electricity",
			tariffDigitalSignature: "Tariffs with digital signature.",
			includeBonus: "Annual price including bonus.",
			priceDate: "Price Date",
			orderInformation:
				"Park your order to add more data at a later time. Please note that orders can only be exported if they are taken out of the parking status",
			StructureEditedSuccess: "Structure Edited Successfully",
			StructureCreatedSuccess: "Structure Created Successfully",
			StuffeEditedSuccess: "Stuffe Editeded Successfully",
			StuffeCreatedSuccess: "Stuffe Created Successfully",
			StructureDeletedSuccess: "Structure Deleted Successfully",
			StuffeDeletedSuccess: "Stuffe Deleted Successfully",
			Check: "Check",
			OrderCreatedError: "Order failed to create!",
			OrderCreatedSuccess: "Order was created successfully",
			EditConsumption: "Edit Consumption",
			AddRowToConsumption: "Add Row to Consumption",
			CreateConsumption: "Create Consumption",
			SettlementCreateError: "Settlement failed to create!",
			SettlementCreateSuccess: "Settlement has been created",
			HasSettlementCreated:
				"A settlement for Head Of Sales has been created with this order!",
			LeadCreated: "Lead created successfully",
			LeadEdited: "Lead edited successfully",
			ChangeUser: "Changed user successfully",
			LocationEdited: "Location edited successfully!",
			DocumentUploaded: "Document Uploaded successfully",
			startConversation: "Start Conversation",
			check: "Check",
			dateError: "Please select a date that is at least",
			yearsAgo: "years ago.",
			SetDate: "Set Date",
			orderStatusSelection: "Order Status Selection",
			orderStatusSelectionInfo:
				"Choose the default status for new client orders. This setting will define how new orders are initially processed.",
			profile: "Profile",
			SelectAnOption: "Select an option",
			orderForm: "Order Form",
			fieldLabelEn: "Field Name in English",
			fieldLabelDe: "Field Name in German",
			addSection: "Add Section",
			editSection: "Edit Section",
			linkPdfToSection: "Link PDF to Section",
			editOrderField: "Modify Order Field Settings",
			fieldOptions: "Customize Field Options",
			exportSortNumber: "Position in Export Order",
			orderSortNumber: "Position in Display Order",
			showAs: "Display As",
			requestType: "Request Type",
			includeTariffInCalculation: "Include Tariff in Calculation?",
			defaultValue: "Default Value",
			isDisabled: "Is Disabled",
			isRequiredOnOfferEmail: "Is Required on Offer Email",
			isAReqField: "Is a Required Field",
			minCharacters: "Minimum Characters",
			maxCharacters: "Maximum Characters",
			hideWeb: "Hide on Web App",
			hideMobile: "Hide on Mobile App",
			showOnOrderSummary: "Show on Order Summary",
			hideOnPrivateTariffs: "Hide on Private Tariffs",
			fixedNoteEn: "Additional Information (English)",
			fixedNoteDe: "Additional Information (German)",
			dependencyFields: "Dependent Fields",
			displayOnlySelectedTariff: "Show Only Selected Tariff",
			showSection: "Display Section",
			collapse: "Allow Section to Collapse",
			sectionNameEn: "Section Name (English)",
			sectionNameDe: "Section Name (German)",
			sectionDeleted: "Section removed successfully!",
			sectionCreated: "Section created successfully!",
			sectionUpdated: "Section updated successfully!",
			areYouSureDeleteField: "Are you sure you want to delete this order field?",
			areYouSureDeleteSection: "Are you sure you want to remove this section?",
			orderFieldDeleted: "Order field deleted successfully!",
			fieldLabelEnInfo: "The label of this field when the app is in English.",
			fieldLabelDeInfo: "The label of this field when the app is in German.",
			exportSortNumberInfo: "Determines the order of this field in order export.",
			orderSortNumberInfo:
				"Determines the display order of this field in the web and mobile app.",
			fieldOptionsInfo: "Sets the available options for this field (comma-separated).",
			showAsInfo: `Defines how the field will be displayed (options: Value, Price, Checkbox, Total).

			Value: Displays the actual value of the field.
			Price: Shows the price, especially useful for tariff or product fields.
			Checkbox: Displays a checkbox when the field is tariff/product-related and a product is selected.
			Total: Calculates and shows the total price of assigned tariffs/products.`,
			requestTypeInfo: "Defines the type of request performed by this field.",
			assignProductsToCalcInfo: "Selects which products will be calculated in this field.",
			includeTariffInCalculationInfo:
				"Enables or disables tariff inclusion in the calculation.",
			AssignProductInfo: "Assigns a product to this field.",
			autoFillInputInfo: "Defines from which lead field this input will be autofilled.",
			assignInputToInfo: "Selects which other fields this input will autofill.",
			defaultValueInfo: "Sets a default value for the field that cannot be removed.",
			isDisabledInfo: "Disables this field during order creation.",
			isRequiredOnOfferEmailInfo: "Makes the field required when sending an offer email.",
			isAReqFieldInfo: "Marks this field as required.",
			isMultipleInfo: "Determines whether the dropdown allows multiple selections.",
			displayOnlySelectedTariffInfo: "Displays only the selected tariff for the user.",
			minCharactersInfo: "Sets the minimum number of characters allowed.",
			maxCharactersInfo: "Sets the maximum number of characters allowed.",
			dependencyFieldsInfo:
				"Selects fields that will depend on this field (hidden unless this has a value).",
			hideWebInfo: "Hides this field on the web app.",
			hideMobileInfo: "Hides this field on the mobile app.",
			showOnOrderSummaryInfo: "Shows this field in the order summary on the mobile app.",
			hideOnPrivateTariffsInfo: "Hides this field on private tariffs (energy clients only).",
			fixedNoteEnInfo: "Displays information in English when this field is active.",
			fixedNoteDeInfo: "Displays information in German when this field is active.",
			isMultiple: "Allow multiple selections",
			staticSection: "Static Section",
			checkbox: "Checkbox",
			dropdown: "Dropdown",
			number: "Number",
			signature: "Signature",
			textarea: "Textarea",
			title: "Title",
			product: "Product",
			tariff: "Tariff",
			birthdate: "Birthdate",
			bic: "Bic",
			bankname: "Bank name",
			DeleteTable: "Delete Table",
			EditStructure: "Edit Structure",
			CreateStructure: "Create Structure",
			EditStufe: "Edit Stufe",
			CreateStufe: "Create Stufe",
			PayoutModelTwoProvisionPercentage: "Payout Model two provision percentage",
			PayoutModelTwoName: "Payout model two Name",
			PayoutModelTwoStatuses: "Payout model two statuses",
			PayoutModelOneName: "Payout model one name",
			PayoutModelOneProvisionPercentage: "Payout model one provision percentage",
			ProvisionName: "Provision Name",
			PayoutModelOneStatuses: "Payout Model one Statuses",
			Entrance: "Entrance",
			CommissionAmount: "Commission Amount",
			PayoutModel: "Payout Model",
			SetDateForInterns: "Set a start date for all interns",
			GridView: "Grid View",
			ListView: "List View",
			PriorityView: "Priority View",
			StatusView: "Status View",
			SelectGoalsToViewActions: "Select one or more goals to view actions*",
			CheckEmailFormat: "Check Email format",
			GroupView: "Group View",
			GroupName: "Group Name",
			CreateGroupView: "Create Group View",
			EditGroupView: "Edit Group View",
			Groups: "Groups",
			DateBased: "Date Based",
			removeFromMobile: "Remove From Mobile",
			AreYouSureTo: "Are you sure to",
			removeCard: "remove Card",
			addCard: "add Card",
			fromMobile: "from Mobile",
			toMobile: "to Mobile",
			fromDashboard: "from Dashboard",
			toDashboard: "to Dashboard",
			Elements: "Elements",
			LOW: "LOW",
			NONE: "NONE",
			MEDIUM: "MEDIUM",
			HIGH: "HIGH",
			CANCELLED_INCORRECT_ORDER: "Cancelled incorect order",
			CANCELLED_PROVIDER: "Cancelled provider",
			CANCELLED_CUSTOMER: "Cancelled customer",
			CONTRACT_REACTIVATED: "Contract Reactivated",
			dateTypeInfo:
				"Choose the unit of time for date comparison, which will be used in validation to ensure that the selected dates meet the specified criteria.",
			minDate: "Minimum Date",
			minDateInfo:
				"Enter the minimum date that must be met. The allowed date range will be adjusted based on the selected Date Type.",
			maxDate: "Maximum Date",
			maxDateInfo:
				"Enter the maximum date that can be selected. This sets an upper limit for date selection, determined by the chosen Date Type.",
			daily: "Daily",
			weekly: "Weekly",
			monthly: "Monthly",
			yearly: "Yearly",
			maxAgeError: "Max date cannot be 0 when min date is 0. Please enter a valid max date.",
			dndTargetError:
				"The field was dropped outside the allowed area. Please try again and drop the field within the designated area.",
			assignTariff: "Assign Tariff",
			assignTariffInfo: "Select a tariff from the list to assign it to the field.",
			TargetOrders: "Target Orders",
			displayCard: "display Card",
			SelectedAgencies: "Selected Agencies",
			SelectedTeams: "Selected Teams",
			SelectedClients: "Selected Clients",
			SelectAll: "Select All",
			DeselectAll: "Deselect All",
			checked: "Checked",
			unchecked: "Unchecked",
			pdfSettings: "PDF Settings",
			linkTariffToPdf: "Link Tariff to PDF",
			pdfDefaultSwitchInfo: "This field will determine if this PDF will be",
			sendEmailInfo: "sent by email when an order is created",
			sendOfferEmailInfo: "sent by email with the offer",
			saveOnServerInfo: "saved on the server",
			saveOnClientServerInfo: "saved on the client server",
			insertHorizontalLine: "Insert Horizontal Line",
			insertVerticalLine: "Insert Vertical Line",
			pdfName: "PDF Name",
			editWidth: "Adjust Width",
			editX: "Adjust X Position",
			editY: "Adjust Y Position",
			editAlign: "Adjust Alignment",
			cloneField: "Duplicate Field",
			editField: "Edit Field",
			editFields: "Edit Fields",
			removeField: "Remove Field",
			editPdf: "Edit PDF",
			ShowOn: "Show on: ",
			Mobile: "Mobile",
			GoalExpired: "Goal deadline has passed ",
			InActive: "Inactive",
			dateStatus: "Date Status",
			SelectGroupView: "Select group view",
			GoalCreatedSuccessfully: "Goal created Successfully",
			GoalEditedSuccessfully: "Goal edited Successfully",
			RemoveAllUsers: "Remove all Users",
			AreYouSureRemoveAll: "Are you sure you want to remove all users",
			SelectAllStatuses: "Select all statuses*",
			fieldOutsidePdfError:
				"Placement error: Please keep the PDF field within the boundaries of the PDF.",
			fontSize: "Font Size (in pixels)",
			width: "Element Width (in pixels)",
			height: "Element Height (in pixels)",
			horizontal: "Horizontal Position (X-axis)",
			vertical: "Vertical Position (Y-axis)",
			alignment: "Alignment of Field Content",
			areYouSureDeleteFile: "Are you sure you want to delete this file?",
			UploadQRCode: "Upload QR Code",
			UploadQRCodeInfo: "The QR code need to be uploaded as SVG or PNG.",
			SystemMessages: "System Messages",
			daysAgo: "days ago",
			aDayAgo: "a day ago",
			AppointmentDescription: "You have an appointment scheduled tomorrow at",
			AppointmentReminder: "Appointment Reminder",
			CANCELLED_DOUBLE: "CANCELLED_DOUBLE",
			CancelledDouble: "Cancelled Double",
			CLARIFICATION: "CLARIFICATION",
			Clarification: "Clarification",
			CANCELLED_ALL: "All Cancelled",
			StatusOne: "Status One",
			StatusTwo: "Status Two",
			ChooseImportStatus: "Choose Import Status",
			CancelledAll: "All Cancelled",
			Energy: "Energy",
			statusView: "View by Status",
			streetView: "View by Street",
			firstAndLastName: "First & Last name",
			assignedUsers: "Assigned Users",
			editLead: "Edit Lead",
			duplicateLead: "Duplicate Lead",
			deleteLead: "Delete Lead",
			NewNotification: "You have a new notification!",
			leadsFrom: "Leads from",
			TariffModel: "Tariff Model",
			DistributionModel: "Distribution Model",
			EnergyDistributionModel: "Energy Distribution Model",
			provisionType: "Provision Type",
			Percentage: "Percentage",
			Fixed: "Fixed",
			CareerConditions: "Career Conditions",
			fromStufe: "From Stufe",
			toStufe: "To Stufe",
			targetDays: "Target Days",
			cycle: "Cycle",
			averagePerDay: "Average per day",
			conditionFor: "Condition For",
			stufeLevel: "Stufe Level",
			CreateConditionTable: "Create Condition Table",
			EditConditionTable: "Edit Condition Table",
			OverheadFor: "Overhead for",
			Own: "Own",
			OWN: "Own",
			TEAM: "Team",
			USER_ROLES: "User roles",
			DISTRIBUTION_CHANNEL: "Distribution Channel",
			mapView: "View by Map",
			replacePdf: "Replace PDF",
			StructureCreateFail: "Structure failed to create",
			StructureEditFail: "Structure failed to edit",
			currentStatus: "Current Status",
			noBiggerthan: "Can not be bigger than:",
			BILLING_NEXT_MONTH: "Billing next month",
			BillingNextMonth: "Billing next month",
			CANCELLED_CREDIT_WORTH: "Cancelled credit worth",
			CancelledCreditWorth: "Cancelled credit worth",
			DifferenceProvision: "Difference Provision",
			activities: "Activities",
			lastWeek: "Last Week:",
			lastMonth: "Last Month:",
			last3Months: "Last 3 Months:",
			lastYear: "Last Year:",
			older: "Older:",
			FAILED_AVERAGE: "Failed Avarage",
			FAILED_STUFE: "Failed Stufe",
			COMPLETED: "Completed",
			FAILED: "Failed",
			CyclesCompleted: "Cycles Completed",
			StartingOn: "Starting On",
			RequirementAchievedOn: "Requirement AchievedOn",
			CareerStages: "Career Stages",
			DISTRIBUTION_MODEL: "Distribution Model",
			TARIFF_MODEL: "Tariff Model",
			ENERGY_DISTRIBUTION_MODEL: "Energy Distribution Model",
			businessLicenseUrl: "Business License",
			idNumber: "ID Number",
			vatEntitlement: "Vat Entitlement",
			taxNumberUrl: "Tax Number",
			vatNumberUrl: "Vat Number",
			GenerateSettlementID: "Generate Settlement ID",
			True: "True",
			False: "False",
			weeksAgo: "weeks ago.",
			monthsAgo: "months ago.",
			dateRangeError: "The date must be within the specified range.",
			dateErrorAtLeast: "Please select a date that is at least",
			dateErrorAtMost: "Please select a date that is before",
			EnergyType: "Energy Type",
			ClientType: "Client Type",
			deleteStructureInfo:
				"Deleting this structure will remove all associated career conditions permanently. Are you sure you want to proceed?",
			excludeFromSettlement: "Exclude from Settlement",
			EXCLUDE_SETTLEMENT: "Exclude from Settlement",
			changedBy: "Changed By",
			falseEmail: "This email does not exist!",
			CheckEmail: "Check Email format",
			vpNumberOnlyNumbers: "Vp nummber must contain only numbers",
			dataNotFound: "The requested data could not be found.",
			settlementNumberFormat:
				"Spaces are allowed only if they are directly before or after a comma.",
			"In Process": "In Process",
			Imported: "Imported",
			tariffsInProcess:
				"We're processing your request and generating tariffs in the background. Please hold on a moment.",
			chooseExternalClient: "Choose Client from External Service",
			doYouWantToCreateExternalTariffs:
				"Do you want to create tariffs from External Service?",
			createExternalTariffs: "Create tariffs from External Service",
			StufeOrStructureNotFound: "User has no stufe or structure",
			EditAgencySP: "Edit Agency SP",
			MakeGroupLeader: "Make group leader",
			GroupLeader: "Group Leader",
			AgencyEditedSuccess: "Agency edited successfully",
			AgencyEditedError: "Agency edit failed",
		},
	},
	de: {
		translation: {
			TariffUpdateErrorProvision: "Storno - Reserve kann nicht größer als Provision sein!",
			EditOrder: "Bestellung bearbeiten",
			OrderInfo: "Bestellinfo",
			Continue: "weiter",
			"Willkommen zu React": "",
			Register: "registrieren",
			Account: "Nutzerkonto",
			"Personal Information": "persönliche Daten",
			HaveAccount: "Nutzerkonto vorhanden?",
			ForgotPassowrd: "Passwort vergessen?",
			PasswordRecovery: "Passwort wieder herstellen",
			EnterRecoveryEmail: "Eingabe Email-Adresse für Wiederherstellung",
			RecoveryEmail: "Email-Adresse für Widerherstellung",
			EmailSent: "Email gesendet",
			Send: "senden",
			Dashboard: "Cockpit",
			AllDocuments:
				"Alle Dokumente zu diesem Projekt sind hier abgelegt. Dokumente ändern oder neue Dokumente anlegen.",
			TotalRevenue: "Erwartete Provisionsauszahlungen",
			Sales: "Der Umsatz",
			Deliveries: "Lieferungen",
			LastProductSales: "letzte Verkäufe",
			SaleStatistics: "Übersicht Aufträge",
			Provision: "Bestimmung",
			Product: "Zusatzprodukt",
			NumOfContracts: "Anzahl Verträge",
			Value: "Wert",
			Bonus: "Bonus",

			EditBonus: "Bonus bearbeiten",
			EditCommisson: "Partner-Provision bearbeiten",
			CreateBonus: "Partner-Bonus anlegen",
			Monthly: "MONATLICH",
			month: "Monat",
			Custom: "CUSTOM",
			Frequency: "Zeitspanne",
			from: "von",
			to: "bis",
			send: "senden",
			ClientsList: "Versorger",
			CreateClient: "Versorger erstellen",
			editClient: "Versorger bearbeiten",
			deleteClient: "Versorger Deaktivieren",
			Delete: "Deactive",
			thisClient: "dieser Versorger",
			clientName: "Versorger Name",
			name: "Name",
			email: "E-Mail",
			projectCategories: "Projektkategorien",
			productName: "Name Zusatzprodukt",
			lastUpdate: "letzte Änderung",
			create: "anlegen",
			editCategory: "Projektkategorien",
			deleteCategory: "Projektkategorie deaktivieren",
			thisCategory: " diese Projektkategorien",
			categoryId: "Projektkategorien ID",
			categoryName: "Name Projektkategorien",
			subCategoryName: "Unterkategorie",
			category: "Kategorie",
			products: "Zusatzprodukte",
			comissions: "Partner-Provisionen",
			search: "suchen",
			cancellationReserve: "Storno-Reserve",
			editUser: "Nutzer bearbeiten",
			percentage: "prozentual",
			cValue: "in EURO",
			comission: "Partner-Provision",
			cancellationReserveType: "Storno-Reserve Typ",
			customerType: "Kundentyp",
			tariffId: "Tarif ID",
			editProduct: "Zusatzprodukt bearbeiten",
			deleteProduct: "Produkt deaktivieren",
			thisProduct: "dieses Produkt",
			lastUpdated: "letzte Änderung",
			date: "Datum",
			ProductName: "NAME Zusatzprodukt",
			TheLocation: "Ort",
			ProjectCategory: "Projekt-Phase",
			Created: "Angelegt",
			Paid: "Zahlungsfreigabe",
			Cancelled: "Storno",
			Deliveried: "geliefert",
			Filename: "Dateiname",
			OrderID: "Bestell ID",
			PartnerID: "Partner ID",
			businessLicense: "Geschäftslizenz",
			ProductID: "Zusatzprodukt ID",
			TariffName: "Tarif-Name",
			SalesPartner: "Vertriebspartner",
			Team: "Team",
			teams: "Team",
			ProjectName: "Projekt NAME",
			UserSP: "Nutzer",
			UserSPName: "Nutzer",
			UserTitle: "Titel",
			CreateUser: "Benutzer erstellen",
			ThisUser: "dieser Benutzer",
			EditUser: "Nutzer bearbeiten",
			DeleteUser: "Nutzer Deaktivieren",
			Username: "Nutzername",
			Role: "Rolle",
			role: "Rolle",
			Email: "E-Mail",
			Client: "Versorger ",
			PhoneNumber: "Telefonnummer",
			Address: "Adresse",
			FirstName: "Vorname",
			LastName: "Nachname",
			EmailAddress: "Email-Adresse",
			User: "Benutzer",
			Users: "Benutzer",
			users: "Benutzer",
			TypeToSearch: "Suchen",
			Orders: "Verträge",
			orders: "Verträge",
			CreateOrder: "Vertrag anlegen",
			Add: "Hinzufügen",
			Edit: "Bearbeiten",
			Cancel: "Abbrechen",
			Confirm: "Bestätigen",
			AreYouSureDeactivate: "Sind Sie sicher, dass Sie deaktivieren möchten?",
			AreYouSureDraft: "Sind Sie sicher, dass Sie einen Entwurf erstellen möchten?",
			Settings: "Einstellungen",
			UserDetails: "Nutzer-Details",
			STATISTIC: "STATISTIK",
			Ongoingcases: "Verträge in Bearbeitung",
			Settledcases: "Verträge abgerechnet",
			Totalcases: "Summe Verträge",
			Lastproductsales: "Letzte Produktverkäufe",
			Tariff: "Tarif",
			CancelationReserve: "Stornoreserve",
			DetailsUpperCase: "EINZELHEITEN",

			allOrder: "Alle Aufträge",
			TheSettings: "Einstellungen",
			Accountsettings: "Kontoeinstellungen",
			PublicPofile: "Öffentliches Profil",
			AccDetails: "Kontoeinstellungen",
			PublicProfile: "Öffentliches Profil",
			EnterEmail: "Email eingeben",
			ConfirmChange: "Änderung bestätigen",
			RecoverPassword: "Passwort wiederherstellen",
			ChangeEmail: "Email ändern",
			YourEmailAdress: "Deine Email-adresse ist",
			UploadNewProfilePic: "Neues Profilbild hochladen",
			Change: "ändern",
			CantRemember: "Passwort vergessen?",
			Password: "Passwort",
			ResetYourPassword: "Passwort zurückstellen",
			OldPassword: "altes Passwort",
			NewPassword: "neues Passwort",
			ConfirmPassword: "neues Passwort bestätigen",
			Save: "speichern",
			Company: "Unternehmen",
			ThisTenant: "Versorger",
			TenantList: "Versorgerliste",
			CreateTenant: "Versorger anlegen",
			TenantName: "Versorger Name",
			TenantDomain: "Versorger Domain",
			Domain: "Domain",
			Submit: "absenden",
			Deactivated: "Deaktiviert",
			FirstNameIsReq: "Vorname ausfüllen",
			LastNameIsReq: "Nachname ausfüllen",
			EmailIsRequred: "gültige Email-Adresse eingeben",
			PhoneIsReq: "Telefonnummer angeben",
			TenantNameIsReq: "Versorgername angeben",
			AddressIsReq: "Adresse angeben",
			NameIsReqired: "Name angeben",
			TenantDomainIsReq: "Versorger-Domain angeben",
			ThisTariff: "dieser Tarif",
			Tariffs: "Tarife",
			CreateTariff: "Tarif anlegen",
			DeactivateTariff: "Tarif deaktivieren",
			Calculation: "Abrechnung",
			Contracts: "Verträge",
			Total: "Gesamt",
			TotalPayout: "Auszahlung Provisionen",
			TotalCancellationPayout: "Auszahlung Stornoreserve",
			ID: "ID",
			Approved: "Angenommen",
			Pending: "Parkende Aufträge",
			Status: "Status",
			CreatedAt: "hergestellt in",
			ChangePassword: " Passwort ändern",
			DoYouWant: "Möchten Sie Ihr Passwort ändern?",
			calculationData: "Berechnungsdaten",
			MobilePhone: "Mobiltelefon",
			mobilePhone: "Mobiltelefon",

			Fax: "Fax",

			General: "Allgemeines",
			Communication: "Kommunikation",
			DistributionChannel: "Vertriebsweg",
			Salutation: "Anrede",
			Career: "Karriere",
			ExtVpNr: "Ext.VP-Nr",
			fixedLevel: "Festes Niveau",
			IntConsultant: "Int. Berater",
			Street: "Straße",

			LegalForm: "Rechtsform",
			TaxNumber: "Steuernummer",
			AdvertisedBy: "beworben von",
			Nr: "Nr",
			PostCode: "Postleitzahl",
			Country: "Land",
			salesHistory: "Umsatzhistorie",
			DateOfBirth: "Geburtsdatum",
			Branch: "Ast",
			FromCentralRe: "Von Central Re",
			AccountNumber: "Accountnummer",
			VatRequired: "Vat erforderlich",
			City: "Stadt",
			AreYouSureSettle: "Sind Sie sicher, dass Sie Bestellungen abrechnen möchten?",
			Settlement: "Abrechnung",
			contractInformation: "Vertragsinformation",
			settlementDetails: "Abrechnungsdetails",
			Complete: "Speichern",
			TotalPrice: "Gesamtpreis",
			ProductDescription: "Produktbeschreibung",
			Draft: "Entwurf",
			Published: "Veröffentlicht",
			draft: "Entwurf",
			published: "Veröffentlicht",
			ViewStats: "Statistiken anzeigen",
			AddTemplate: " Vorlage hinzufügen",
			Template: "Schablone",
			Back: "Zurück",
			Publish: "Veröffentlichen",
			AddPhoto: "Foto hinzufügen",
			AddTitle: "Titel hinzufügen",
			Title: "Titel",
			CannotSave: "Eine leere Notiz kann nicht gespeichert werden",
			AreYoutSure: "sind Sie sicher?",
			AllDataLost: "Alle Daten gehen verloren!",
			Agree: "Zustimmen",
			Disagree: "nicht einverstanden",
			Description: "Beschreibung",
			RemoveFields: "Felder entfernen",
			AddFields: "Felder hinzufügen",
			Download: "Herunterladen",
			Loading: "Wird geladen...",
			OnlyLoading: "Wird geladen",
			ChangeStatus: "Status ändern",
			DateCreated: "Erstellt am",
			ActivateUser: "Benutzer aktivieren",
			ActivateCategory: "Kategorie aktivieren",
			ActivateClient: "Versorger aktivieren",
			EditTariff: "Tarif bearbeiten ",
			ActivateTariff: "Tarif aktivieren",
			ActivateProduct: "Produkt aktivieren",
			Trainings: "Schulungen",
			Messages: "Mitteilungen",
			Logout: "Ausloggen",
			EnterRefisteredEmail: "Bitte geben Sie eine registrierte E-Mail-Adresse ein",
			PasswordRequired: "Passwort ist erforderlich",
			PasswordMustBe: "Das Passwort muss mindestens 6 Zeichen lang sein",
			YourEmailOrPassword: "Ihre E-Mail oder Ihr Passwort ist falsch!",
			EmailSentSuccessfully: "Email wurde erfolgreich Versendet!",
			PasswordReset: "Passwort erfolgreich zurückgesetzt!",
			PleaseSelectAction: "Bitte wählen Sie eine Aktion aus",
			ClientNameIsReq: "Versorger-Name ist erforderlich",
			vpNR: "VP NR.",
			TariffIsReq: "Tarif ist erforderlich",
			ProvisionIsReq: "Bereitstellung ist erforderlich",
			ComissionIsReq: "Provision ist erforderlich",
			CancellationReserveIsReq: "Eine Stornierungsreserve ist erforderlich",
			CancellationReserveTypeISReq: " Art der Stornierungsreserve ist erforderlich",
			NumOfContractsIsReq: "Anzahl der Verträge ist erforderlich",
			ValueIsReq: "Wert erforderlich",
			DateIsReq: "Datum ist erforderlich",
			TitleIsReq: "Titel ist erforderlich",
			BonusEditSuccess: "Bonus erfolgreich bearbeitet!",
			BonusDeleteSuccess: "Bonus erfolgreich gelöscht!",
			CilentUpdateSuccess: "Versorger erfolgreich aktualisiert!",
			ClientCreateSuccess: "Versorger erfolgreich erstellt!",
			ClientDeleteSuccess: "Versorger erfolgreich deaktiviert!",
			ClientRestoreSuccess: "Versorger erfolgreich aktiviert!",
			ComisionUpdateSuccess: "Provision erfolgreich aktualisiert!",
			ComisionCreateSucces: "Provision erfolgreich erstellt!",
			OrderCreateSuccess: "Bestellung erfolgreich erstellt!",
			StatusChangeSuccess: "Statusänderung läuft, Registerkarte „Statusverlauf“ prüfen",
			ProductCreateSuccess: "Produkt erfolgreich erstellt!",
			ProductUpdateSuccess: "Produkt erfolgreich bearbeitet!",
			ProductDeactiveSuccess: "Produkt erfolgreich deaktiviert!",
			ProductDeleteSuccess: "Produkt erfolgreich gelöscht!",
			ProductRestoreSuccess: "Produkt erfolgreich aktiviert!",
			CategoryUpdateSuccess: "Kategorie erfolgreich bearbeitet!",
			CategoryCreateSuccess: "Kategorie erfolgreich erstellt!",
			idNumberUrlBack: "ID-Nummer-URL zurück",
			CategoryDeactiveSuccess: "Kategorie erfolgreich deaktiviert!",
			CategoryDeleteSuccess: "Kategorie erfolgreich gelöscht!",
			CategoryRestoreSuccess: "Kategorie erfolgreich aktiviert!",
			TariffCreateSuccess: "Tarif erfolgreich erstellt!",
			TariffUpdateSuccess: "Tarif erfolgreich bearbeitet!",
			financialInformation: "Finanzinformationen",
			CannotUpdateTables:
				"*Sie können keine Tabellen aktualisieren, denen keine Standorte zugewiesen sind",
			TariffDeactiveSuccess: "Tarif erfolgreich deaktiviert!",
			TariffDeleteSuccess: "Tarif erfolgreich gelöscht!",
			TariffRestoreSuccess: "Tarif erfolgreich aktiviert!",
			TenantCreateSuccess: "Mieter erfolgreich angelegt!",
			TenantUpdateSuccess: "Mieter erfolgreich aktualisiert!",
			TenantDeleteSuccess: "Mieter erfolgreich deaktiviert!",
			ImageChangeSuccess: "Bild erfolgreich geändert!",
			ClientSettingsSuccess: "Client-Einstellungen wurden erfolgreich aktualisiert",
			DetailsChangeSuccess: "Sie haben Ihre Daten erfolgreich geändert!",
			EmailChangeSuccess: "Sie haben Ihre E-Mail-Adresse erfolgreich geändert!",
			UserEditSuccess: "Benutzer erfolgreich bearbeitet!",
			UserCreateSuccess: "Benutzer erfolgreich erstellt!",
			UserDeleteSuccess: "Benutzer erfolgreich gelöscht!",
			UserRestoreSuccess: "Benutzer erfolgreich aktiviert!",
			NoRecords: "Keine Datensätze zum Anzeigen",
			InvalidToken: "Entschuldigung, dieses Token ist ungültig",
			RequestNewToken: "Klicken Sie hier, um ein neues Token anzufordern",
			ConfirmPasswordReq: "Passwort bestätigen ist erforderlich",
			PasswrodMatch: "Passwörter müssen übereinstimmen!",
			UserList: "Benutzerliste",
			CreateTeam: "Team erstellen",
			Actions: "Aktionen",
			DescriptionIsReq: "Beschreibung ist erforderlich",
			TotalPriceReq: "Der Gesamtpreis ist erforderlich",
			ProductNameReq: "Produktname ist erforderlich",
			ProductDescriptionReq: "Produktbeschreibung ist erforderlich",
			SelectDate: "Bitte wählen Sie ein Bereichsdatum aus",
			TooShort: "Zu kurz!",
			TooLong: "Zu lang!",
			CountryReq: "Land ist erforderlich",
			CityReq: "Stadt ist erforderlich",
			EmailReq: "E-Mail ist erforderlich",
			SeeMore: "Mehr sehen",
			ProductAddOn: "Produktzusatz",
			RemoveRole: "Rolle entfernen",
			DeactiveUser: "Benutzer deaktivieren",
			editLocation: "Standorte bearbeiten",
			deleteLocation: "Standort löschen",
			deactivateLocation: "Standort deaktiviren",
			thisLocation: " dieser Standort!",
			LocationDeleteSuccess: "Standort erfolgreich gelöscht!",
			LocationDeactivateSuccess: "Standort erfolgreich deaktiviert!",
			UserDeactivateSuccess: "Benutzer erfolgreich deaktiviert!",
			Price: "Preis",
			AreYouSureLeave: "Sind Sie sicher dass du gehen wollen?",
			Yes: "Ja",
			No: "Nein",
			VATEntitlement: "MwSt-Berechtigung",
			VATNumber: "MwSt Nummer",
			BankAccount: "Bankkonto",

			BankAccountHolder: "Kontoinhaber",
			Info: "Info",
			Bank: "Bank",
			Uploads: "Hochladen",
			contractId: "Antrags-ID",
			NoData: "Keine Daten",
			PleaseAddUsers: "Bitte fügen Sie mindestens einen Benutzer hinzu",
			CompanyIsReq: "Unternehmen ist erforderlich",
			postCode: "Postleitzahl",
			iban: "IBAN",
			BirthdateIsReq: "Geburtsdatum ist erforderlich",
			PostCodeIsReq: "Postleitzahl ist erforderlich",
			CompanyName: "Firmenname",
			CompanyNameIsReq: "Firmenname ist erforderlich",
			TaxNumberReq: "Steuernummer ist erforderlich",
			MobilePhoneReq: "Mobiltelefon ist erforderlich",
			AFileIsReq: "Eine Datei ist erforderlich",
			PassportNumberReq: "Passnummer ist erforderlich",
			PassportValidUntil: "Reisepass gültig bis: ",
			PassportValidUntilReq: "Reisepass gültig bis erforderlich",
			TaxOffice: "Finanzamt",
			statisticAndDocuments: "Statistiken und Dokumente",
			TaxOfficeReq: "Finanzamt erforderlich",
			BankAccountReq: "Bankkonto ist erforderlich",
			BankNameReq: "Bankname ist erforderlich",
			BankAccountHolderReq: "Der Name des Bankkontoinhabers ist erforderlich",
			VATEntitlementReq: "Umsatzsteuerberechtigung ist erforderlich",
			VATNumberReq: "Umsatzsteuer-Identifikationsnummer ist erforderlich",
			Iban: "IBAN",
			IbanReq: "IBAN ist erforderlich",
			Bic: "BIC",
			BicReq: "BIC ist erforderlich",
			UserRoles: "Nutzer-Rolle",
			UserRolesReq: "Benutzerrollen ist erforderlich",
			ExtVpNrReq: "Ext.VP-Nr ist erforderlich",
			GeneralInformation: "Allgemeine Informationen",
			AreYouUserCompany: "Unternehmen?",
			PassportInformation: "Ausweisinformationen",
			Passport: "Pass",
			TradeLicense: "Handelslizenz",
			TaxingInformation: "Besteuerungsinformationen",
			SelectFile: "Datei hochladen",
			VATNumberQ: "Umsatzsteuer-Identifikationsnummer (falls zutreffend)",
			VATEntitlementQ: "MwSt.Berechtigung ?",
			TaxOfficeQ: "Name des zuständigen Finanzamtes",
			PersonalInformation: "Persönliche Info",
			Completed: "Vollendet",
			Managment: "Verwaltung",
			Settle: "Abrechnen",
			PrivacyPolicy: "Datenschutz-Bestimmungen",
			ClickHereToSelectUsers: "Benutzer auszuwählen",
			TeamLeader: "Team-Leiter",
			"TEAM LEADER": "Team-Leiter",
			MakeTeamLead: "Teamleiter machen",
			Remove: "Löschen",
			AddUser: "Benutzer hinzufügen",
			LeaveChanel: "Kanal verlassen",
			FileUploaded: "Datei hochgeladen",
			PassportNumber: "Passnummer",
			CopyOfRegister: "Kopie des Registers",
			AreYouSureActivate: "Sind Sie sicher, dass Sie aktivieren möchten?",
			TeamCreatedSuccessfully: "Team erfolgreich erstellt!",
			TeamDeletedSuccesfully: "Team erfolgreich gelöscht!",
			ThisTeam: "dieses Team",
			AddTariff: "Tarif hinzufügen",
			Channels: "Gruppen",
			DirectMessage: "Direktnachricht",
			Member: " mitglied ",
			Members: " mitglieder ",
			AddUsersToChannel: "Benutzer zum Kanal hinzufügen",
			Select: "wählen",
			SendMessage: "Nachricht senden",
			statistic: "Statistik",
			SendMessageTo: "Nachricht senden an ",
			Of: "von",
			Rows: "Zeilen",
			RowsPerPage: "Zeilen pro Seite:",
			FirstPage: "Erste Seite",
			PreviousPage: "Vorherige Seite",
			NextPage: "Nächste Seite",
			agencyLogo: "Agentur logo",

			LastPage: "Letzte Seite",
			ActivateLocation: "Standort aktivieren",
			ProjectCategories: "Projektkategorien",
			SP: "Freie-Mitarbeiter/innen",
			Employee: "Mitarbeiter/in",
			EMPLOYEE: "Mitarbeiter/in",
			"AGENCY-SP": "Agentur-Mitarbeter/in",
			Agency: "Agentur",
			agency: "Agentur",
			AGENCY: "Agenturen",
			TEAMLEADER: "Teamleiter/in",
			Browse: "Durchsuche",
			ConfirmUpload: "Bestätigen und Hochladen starten",
			CsvUploadedSuccessfully: "CSV wurde erfolgreich hochgeladen",
			Imports: "Importe",
			BankInformation: "Bankinformationen",
			Locations: "Standorte",
			subLocation: "Ortsteil",
			ZipCodeIsReq: "Postleitzahl ist erforderlich",
			CreateChannel: "Gruppe erstellen",
			CopyOfRegisterNumber: "Kopie der Registernummer",
			CreateCategory: "Projekt-Phase erstellen",
			TopicNameIsReq: "Themenname ist erforderlich",
			ChannelName: "Gruppe Name",
			AddChannel: "Gruppe hinzufügen",
			CreateTemplate: "Vorlage erstellen",
			TemplateName: "Vorlagenname",
			TemplateNameIsReq: "Vorlagenname ist erforderlich",
			NumberOfCodes: "Anzahl der Codes",
			NumberOfCodesIsReq: "Anzahl der Codes ist erforderlich",
			You: "(Sie)",
			UploadAnImage: "Bild hochgeladen: ",
			Upload: "Versorgerabrechnung importieren",
			Contract: "Vertrag",
			Show: "Show Fehler",
			InvalidCsv: "Ungültig CSV",
			Close: "schließen",
			Valid: "Gültig",
			Gross: "Rohmarge",
			Up: "Hoch",
			Down: "Runter",
			AddCommissionValue: "Bitte fügen Sie einen Provisionswert hinzu ",
			EmployeeComission: "Angestellter Provision",
			EmployeeProvision: "Angestellter Provision",
			SPComission: "freier SP Provision",
			SPProvision: "freier SP Provision",
			AgencyComission: "Agentur Provision",
			AgencyProvision: "Agentur Provision",
			AgencySPComission: "Agentur SP Provision",
			AgencySPProvision: "Agentur SP Provision",
			hrNumberUrl: "URL der Stundennummer",
			TeamLeaderComission: "Team-Leiter Provision",
			TeamLeaderProvision: "Team-Leiter Provision",
			EmployeeComissionIsReq: "Angestellter Provision ist erforderlich",
			EmployeeProvisionIsReq: "Angestellter Provision ist erforderlich",
			SPComissionIsReq: "freier SP Provision ist erforderlich",
			SPProvisionIsReq: "freier SP Provision ist erforderlich",
			AgencyComissionIsReq: "Agentur Provision ist erforderlich",
			AgencyProvisionIsReq: "Agentur Provision ist erforderlich",
			AgencySPComissionIsReq: "Agentur SP Provision ist erforderlich",
			AgencySPProvisionIsReq: "Agentur SP Provision ist erforderlich",
			TeamLeaderComissionIsReq: "Team-Leiter Provision ist erforderlich",
			TeamLeaderProvisionIsReq: "Team-Leiter Provision ist erforderlich",
			ProvisionCategory: "Projektkategorie",
			TariffComission: "VERSORGERPROVISIONEN",
			TariffAgencySP: "Agentur-SP",
			TariffEmployee: "Angestellter",
			TariffAgency: "Agentur",
			TariffSP: "freier SP",
			entryDate: "Beitrittsdatum",
			AddClient: "Versorger erstellen",
			EditClient: "Versorger bearbeiten",
			EditTeam: "Team bearbeiten",
			ContactPersonFirstName: "Kontaktpersons Vorname",
			ContactPersonLasttName: "Kontaktpersons Nachname",
			ContactPersonEmail: "Kontaktpersons Email-Adresse",
			LocationName: "Ortsname",
			ZipCode: "Postleitzahl",
			LocationNameIsReq: "Ortsname ist erforderlich",
			AddCategory: "Projekt-Phase anlegen",
			EditCategory: "Projekt-Phase bearbeiten",
			AddProduct: "Produkt anlegen",
			EditProduct: "Produkt bearbeiten",
			locations: "Standorte",
			product: "Zusatzprodukte",
			EditChannel: "Kanal bearbeiten",
			AddLocation: "Standorte anlegen",
			AddLocations: "Standorte anlegen",
			OnlyLettersAllowed: "Für dieses Feld sind nur Buchstaben erlaubt",
			LocationAdded: "Standort erfolgreich hinzugefügt!",
			Comission: "Versorgerprovisionen",
			vatNumber: "Umsatzsteuer-Identifikationsnummer",
			positiveNum: "Zahl muss positiv sein",
			FirmaName: "Firma Name",
			EditTenant: "Versorger bearbeiten",
			OldPasswordRequired: "altes Passwort ist erforderlich",
			PermanentDeleteCategory: "Projektkategorie löschen",
			PermanentDeleteLocation: "Ort löschen",
			AreYouSureDelete: "Möchten Sie wirklich dauerhaft löschen ",
			PermanentDeleteTariff: "Tarif löschen",
			PermanentDeleteProduct: "Produkt löschen",
			APPROVED: "Angenommen",
			approved: "Angenommen",
			PAID: "Zahlungsfreigabe",
			PENDING: "Parkende Aufträge",
			CANCELLED: "Storno",
			cancelled: "Storno",
			SETTLED: "ABGERECHNET",
			dateOfBirth: "Geburtsdatum",
			CommissionIsReq: "Provision ist erforderlich",
			ClientOrderID: "Versorger Bestellung ID",
			PleaseWriteThreeChars: "Bitte schreiben Sie nur 3 buchstaben",
			TrainingPhotoUploaded: "Schulungs-Titelbild hochgeladen!",
			ClientOrderIDIsReq: "Versorger-ID ist erforderlich",
			categoryRelatedTo: "Kategorien im Zusammenhang mit",
			HouseNumber: "Hausnummer",
			BirthDay: "Geburtstag",
			Telephone: "Telefon",
			ContactPersonName: "Ansprechpartner",
			Addition: "Zusatz",
			BankHolder: "Bankinhaber",
			SingatureDate: "Unterschriftsdatum",
			BankSalutation: "Bank Anrede",
			BankName: "Bank Name",
			BankZipCode: "Bank Postleitzahl",
			BankLocation: "Bank Ort",
			BankStreet: "Bank Straße",
			BankHouseNumber: "Bank Hausnummer",
			BankIban: "Bank Iban",
			BankBic: "Bank Bic",
			BankSignatureDate: " Bank Unterschriftsdatum",
			AddressSalutation: "Adresse Anrede",
			AdrressFullName: "Adresse Vollständiger Name",
			AddressZipCode: "Adresse Postleitzahl",
			AddressStreet: "Adresse Straße",
			AddressEmail: "Adresse E-mail",
			AddressBirthdate: "Adresse Geburtstag",
			AddressAddition: "Adresse Zusatz",
			AddressHousrNr: "Adresse Hausnummer",
			ProviderSalutation: "Anbieter Anrede",
			ProviderFirstName: "Anbieter Vorname",
			ProviderLastName: "Anbieter Nachname",
			vatEntitlementUrl: "MwSt.-Berechtigungs-URL",
			ProviderStreet: "Anbieter Straße",
			ProviderHouseNumber: "Anbieter Hausnummer",
			ProviderAddition: "Anbieter Zusatz",
			ProviderZipCode: "Anbieter Postleitzahl",
			FullName: "Vollständiger Name",
			Place: "Ort",
			BankCode: "Bankleitzahl",
			BankCreditCardNumber: "Kreditkartennummer der Bank",
			AddressCity: "Adresse Ort",
			ProviderLocation: "Standort des Anbieters",
			ActivePhoneNumber: "Aktive Telefonnummer",
			ProjectLocation: "Gemeinde",
			ContractDate: "Auftrags-Datum",
			SettlementNumber: "Nummer",
			SettlementDate: "Abrechnungs-Datum",
			OrderedDate: "Bestelldatum",
			NumberofCanceled: "Storno",
			SettlmentProvision: "Betrag",
			PermanentDeleteUser: "Benutzer löschen",
			SettlementId: "Auftrags-ID",
			SettlementTarif: "Tarif/Produkt",
			SettlementStatus: "Abrechnungsgrund",
			UploadTenantLogo: "Neues Logo hochladen",
			LogoChangeSuccess: "Logo erfolgreich geändert!",
			clientSettings: "Versorger-Einstellungen",
			AgencySP: "Agentur-SP",
			AGENCYSP: "Agentur-Mitarbeter/in",
			VPNumber: "VP Nummer",
			CategoryNameIsReq: "Kategoriename ist erforderlich",
			TeamUpdatedSuccessfully: "Team erfolgreich aktualisiert!",
			Details: "Einzelheiten",
			SelectTeamLeader:
				"Bitte weisen Sie einen Teamleiter oder Abteilungsleiter zu, um fortzufahren.",
			FormerProviderPorting: "Bisheriger Anbieter Portierung",
			FormerProviderName: "Anbieter Name",
			RowsSelected: "Zeile(n) ausgewählt",
			RowsSelectedOrder: "Alle ausgewählt",
			DownloadPDF: "Down-Load PDF",
			selectCompanyError: "Bitte wählen Sie ein Unternehmen zum Download als CSV-Datei aus!",
			clientNoProduct: "Der Kunde dieser Bestellung hat keine Produkte",
			Next: "Nächste",
			CompleteTraining: "Vollständigen",
			TrainingUpdateSuccess: "Schulung erfolgreich aktualisiert!",
			VPNumberIsReq: "VP-Nummer ist erforderlich",
			LastNameCompany: "Nachname (Firma)",
			Prefix: "Ortsvorwahl",
			Extension: "Durchwahl",
			Delivered: "Exportiert an Versorger",
			DELIVERED: "Exportiert an Versorger",
			AdditionalSalutation: "Zusätzlicher Auftragnehmer Anrede",
			AditionalFirstName: "Zusätzlicher Auftragnehmer Vorname",
			AditionalLastName: "Zusätzlicher Auftragnehmer Nachname",
			AdditionalZipCode: "Zusätzlicher Auftragnehmer Postleitzahl",
			AdditionalStreet: "Zusätzlicher Auftragnehmer Straße",
			AdditionalEmail: "Zusätzlicher Auftragnehmer E-mail",
			AdditionalBirthdate: "Zusätzlicher Auftragnehmer Geburtstag",
			AdditionalAddition: "Zusätzlicher Auftragnehmer Zusatz",
			AdditionalHousrNr: "Zusätzlicher Auftragnehmer Hausnummer",
			AdditionalPlace: "Zusätzlicher Auftragnehmer Ort",
			AdditionalTelephone: "Zusätzlicher Auftragnehmer Telefonnummer",
			AdditionalMobile: "Zusätzlicher Auftragnehmer Handynummer",
			OneFamilyHouse: "Einfamiliesnhaus",
			MoreFamilyHouse: "Mehrfamilienhaus",
			hrNumber: "Std.-Nummer",
			HomeOwner: "Eigentümer/in",
			HomeCoOwner: "Miteignetümer/in",
			extVpNr: "Vp Nummer",
			Renter: "Mieter/in",
			NamePropertyOwner: "Name Hausverwltg. / Eigentümer",
			TermsAndConditions: " Einwilligung Datennutzung",
			EmailNewsLetter: "Newsletter-E-Mail",
			SendNewsLetter: "Newsletter versenden",
			TransferNumber: "Rufnummernübernahme",
			WriteMessage: "Nachricht schreiben",
			FaildToDownload: "Download fehlgeschlagen!",
			ClientLogo: "Versorger Logo",
			exportPDF: "PDF exportieren?",
			OrderProductNumber: "Vertrags-ID Produktgeber",
			DateOfSignature: "Datum der Unterschrift",
			VPName: "VP Name",
			OnlyAvailablePdfsCanBeDownloaded: "Nur verfügbare PDF`s können heruntergeladen werden",
			PickAColorForYourPDFTemplate: "Wählen Sie eine Farbe für Ihren PDF-Vertrag!",
			YourCurrentColor: "Ihre aktuelle Farbe",
			TitleBankdescription: "Titel: SEPA-Lastschriftmandat:",
			TitleEmaildescription: "Titel: E-Mail Newsletter:",
			TitleConfirmationdescription: "Titel: Bestätigung - Auftragserteilung:",
			Writeyourparagraphsfortitleshere: "Schreiben Sie hier Ihre Absätze für PDF-Titel:",
			AddDescription: "Füge eine Beschreibung hinzu",
			RemoveDescription: "Beschreibung entfernen",
			// SNGLE ORDER OBJECT
			orderId: "Auftragsnummer",
			provision: "Provision",
			createdAt: "Hergestellt in",
			tariffName: "Tarifname",
			salesPartnerName: "VP Name",
			projectCategoryId: "Project-Phase ID",
			exitDate: "Austrittsdatum",
			contractUrl: "Vertrags-URL",
			addons: "Produkte",
			totalPayOut: "Gesamtauszahlung",
			totalCancellationReservePayOut: "Auszahlung der Gesamtstornierungsreserve",
			clientId: "Kunden ID",
			homeOwner: "Hauseigentümer",
			canceledOrders: "Stornierte Verträge",
			iAmHomeOwner: "Ich bin alleiniger Eigentümer des Grundstücks und Gebäudes",
			termsAnConditions: "Geschäftsbedingungen",
			formerProviderCheck: "Ehemaliger Provider-Check",
			uuid: "UUID",
			tenantName: "Versorger Name",
			clientOrderId: "Kundenauftrags-ID",
			orderDetailsData: "Auftragsdetails Daten",
			userTitle: "Benutzertitel",
			firstName: "Vorname",
			lastName: "Nachname",
			company: "Gesellschaft",
			userEmail: "Benutzer Email",
			viewOrderDetails: "Bestell Details ansehen",
			id: "ID",
			salutation: "Anrede",
			street: "Straße",
			houseNumber: "Hausnummer",
			zip: "Postleitzahl",
			place: "Ort",
			dayOfBirth: "Geburtstag",
			dayOfBirth1: "Geburtstag",
			phone: "Telefon",
			fax: "Fax",
			mobileNumber: "Handynummer",
			signatureDate: "Unterschriftsdatum",
			signatureDate1: "Unterschriftsdatum",
			addition: "Zusatz",
			tenantId: "Tenant ID",
			viewBankAccount: "Bankkonto anzeigen",
			bankState: "Bank State",
			bankIban: "Bank Iban",
			bankBic: "Bank Bic",
			bankCreditCardNr: "Bank Credit Card Nr",
			bankCode: "Bank Code",
			bankName: "Bank Hausnummer",
			location: "Ort",
			viewAddressForInvoice: "Rechnungsadresse anzeigen",
			abwRAState: "Abw RA State",
			abwRACity: "Abw RA City",
			fullName: "Vollständiger Name",
			contactPersonFirma: "Ansprechpartner Firma",
			viewFormerProvider: "Ehemaligen Anbieter anzeigen",
			formerProvider: "Ehemaliger Anbieter",
			formerProviderName: "Ehemaliger Anbietername",
			locationPrefix: "Standortpräfix",
			activePhoneNumber1: "Aktive Telefonnummer 1",
			activePhoneNumber2: "Aktive Telefonnummer 2",
			activePhoneNumber3: "Aktive Telefonnummer 3",
			activePhoneNumber4: "Aktive Telefonnummer 4",
			activePhoneNumber5: "Aktive Telefonnummer 5",
			activePhoneNumber6: "Aktive Telefonnummer 6",
			providerExtension: "Provider-Erweiterung",
			additionalContractor: "Zusätzlicher Auftragnehmer",
			telephone: "Telefon",
			onThePhone: "Am Telefon",
			firma: "Firma",
			activity: "Aktivität",
			entryOnThePhone: "Eintrag am Telefon",
			sendNewsLetter: "Newsletter versenden",
			emailNewsLetter: "E-Mail-Newsletter",
			groundFloor: "Erdgeschoss",
			underground: "Unter Tage",
			upstairs: "Nach oben",
			floor: "Boden",
			oneFamilyHouse: "Einfamilienhaus",
			apartmentBuilding: "Wohngebäude",
			costumerType: "Kundentyp",
			locationName: "Standortnamen",
			projectCategoryName: "Name der Projektkategorie",
			clientFullName: "Vollständiger Name des Kunden",
			pdfEmail: "PDF-E-Mail",
			addonsViews: "Zusatzprodukte",
			idNumberUrlFront: "ID-Nummer-URL vorne",
			BackOffice: "Back-Office",
			TariffLeader: "Team Leiter",
			All: "Alle",
			gender: "Geschlecht",
			PreviewOrder: "Vertragsvorschau",
			CompanyTypes: "Unternehmensform",
			Distributionchannel: "Vertriebskanal",
			FinancialInformation: "Finanzinformationen",
			CompanyTypesIsReq: "Unternehmensform ist erforderlich",
			GenderReq: "Geschlecht ist erforderlich",
			BusinessLicense: "Gewerbeschein",
			IDNumber: "Personalausweis",
			PassportFront: "Vorderseite",
			PassportBack: "Rückseite",
			Proof: "Nachweis",
			HrNumber: "HR-Nummer",
			HrNumberAuszug: "HR-Auszug",
			AddNewTable: "Neue Tabelle hinzufügen",
			CreateTable: "Tabelle erstellen",
			TableName: "Tabellenname",
			StartDate: "Anfangsdatum",
			EndDate: "Endtermin",
			Until: "bis",
			LocationIsReq: "Standort ist erforderlich",
			AgencyLogo: "Agentur Logo",
			CalculationType: "Abrechnungsart",
			Simple: "Gesamtabrechnung",
			Detailed: "Partnerabrechnung",
			CalculationTypeReq: "Abrechnungsart ausfüllen",
			CanNotAddTableCheckDates:
				"Bitte verwenden Sie nicht dieselben Daten an denselben Orten!",
			TariffTableDeactivatedSuccesss: "Tariftabelle erfolgreich deaktiviert",
			DeactivatedTables: "Deaktivierte Tabellen",

			SignIn: "Anmelden",
			Login: "Anmelden",
			findAccount: "Dein Nutzerkonto finden",
			toContinue: "weiter",
			stepOneTitle: "Einführung Schritt 1",
			stepOneDescription: "Ziel ist die Vermittlung von Grundschritten zur Anwendung",
			stepTwoTitle: "Einführung Schritt 2",
			stepTwoDescription: "Ziel ist die Vermittlung von Grundschritten zur Anwendung",
			stepThreeTitle: "Einführung Schritt 3",
			stepThreeDescription: "Ziel ist die Vermittlung von Grundschritten zur Anwendung",
			stepFourTitle: "Einführung Schritt 4",
			stepFourDescription: "Ziel ist die Vermittlung von Grundschritten zur Anwendung",
			ForgotPassword: "Password vergessen",

			STEP: "SCHRITT",
			OF: "OF",

			sendBtn: "Senden",
			Hi: "Hallo",
			myCases: "Mein Portfolio",
			statistics: "Statistiken",
			Week: "Woche",
			This: "Nutzer existiert nicht",
			Last: "Letzte",

			myMonthlyIncome: "Mein Monatseinkommen",

			successful: "erfolgreich",
			issue: "achtung",
			files: "Dateien",
			uploaded: "hochgeladen",

			My: "Meine",

			Location: "Ort",

			Order: "Auftrag",

			Name: "Name",
			Project: "Projekt",
			Category: "Projektkategorie",

			Partner: "Partner",
			Entry: "Eingabe",

			ClientId: "Auftrags-ID intern",
			reqInfoHere:
				"Bei mehreren Anschlüssen pro Kunde jeden Anschluss als Extraauftrag eingeben!",
			Answer: "Antwort",
			Signature: "Unterschrift",
			writeSignature: "Hier unterschreiben",
			writeSignatureOrder: "Bestätigung Auftragszusammenfassung",
			writeSignatureNumber: "Auftrag Rufnummernmitnahme",
			writeSignaturePlacement: "Auftragserteilung",
			Sign: "Unterschrift",
			reSign: "Unterschrift wiederholen",
			noProdTariff: "Zusatzprodukt hat keine Tarifzuordnung",
			Choose: "Auswahl",
			selectOneClient: "Produktgeber auswählen",
			Clear: "Eingabe löschen",
			signAbove: "Über der Linie unterschreiben",
			taxOffice: "Finanzamt",
			helpCenter: "Hilfe",
			Notifications: "Mitteilungen",
			Allow: "Erlauben",
			Notification: "Mitteilung",
			allowNtfRing: "Mitteilungen erlauben",
			Version: "Version",
			userProfile: "Nutzer-Profil",
			userDetails: "Nutzerdetails",
			bankLastNameFirma: "Nachname (Firma)",

			Security: "Sicherheit",
			changePw: "Password wechseln",
			confPw: "Password bestätigen",
			saveBtn: "Speichern",

			prjCategory: "Projekt-Kategorie",
			prjCategorySelect1: "Nachfragebündlung",
			prjCategorySelect2: "Bauvermarktung",
			prjCategorySelect3: "Nachvermarktung",
			reqFirstName: "Vornamen eingeben",
			reqLastName: "Nachnamen eingeben",
			reqEmail: "Email-Adresse eingeben",
			reqRegEmail: "Regestrierte Email eingeben",
			isEmail: "Gültige Email eingeben",
			minPw: "Passwort muss mind. 8 Zeichen enthalten",
			matchPW: "Passwort stimmt nicht überein",
			searchPlaceholder: "Datei suchen",
			wrongEmailPw: "Email oder Passwort falsch",
			isRequired: "Pflichtfeld",
			projectCategorieTitle: "Projektkategorie ankreuzen",
			projectCategorieText: "In welcher Projektphase wurde der Vertrag abgeschlossen",
			tariffTitle: "Tarif auswählen (Professional-Tarife nur für zugelassene VP)",
			tariffText: "Bei mehreren Verträgen pro Kunde (z.B. Mehrfachanschlüsse)",
			productTitle: "Anschlussort",
			productCategoryText: "Bei mehreren Verträgen pro Kunde (z.B. Mehrfachanschlüsse)",
			dgRefNr: "Auftrags-ID extern",
			Language: "Sprache",
			Gender: "Geschlecht",

			houseNr: "Hausnr",
			Surname: "Vorname",

			birthDate: "Geburtsdatum",

			Mr: "Herr",
			Mrs: "Frau",
			Zipcode: "PLZ",

			phoneNr: " Rufnummer (inkl. Vorwahl)",
			mobileNr: "Mobilnummer",
			bankAccHolder: "Vor- und Nachname des Kontoinhabers",

			totalContacts: "Aufträge gesamt",
			payOut: "Auszahlung",
			cancelPayOut: "Auszahlung Stornoreserve",

			changeDate: "Datum ändern",
			filesUploaded: "Dateien hohgeladen",

			SignInToContinue: "Weiter nach Anmeldung",

			MyIncome: "Mein Einkommen",
			ChooseTariff: "Tarif auswählen",
			ChooseCategory: "Projektkategorie auswählen",
			ChooseProduct: "Zusatzprodukt auswählen",
			SelectClient: "Produktgeber auswählen",
			ChooseTitle: "Anrede auswählen",
			ClientRequired: "Produktgeber erforderlich",
			OrderEntry: "Auftragseingabe",
			OrderEntry2: "Auftragserfassung",
			ClearSignature: "Unterschrift löschen",
			companyType: "Unternehmenstyp",
			Internet: "Internet",
			Tools: "Werkzeuge",
			ConfPassRequired: "Bestätigung Passwort erforderlich",
			AllowNotification: "Mitteilungen erlauben",
			city: "Stadt",
			WeSentNewPassword: "Neues Passwort gesendet",
			WrongEmail: "Email falsch!",
			YouMadeError: "Neuer Auftrag angelegt",
			SomethingWrong: "Fehleingabe - bitte wiederholen!",
			MyOrders: "Meine Aufträge",
			YourSignature: "Deine Unterschrift",

			UserTitleReq: "Nutzer-Titel is required",
			BirthdateReq: "Geburtsdatum erforderlich",
			StreetReq: "Straße erforderlich",
			HouseNrReq: "Hausnummer erforderlich",
			ZipcodeReq: "PLZ erforderlich",
			phoneNumber: "Telefonnummer",
			MobileReq: "Mobilnummer erforderlich",
			BankAccReq: "Vor- & Zuname Kontoinhaber erforderlich",

			TariffReq: "Tarif erforderlich",
			ProjectCategoryReq: "Projektkategorie erforderlich",
			SignatureReq: "Unterschrift erforderlich",
			SelectBirhtday: "Geburtsdatum auswählen",
			NameOrCompany: "Vor- & Nachname (Firma) Kontoinhaber",
			NameOrCompanyReq: "Vor- & Nachname (Firma) Kontoinhaber erforderlich",
			Chat: "Chat",
			myPotentialIncome: "Mein erwartetes Einkommen",
			ContractId: "Auftrags-ID",
			simpleView: "einfache Ansicht",
			Search: "Suche",

			Create: "Erstellen",

			channelName: "Kanal Name",
			NewMessage: "Neue Nachricht",

			members: "Mitglieder",
			member: "Mitglied",
			addMember: "Mitglied hinzufügen",
			addPeople: "Leute hinzufügen",

			leaveChannel: "Kanal verlassen",
			saySmth: "Schribe etwas",
			Message: "Nachricht",
			MessageReq: "Bitte schreiben Sie eine Nachricht!",

			areYourSure: "Bist du sicher ?",
			you: "Sie",

			contactIdReq: "Vertrags-ID erforderlich",
			locationReq: "Ort erforderlich",
			subLocationReq: "Ortsteil erforderlich",
			pdfReq: "Pdf-Datei erforderlich",
			teamReq: "Mannschaft erforderlich",
			chooseLocation: "Ort wählen",
			chooseMunicipality: "Gemeinde auswählen",
			municipality: "Gemeinde",
			chooseTeam: "Team wählen",
			noData: "Zur Zeit Keine Daten!",

			To: "Zu",

			uploadPdf: "PDF hochladen",
			emailExist: "Benutzer mit dieser E-Mail existiert!",
			Titles: "Anrede",
			kontoNumber: "Kontonummer",
			blz: "BLZ",

			extraContract: "Extra Vertrag Hausanschluss",

			prefix: "Ortsvorwahl",
			extension: "Durchwahl",

			telephoneNumber: "Telefonnummer",
			telephoneNumberReq: "Telefonnummer erforderlich",
			bankInformation: "Bankeinzug",
			InvoicingAddress: "Abweichende Rechnungsadresse",
			yes: "Ja",
			no: "Nein",
			ChooseSalutation: "Anrede wählen",
			transferNum: "Kündigung & Rufnummernübernahme",
			notTransferNum: "Ich benötige keine Kündigung",

			notHomeOwner: "Ich bin NICHT alleiniger Eigentümer des Grundstücks und/oder Gebäudes",
			termsAndConditions: "Einwilligung Datennutzung",
			termAndCnd: "Datennutzung",
			contactPerson: "Ansprechpartner",
			contactPersonReq: "Ansprechpartner erforderlich",
			agencyType: "AgenturTyp",
			unfilled: "Keine Angabe",
			Your: "Ihre",
			bankInfo: "Bankinfo",
			generalInfo: "Allgemeine Information",
			formerInfo: "Ehemalige Anbieterinfo",
			addressInfo: "Adressinfo",
			confirmOrder: "Zusammenfassung",
			orderSummary: "Bestellübersicht",
			Business: "Gewerbe",
			PrivateClient: "Privatkunde",
			providerName: "Name jetziger Anbieter",
			providerNameReq: "Name jetziger Anbieter erforderlich",
			providerFirstName: "Vorname Anschlussinhaber",
			providerLastName: "Nachname Anschlussinhaber",

			entryOnThePhoneCheckbox: "Eintrag ins Telefonbuch",

			noAdditionalProducts: "Zur Zeit keine Zusatzprodukte verfügbar",
			SubscribeToNewsletter: "Newsletter-E-Mail abonnieren",
			DoYouWantToSubscribeToNewsletter: "Möchten Sie einen Newsletter abonnieren?",

			youHaveChange: "Du hast deine verändert ",

			apartmentHabitations: "Wohneinheiten",
			apartmentHabitationsReq: "Wohneinheiten erforderlich",

			floorReq: "Boden erforderlich",

			left: "Links",
			right: "Rechts",
			middle: "Mitte",
			leftChannelMsg: "Du hast den Kanal verlassen",
			invalidIban: "IBAN ist ungültig",
			owner: "Eigentümer/in",
			renter: "Mieter/in",
			coOwner: "Miteigentümer/in",
			nameOfPropOwner: "Name Hausverwltg./Eigentümer",
			userAddSuccess: "Benutzer erfolgreich hinzugefügt",
			orderSignature: "Datum der Unterschrift",
			orderProductNumber: "Vertrags-ID Produktgeber",
			orderProductNumberIsReq: "Vertrags-ID Produktgeber erforderlich",
			orderProductNumberLength: "Vertrags-ID Produktgeber muss 9-stellig sein",
			orderClipLength: "Auftragsklammer muss 9-stellig sein",
			syncData: "Synchronisieren Lokaler Daten",
			offlineOrders: "Offline-Bestellungen",
			mustBeNumbers: "Darf nur Ziffern sein",
			orderSignatureIsReq: "Datum der Unterschrift erforderlich",
			TeamMembers: "Teammitglieder",
			CalculationPreview: "Abrechnungsvorschau",
			AgencyWith: "Agentur mit",
			OrderEditSuccess: "Bestellung erfolgreich bearbeitet",
			OrderEditError: "Bestellung konnte nicht aktualisiert werden!",
			ManagementInfo: "Management Info",
			reqSalutation: "Anrede erforderlich!",
			DatePaid: "Datum Wan ist Bezahlt",
			SetdateorderSuccess: "Bestelldatum erfolgreich ausgewählt",
			Setdateordererror: "Bitte wählen Sie ein Datum!",
			TrainingDeletedSuccessfully: "Schulung erfolgreich gelöscht",
			TrainingDraftedSuccessfully: "Schulung erfolgreich erstellt",
			Couldnotdeletetraining: "Training konnte nicht gelöscht werden",
			Couldnotdrafttraining: "Training konnte nicht entworfen werden",
			AreUSureCalculation: "Sind Sie sicher, dass Sie dieses Datum hinzufügen möchten?",
			Settled: "Abgerechnet",
			PaidContracts: "Abzurechnende Verträge",
			allOrders: "Alle Bestellungen",
			AllClients: "Alle Kunden",
			AgencyLogoReq: "Agentur-Logo ist erforderlich!",
			Cantchangeteamdatesreq: "Team kann an diesem Datum nicht gewechselt werden!",
			Schreiber: "Schreiber",
			AgencyName: "Agenturname",
			AllTeams: "Team",
			AllAgencies: "Agenturen",
			SubTotal: "Zwischensumme",
			InfoChef: "Info-Chef",
			infoChef: "Info-Chef",
			DeleteTeam: "Nutzer Löschen",
			ChosePDF: "Wählen Sie PDFs",
			keepPhoneNumber: "Rufnummer(n) behalten",
			newNumber: "Neue Rufnummer",
			secondVoiceChannel: "Zweiter Sprachkanal",
			fullData: "Daten mit vollständigen Zielrufnummern speichern",
			abbreviateData: "Daten mit verkürzten Zielrufnummern speichern",
			deleteData:
				"Daten sofort nach Rechnungsstellung oder Hinterlegung im Kundenportal löschen",
			dataPolicy: "Speicherung der Verbindungsdaten",
			none: "keinen",
			shortened: "gekürzt",
			completly: "vollständig",
			homeOwnerFirstName: "Vorname",
			homeOwnerLastName: "Nachname",
			homeOwnerPlace: "Ort",
			homeOwnerForm: "Grundstückseigentümererklärung",
			orderPlacement: "Auftragserteilung/Vertrag",
			advertisingConsent: "Werbeeinwilligung (optional)",
			contractSummary: "Vertragszusammenfassung",
			ChoosePDF: "Wählen Sie PDF",
			DoYouWantToCreateAccount: "Möchten Sie ein Konto erstellen ?",
			firstEmail: "E-Mail anbiete",
			secondEmail: "E-Mail bestellen",
			HelpAndSupport: "Hilfe & Unterstützung",
			Finished: "Abgeschlossen",
			FINISHED: "ABGESCHLOSSEN",
			AddProjectCategories: "Sie haben keine Projektkategorien für diesen Kunden",
			NoSignature: "Unterschrift fehlt",
			NOSIGNATURE: "UNTERSCHFRIFT FEHLT",
			TotalOrders: "Aufträge gesamt",
			TotalApproved: "Anzahl Aufträge angenommen",
			TotalPending: "Anzahl Aufträge parkend",
			OrdersperProvider: "Bestellungen pro Anbieter",
			Male: "Männlich",
			Female: "Weiblich",
			reqFloor: "Please enter a floor",
			reqAddressId: "Please enter a address Id",
			StockWerk: "Floor",
			AddressID: "Address ID",
			accessCancellationReserve: "Zugang Storno Reserve",
			standCancellationReserve: "Stand Storno Reserve",
			payout: "Auszahlung fällig",
			day: "Tag",
			year: "Jahr",
			topUp: "Top-Liste Verkäufer",
			topDown: "Flop-Liste Verkäufer",
			topM: "Oben M",
			topF: "Oben W",
			January: "Jan",
			February: "Feb",
			March: "Mär",
			April: "Apr",
			May: "Kann",
			June: "Juni",
			July: "Juli",
			August: "Aug",
			September: "Sep",
			October: "Okt",
			November: "Nov",
			December: "Dez",
			Monday: "Montag",
			Tuesday: "Dienstag",
			Wednesday: "Mittwoch",
			Thursday: "Donnerstag",
			Friday: "Freitag",
			Saturday: "Samstag",
			Sunday: "Sonntag",
			JANUARY: "JANUAR",
			FEBRUARY: "FEBRUAR",
			MARCH: "MÄRZ",
			APRIL: "APRIL",
			MAY: "MAI",
			JUNE: "JUNI",
			JULY: "JULI",
			AUGUST: "AUGUST",
			SEPTEMBER: "SEPTEMBER",
			OCTOBER: "OKTOBER",
			NOVEMBER: "NOVEMBER",
			DECEMBER: "DEZEMBER",
			GraphByClients: "Aufträge nach Anbieter",
			contractImage: "Foto Hausanschlussvertrag",
			orderClip: "Auftragsklammer",
			orderClipReq: "Auftragsklammer erforderlich",
			custom: "Custom",
			AddNew: "Neu",
			Zuordnung: "Zu Ordnung",
			SalesTracker: "Verkaufstracker",
			Import: "Importieren",
			InProgress: "im Gange",
			Declined: "Zurückgegangen",
			Success: "Erfolg",
			AddACard: "Füge eine Karte hinzu",
			CreateNew: "Erstelle neu",
			ShowDetails: "Zeige Details",
			WriteAComment: "Schreibe einen Kommentar",
			AddStatus: "Status hinzufügen",
			Update: "Aktualisieren",
			NoUsersSelected: "Keine Benutzer ausgewählt*",
			ChooseUsers: "Wählen Sie Benutzer",
			AreuSureDuplicate: "Möchten Sie diesen Lead wirklich duplizieren?",
			ChooseStatus: "Status wählen",
			ChooseAgency: "Agentur wählen",
			ChooseClient: "Auftraggeber auswählen",
			ChooseProject: "Projekt wählen",
			ImportedFrom: "Importiert von",
			totalOrders: "Aufträge gesamt",
			Agencies: "Agenturen",
			SalesPerson: "Verkäufer",
			projectCatergory: "Projektkatergorie",
			viaET: "HAA über ET bei Vertragseingabe",
			viaMFH: "HAA über Wowi/MFH",
			viaPapier: "HAA per Papier",
			NameOfEmployer: "Name des Arbeitgebers",
			PreviousHealthCompanyNumber: "Vorherige Gesundheitsunternehmensnumme",
			PreviousHealthCompany: "Vorherige Gesundhe its firma",
			PlaceOfBirth: "Geburtsort",
			MaritalStatus: "Familienstand",
			CountryOfBirth: "Geburtsland",
			BirthName: "Geburtsname",
			HVSignature: "HV Unterschrift",
			FileSizeError: "Die Dateigröße darf 3 MB nicht überschreiten",
			Max3MB: "(Maximale Dateigröße 3 MB)",
			GenerateExternalID: "Externe ID generieren",
			Tousethesethreedifferentcharacters: "Um diese drei verschiedenen Zeichen zu verwenden",
			TouseThisCharachter: "Um dieses Zeichen zu verwenden, befolgen Sie diese Anweisungen:",
			Usetheverticalbarcharacter:
				"Verwenden Sie das senkrechte Strich-Symbol (|), um jeden Text einzuschließen, den Sie einschließen möchten.",
			UseSquareBracketsCharacter:
				"Verwenden Sie die eckigen Klammern ([ ]), um den gewünschten Text einzuschließen.",
			Usethehashcharacter:
				"Verwenden Sie das Gitter-Symbol (#), um anzuzeigen, dass Sie eine ID eingeben werden, und geben Sie die ID unmittelbar nach dem Gitter-Symbol ein.",
			Usethedollarsigncharacter:
				"Verwenden Sie das Dollarzeichen-Symbol ($), um anzuzeigen, dass Sie ein Enum eingeben werden, und geben Sie dann eins der unten aufgeführten Enums in einem weiteren Satz von Dollarzeichen ein.",
			Herearetheavailableenums:
				"Hier sind die verfügbaren Enums: AKTUELLES_DATUM, AKTUELLER_TAG, AKTUELLER_MONAT oder AKTUELLES_JAHR",
			example: "Beispiel: |Firmenname|, $AKTUELLES_DATUM$, #00001#",
			FileUploadError: "Dateiformat ungültig, Importdatei erneut hochladen",
			LEADID: "POTENTIAL ID",
			Lead: "Potential",
			paidOrders: "Abzurechnende Verträge",
			Leads: "Adresslisten",
			LEAD: "POTENTIAL",
			LeadsLocation: "Standort-Check",
			ImportLead: "Potentiale importieren",
			next: "Weiter",
			Active: "Aktive",
			Filters: "Filter",
			AllUnsavedWillBeLost: "Alle nicht gespeicherten Änderungen gehen verloren",
			PAIDUPPERCASE: "ZAHLUNGSFREIGABE",
			AddPrice: "Preis hinzufügen",
			EditPrice: "Preis bearbeiten",
			PriceReq: "Preis ist erforderlich",
			Leaderboard: "Rangliste",
			SetPrice: "Preis festlegen",
			SetPriceSuccess: "Der Preis wurde erfolgreich festgelegt",
			SetPriceError: "Der Preis konnte nicht festgelegt werden",
			DRAFT: "ENTWURF",
			PUBLISHED: "VERÖFFENTLICHT",
			ChooseAction: "Aktion wählen",
			PreviewPDF: "PDF-Vorschau",
			AreuSurePublish: "Sind Sie sicher, dass Sie veröffentlichen möchten?",
			Today: "Heute",
			Yesterday: "Gestern",
			fileSizeError: "Dateigröße sollte kleiner als 10 MB sein.",
			FileTypeError:
				"Dateiformat sollte .pdf, .png , .jpg , powerpoint , word oder .mp4 sein.",
			NewMessages: "Neue Nachrichten",
			Media: "Medien",
			ShowAll: "Zeige alles",
			GroupInfo: "Gruppeninfo",
			ContactInfo: "Kontaktinformation",
			ChatAdmins: "Chat-Administratoren",
			Participants: "Teilnehmer",
			ClearChat: "Klarer Chat",
			DeleteChat: "Chat löschen",
			ReportUser: "Melde",
			DeleteChannel: "Kanal löschen",
			SearchMessages: "Nachrichten suchen",
			AreYouSureToRemoveFromChannel:
				"Sind Sie sicher, dass Sie diesen Benutzer aus dem Kanal entfernen möchten?",
			AreYouSureToAllowWrite:
				"Sind Sie sicher, dass dieser Benutzer in den Kanal schreiben darf?",
			AreYouSureToDenyWrite:
				"Sind Sie sicher, dass Sie diesem Benutzer das Schreiben in den Kanal verweigern?",
			ChooseTrainer: "Wähle deinen Ausbilder",
			Trainer: "Ausbilder",
			Internship: "Azubi",
			RemoveAsTrainer: "Als Ausbilder entfernen",
			RemoveAsIntern: "Als Azubi entfernen",
			Action: "Aktion",
			AddTrainer: "Ausbilder Hinzufügen",
			AddIntern: "Azubi Hinzufügen",
			CreateTrainer: "Ausbilder erstellen",
			CreateIntern: "Azubi erstellen",
			trainerReq: "Ausbilder ist erforderlich",
			UploadLengthInfo: "Sie können nicht mehr als 5 Dateien hochladen",
			AreYouSureToLeaveChannel: "Sind Sie sicher, dass Sie den Kanal verlassen möchten?",
			OrderList: "Auftragsliste",
			Billing: "Abrechnung",
			SubLocation: "Teilort",
			NotReached: "Nicht erreicht",
			NoInterest: "Kein interesse",
			FollowUp: "Wiedervorlage",
			Appointment: "Terminvereinbarung",
			Comments: "Kommentare",
			BlackList: "Blacklist",
			CommissionOverview: "Übersicht Provisions-Abrechnung",
			HotlineQueries: "Kontakt bei Rückfragen",
			BillingNumber: "Abrechnungs Nr.",
			BillingDate: "Erstelldatum",
			InformationReserveAccount: "Info Rücklagenkonto - Stornoreserve",
			BalanceBeforeBilling: "Kontostand Alt",
			NewAdditionCancellationReserves: "Zugang Rücklagen",
			CancellationDeductions: "Storno-Abzüge",
			Withdrawals: "Auszahlungen",
			BalanceNEW: "Kontostand NEU",
			Commission: "Provision",
			Cancellation: "Storno",
			total: "Summe",
			ProductProvider: "Produktgeber",
			TypeOfRevenue: "Umsatzart",
			NewContracts: "Neuverträge",
			AdditionalProd: "Zusatz-Prod.",
			OwnSales: "Eigenumsatz",
			TotalCommission: "Summe Provision",
			PaymentOfReserves: "Aszahlung Rücklagen",
			TotalSettlement: "Summe Abrechnungsbetrag",
			VAT: "UST",
			WithdrawalAmount: "Auszahlungsbetrag",
			postcode: "PLZ",
			additive: "Zusatz",
			WeGrouping: "WE-Gruppierung",
			surname: "Nachname",
			ValueAddedTax: "Umsatzsteuerausweis gemäß",
			DeatilsOnBilledContracts:
				"Details zu abgerechneten Verträgen & Produkten siehe zugehörige Excel-Tabelle!",
			StatementInfos:
				"Diese Abrechnung dient der Vorab-Information und ist nicht nicht für steuerliche und/oder buchhalterische Zwecke geeignet. Entsprechende Gutschriften mit Verweis auf die jeweilige Abrechnungsnummer werden direkt von Deiner Agentur versandt.",

			and: "und",
			others: "andere",
			allUsersChannel: "alle user",
			EditGroup: "Gruppen bearbeiten",
			newAdminReq: "Neuer Administrator muss ausgewählt werden",
			AssignNewAdmin: "Weisen Sie dem Kanal einen neuen Administrator zu",
			AreUSureCopy: "Sind Sie sicher, dass Sie diese Tabelle kopieren möchten?",
			Copy: "Kopieren",
			NoLeadsInfo: "Sie haben keine Potential zum Exportieren",
			BillingDetails: "Rechnungsdetails",
			ManagingDirectors: "Geschäftsführer",
			Jurisdiction: "Gerichtsstand",
			CompanyWebsite: "Unternehmenswebseite",
			BankDetails: "Bankdaten",
			EmailSettings: "Email Einstellungen",
			RegisterTemplate: "Kontovorlage registrieren",
			RestoreTemplate: "Passwortvorlage wiederherstellen",
			BillingDetailsUpdated: "Die Rechnungsdetails wurden erfolgreich aktualisiert",

			EmailSettingsUpdated: "Die E-Mail-Einstellungen wurden erfolgreich aktualisiert",
			FileSizeError1MB: "Die Dateigröße darf 1 MB nicht überschreiten",
			uploadLengthInfo: "Sie können nicht mehr als 3 Dateien hochladen",
			EmailSubject: "E-Mail Betreff",
			EmailDescription: "E-Mail-Beschreibung",
			SendEmailForOrder: "Senden Sie eine E-Mail für die Bestellung",
			IncludeGeneratedPDF: "Generiertes PDF einschließen",
			herearetheavailableenums:
				"Hier sind die verfügbaren Enums: Vorname, Nachname, Kundenname oder KundenID",
			EXAMPLE: "Beispiel: [vorName], [nachName], [kundenName], [kundenId] , [email]",
			TenantLogo: "Versorger Logo",
			CreateCustomField: "Erstellen Sie ein benutzerdefiniertes Feld",
			EditCustomField: "Benutzerdefiniertes Feld bearbeiten",
			FieldName: "Feldname",
			FieldNameEn: "Feldname in Englisch",
			FieldNameDe: "Feldname auf Deutsch",
			ChooseInputType: "Wählen Sie den Eingabetyp",
			ShowField: "Feld anzeigen",
			Fields: "Felder",
			FieldNameReq: "Feldname ist erforderlich",
			FieldNameDeReq: "Feldname in Deutsch ist erforderlich",
			FieldNameEnReq: "Feldname in Englisch ist erforderlich",
			InputTypeReq: "Eingabetyp ist erforderlich",
			CustomFieldUpdated: "Benutzerdefiniertes Feld erfolgreich aktualisiert",
			CustomFieldCreated: "Benutzerdefiniertes Feld erfolgreich erstellt",
			NameDeReq: "Name in deutscher Sprache erforderlich",
			PickAColor: "Bitte wählen Sie eine Farbe",
			StatusReq: "Status ist erforderlich",
			LeadStatusCreated: "Lead-Status erfolgreich erstellt",
			LeadStatusUpdated: "Lead-Status erfolgreich aktualisiert",
			LeadStatusDeleted: "Lead-Status erfolgreich gelöscht",
			StatusDeleted: "Status erfolgreich gelöscht",
			StatusCreated: "Status erfolgreich erstellt",
			StatusEdited: "Status erfolgreich aktualisiert",
			CreateLeadStatus: "Lead-Status erstellen",
			EditLeadStatus: "Lead-Status bearbeiten",
			EditStatus: "Status bearbeiten",
			CreateStatus: "Status erstellen",
			LeadStatuses: "Lead-Status",
			StatusName: "Statusname",
			StatusNameDe: "Statusname auf Deutsch",
			ChooseLeadStatus: "Wählen Sie Lead-Status",
			ChooseLeadStatusType: "Wählen Sie Lead-Statustyp",
			Selected: "Ausgewählt",
			MoveRight: "Nach rechts bewegen",
			MoveLeft: "Geh nach links",
			LeadStatusTypeReq: "Der Lead-Statustyp ist erforderlich",
			ReplaceUsers: "Benutzer ersetzen",
			RemoveUsers: "Benutzer entfernen",
			SelectUser: "Nutzer wählen",
			Replace: "Ersetzen",
			AreYouSureRemove: "Sind Sie sicher, dass Sie es entfernen möchten",
			fromSelectedLeads: " von ausgewählten Leads?",
			ReplaceLeadFrom: "Ersetzen Sie Leads von",
			ColorHex: "Farbe Hex",
			CantEditWithoutUsers:
				"Sie können Leads nicht bearbeiten, ohne dass Benutzer zugewiesen sind",
			SelectLeadsToViewActions:
				"Wählen Sie mindestens einen Lead aus, um Aktionen anzuzeigen*",
			SortBy: "Sortiere nach...",
			SortByStreet: "Nach Straße sortieren",
			DeleteStatus: "Status löschen",
			statusHistory: "Statusverlauf",
			Warning: "Warnung",
			"In Process": "In Bearbeitung",
			ProgressStatus: "Fortschrittsstatus",
			uploadedBy: "Hochgeladen von",
			startTime: "Startzeit",
			endTime: "Endzeit",
			UpdatedStatus: "Aktualisierter Status",
			AreuSureDeleteSettlement:
				"Sind Sie sicher, dass Sie diese Abrechnung löschen möchten ?",
			DeleteSettlement: "Abrechnung löschen",
			"Revert Success": "Erfolg zurücksetzen",
			"Revert In Process": "In Bearbeitung wiederherstellen",
			Failed: "Fehlgeschlagen",
			OrderStatusCantChange:
				"Der Bestellstatus kann nicht auf einen niedrigeren Status geändert werden.",
			FinishedOrderError:
				"Eine abgeschlossene Bestellung kann nur in storniert geändert werden",
			DeliveredOrderError:
				"Bestellungen, die nicht geliefert werden, können ihren Status nicht in „Fertig“ ändern.",
			WrongStatus: "Falscher Status",
			CancelledOrderError: "Der Status stornierter Bestellungen kann nicht geändert werden",
			Options: "Optionen",
			Cancellations: "Stornierungen",
			UserExistsInLeads: "Dieser Benutzer ist in diesen Leads bereits vorhanden",
			UserExistsInLead: "Dieser Benutzer ist in diesem Lead bereits vorhanden",
			StatusChangePending:
				"Sie haben eine laufende Aktion, warten Sie, bis sie abgeschlossen ist",
			numOfOrders: "Anzahl der Bestellungen",
			SameOrdersError: "Die Bestellung hat diesen Status bereits",
			Reverted: "Zurückgesetzt",
			PriceDeleted: "Der Preis wurde erfolgreich gelöscht",
			PriceEdited: "Der Preis wurde erfolgreich bearbeitet",
			DeletePrice: "Preis löschen",
			Brand: "Marke",
			ExternalID: "Externe ID",
			EMPLOYEE_SHOP: "Mitarbeiter/in Shop",
			TariffEmployeeShop: "MITARBEITER/IN SHOP",
			EmployeeShopProvision: "Mitarbeiter/in Shop Provision",
			PermanentDeleteLeads: "Potential löschen",
			LeadsDeleted: "Die Potentiale wurden erfolgreich gelöscht.",
			LeadDeleted: "Das Potential wurde erfolgreich gelöscht.",
			YouCantDeleteSuccess: "Sie können keine Potentiale mit dem Status Erfolg löschen.",
			TariffComissionNotFound: "Tarif provision nicht gefunden!",
			TariffComissionTableNotFound: "Tarif provision table nicht gefunden!",
			TenantNotFound: "Mandanten nicht gefunden!",
			UserNotFound: "Benutzer nicht gefunden!",
			RoleNotFound: "Nutzer-Rolle nicht gefunden!",
			TariffNotFound: "Tarif nicht gefunden!",
			ComisionNotFound: "Comission nicht gefunden!",
			FileTypesError: "Sie können nur XLSX- oder CSV-Dateien hochladen",
			UploadLengthInfo1: "Sie können nicht mehr als eine Datei hochladen",
			Revert: "Zurückkehren",
			Permissions: "Berechtigungen",
			ShowWeb: "Im Web anzeigen",
			ShowMobile: "Auf Mobilgeräten anzeigen",
			deactivatedBy: "Deaktiviert Durch",
			deactivatedAt: "Deaktiviert Um",
			FileSizeError2MB: "Die Dateigröße darf 2 MB nicht überschreiten",
			UploadLengthInfo10: "Sie können nicht mehr als 10 Dateien hochladen",
			TotalCancelled: "Anzahl Aufträge storniert",
			SelectCorrectCell: "Bitte wählen Sie eine korrekte Zelle aus",
			ExcelTypeInfo: "Ungültiger Dateityp. Bitte wählen Sie eine Excel-Datei aus.",
			ClientColor: "Versorger Farbe",
			NoDataDownload: "Keine Daten Verfügbar",
			taxNumber: "Umsatzsteuer ID",
			AreYouSureToDeleteImport: "Möchten Sie diesen Import wirklich löschen?",
			EditInernDates: "Praktikantendaten bearbeiten",
			EditTrainerDates: "Trainerdaten bearbeiten",
			InternshipDatesInfo:
				"Die Praktikumstermine sollten innerhalb der Reichweite des Trainers liegen",
			Conditions: "Bedingungen",
			SelfProduction: "Bonus Eigenproduktion FK",
			MinimumContracts: "Mindestanzahl Eigenverträge",
			MinimumContractsTeamMember: "Mindestanzahl Verträge je Teammitglied",
			IndividualContract: "Vergütung je Eigenvertrag",
			MaxTeamSize: "Maximale Teamgröße",
			leaderTrainerCompensation: "Teamleiter / Ausbildervergütung Overhead",
			leaderTrainerProduction: "Teamleiter / Ausbilder Eigenproduction",
			EditTable: "Tabelle bearbeiten",
			ConditionTableDeleted: "Konditionstabelle erfolgreich gelöscht",
			NoPdfsAvailable: "Es ist kein PDF verfügbar",
			SelectOrderStatus: "Wählen Sie Bestellstatus",
			ImportOrder: "Bestellung importieren",
			CheckErrors: "Überprüfen Sie Fehler",
			ImportWithWarnings: "Mit Warnungen importieren",
			SelectAllOptionsInfo:
				"Sie müssen alle Optionen aus der Dropdown-Liste auswählen, um fortzufahren",
			YouCantHaveActions:
				"Während der Import noch läuft, sind keine anderen Aktionen möglich",
			FileId: "Datei-ID",
			ImportOrderData: "Bestelldaten importieren",
			analytics: "Analytik",
			TeamIsReq: "Bitte wählen Sie mindestens ein Team aus",
			autoFillInput: "Automatische Eingabe",
			OrderSettings: "Bestelleinstellungen",
			NoSpacesFileName: "Der Dateiname sollte keine Leerzeichen enthalten",
			assignInputTo: "Eingabe zuweisen",
			SaveFields: "Felder speichern",
			DeleteFields: "Felder löschen",
			CantDeleteWithoutDate: "Felder ohne ausgewähltes Datum können nicht gelöscht werden",
			ChooseDate: "Wählen Sie ein Datum",
			SubjectIsReq: "Betreff ist erforderlich",
			SaveFilesOnServer: "Möchten Sie angehängte Dateien auf dem Server speichern?",
			Connect: "Verbinden",
			TestConnection: "Testverbindung",
			ClientUserName: "Client-Benutzername:",
			ClientPassword: "Kundenpasswort:",
			ClientURL: "Client-URL:",
			ClientPort: "Client-Port",
			NumOfDaysReq: "Anzahl der Tage ist erforderlich",
			URLReq: "URL ist erforderlich",
			EmployeeTL: "Mitarbeiter TL",
			EMPLOYEE_TL: "Mitarbeiter TL",
			"EMPLOYEE_TL/TRAINER": "Mitarbeiter TL / Ausbilder",
			EmployeeAB: "Mitarbeiter AB",
			EMPLOYEE_AB: "Mitarbeiter AB",
			"EMPLOYEE_AB/TRAINER": "Mitarbeiter AB / Ausbilder",
			Agency70: "Agentur 70",
			AGENCY_70: "Agentur 70",
			"AGENCY_70/TRAINER": "Agentur 70 / Ausbilder",
			EmployeeTLRequired: "Mitarbeiter TL ist erforderlich",
			EmployeeABRequired: "Mitarbeiter AB ist erforderlich",
			Agency70Required: "Agentur 70 ist erforderlich",
			selectedTargetClient: "Ausgewählter Zielkunde",
			selectedTargetTariff: "Ausgewählter Zieltarif",
			selectedTargetProduct: "Ausgewähltes Zielprodukt",
			selectedTargetLocation: "Ausgewählter Zielort",
			Performance: "Leistung",
			// Analytics
			predictiveInsights: "Vorhersagende Erkenntnisse",
			visualData: "Visuelle Daten",
			activityReports: "Aktivitätsberichte",
			customizeInsights: "Erkenntnisse Anpassen",
			costumizeAlerts: "Benachrichtigungen Anpassen",
			showOnDashboard: "Auf dem Dashboard Anzeigen",
			removeFromDashboard: "Aus dem Dashboard Entfernen",
			editCard: "Karte Bearbeiten",
			deleteCard: "Karte Löschen",
			userIsReq: "Bitte wählen Sie mindestens einen Benutzer aus",
			clientIsReq: "Bitte wählen Sie mindestens einen Kunden aus",
			teamIsReq: "Bitte wählen Sie mindestens ein Team aus",
			agencyIsReq: "Bitte wählen Sie mindestens eine Agentur aus",
			txtTooLong: "Text ist zu lang",
			titleReq: "Titel ist erforderlich",
			goalReq: "Ziel ist erforderlich",
			amountReq: "Betrag ist erforderlich",
			priorityReq: "Priorität ist erforderlich",
			orderStatusReq: "Bestellstatus ist erforderlich",
			descriptionReq: "Beschreibung ist erforderlich",
			priorityNone: "Keine",
			priorityLow: "Niedrige Priorität",
			priorityMedium: "Mittlere Priorität",
			priorityHigh: "Hohe Priorität",
			Teams: "Teams",
			Clients: "Kunden",
			userList: "Benutzerliste",
			clientList: "Kundenliste",
			agenciesList: "Agenturliste",
			teamList: "Teamliste",
			addUsers: "Benutzer hinzufügen",
			addClients: "Kunden hinzufügen",
			addAgencies: "Agenturen hinzufügen",
			addTeams: "Teams hinzufügen",
			danger: "Gefahr",
			warning: "Warnung",
			good: "Gut",
			excellent: "Ausgezeichnet",
			success: "Erfolg",
			repeat: "Wiederholen",
			goal: "Ziel",
			amount: "Menge",
			targetClients: "Zielkunden",
			targetTarrifs: "Zieltarife",
			targetProducts: "Zielprodukte",
			targetLocations: "Zielorte",
			priority: "Priorität",
			selectStatus: "Status auswählen",
			txtColor: "Textfarbe",
			bgColor: "Hintergrundfarbe",
			previewCard: "Kartenansicht",
			goalInformation: "Zielinformation",
			selectType: "Auswahltyp",
			statusType: "Status-Typ",
			dateType: "Datumsart",
			sendEmail: "E-Mail senden",
			saveOnServer: "Auf Server speichern",
			ChooseDays: "Wählen Sie die Anzahl der Tage",
			ClientFieldsInfo:
				"In diesem Abschnitt können Sie verwalten, welche Felder an einem bestimmten Datum gelöscht werden",
			FieldsDeletionDays:
				"Wählen Sie eine Zahl, die festlegt, nach wie vielen Tagen Bestellfelder mit einem bestimmten Status gelöscht werden",
			FieldDeletionSettings: "Einstellungen zum Löschen von Feldern",
			SFTPServerSettings: "SFTP-Servereinstellungen",
			SFTPServerInfo: "Dieser Abschnitt dient der Konfiguration von SFTP-Serververbindungen",
			PDFDeletionSettings: "PDF-Löscheinstellungen",
			PDFDeletionInfo:
				"In diesem Abschnitt wird festgelegt, wann die generierten PDFs (aus unserer Datenbank) gelöscht werden.",
			PDFDeletionDays:
				"Wählen Sie eine Zahl, die definiert, nach wie vielen Tagen generierte PDFs mit einem bestimmten Status gelöscht werden",
			ConnectedSuccess: "Erfolgreich verbunden",
			CheckCredentials: "Überprüfen Sie die Anmeldeinformationen",
			ContractPdfSettings: "Vertrags-PDF-Einstellungen",
			ContractPdfInfo:
				"In diesem Abschnitt wird festgelegt, in welchem Status generierte PDFs an den Server des Kunden gesendet werden",
			PleaseSelectStatus: "Bitte wählen Sie einen Status aus",
			ServiceInactive: "Dienst inaktiv",
			EmailSentTo: "Bestätigungs-E-Mail gesendet an:",
			sent: "Gesendet:",
			ProductPrice: "Produktpreis",
			ProductPriceIsReq: "Produktpreis ist erforderlich",
			TariffPrice: "Tarifpreis",
			TariffPriceIsReq: "Tarifpreis ist erforderlich",
			TariffPriceNoNegatives: "Der Tarifpreis darf kein negativer Wert sein",
			ShowAs: "Anzeigen als",
			assignProductsToCalc: "Produkte für Berechnung auswählen",
			AssignProduct: "Produkt zuordnen",
			sendOfferEmail: "Angebot per E-Mail senden",
			OfferEmailSentTo: "Angebots-E-Mail gesendet an:",
			saveOnClientServer: "Auf Client-Server speichern",
			SendEmailForOrderInfo:
				"Dieses Feld legt fest, ob beim Erstellen der Bestellung eine E-Mail gesendet wird",
			IncludeGeneratedPDFInfo:
				"Dieses Feld bestimmt, ob PDFs generiert werden, wenn die Bestellung heruntergeladen wird",
			firstEmailInfo:
				"Dieses Feld bestimmt, ob dieser Kunde im Angebots-E-Mail-Bereich der mobilen App auswählbar ist",
			secondEmailInfo:
				"Dieses Feld legt fest, ob bei der Auftragserstellung eine E-Mail gesendet wird",
			exportPDFInfo:
				"Dieses Feld bestimmt, ob die Bestellungen dieses Kunden heruntergeladen werden können",
			LeadsInfo: "Dieses Feld bestimmt, ob dieser Lead diesem Kunden zugeordnet werden kann",
			LeadsLocationInfo:
				"Dieses Feld legt fest, ob Leads dieses Kunden Geolokalisierung verwenden",
			CancellationCustomer: "Widerruf Kunde",
			CANCELLATIONCUSTOMER: "WIDERRUF_KUNDE",
			CancelledByProvider: "Auftrag vom Anbieter storniert",
			CANCELLEDBYPROVIDER: "AUFTRAG_VOM_ANBIETER_STORNIERT",
			OrderIncorrect: "Auftrag fehlerhaft",
			ORDERINCORRECT: "AUFTRAG_FEHLERHAFT",
			ContractReactivated: "Vertrag reaktiviert",
			CONTRACTREACTIVATED: "Erledigt",
			changeColorThemes: "Farbthemen ändern",
			mainColor: "Hauptfarbe",
			secondaryColor: "Sekundäre Farbe",
			hoverColor: "Hover-Farbe",
			EditSchedule: "Zeitplan bearbeiten",
			PublishNow: "Jetzt veröffentlichen",
			ScheduleDate: "Plandatum",
			DownloadInProcess:
				"Der Download wird im Hintergrund abgeschlossen (nicht neu laden, bis der Download abgeschlossen ist) …",
			DownloadDone: "Download abgeschlossen!",
			DownloadFailed: "Herunterladen fehlgeschlagen!",
			EntryDate: "Eintrittsdatum",
			ExpiryDate: "Verfallsdatum",
			Export: "Exportiert",
			SelectMonth: "Wähle einen Monat",
			EmployeeStatus: "Status des Mitarbeiters",
			Scheduled: "Geplant",
			NumOfPoints: `Anzahl ${!MPC ? "Punkte" : "MPC"}`,
			ProvisionNew: "Provision Neu",
			StornoNew: "Storno neu",
			OverheadBonuses: "Overhead Boni",
			OldCancellationReserve: "Info Storno Reserve alt",
			NewCancellationReserve: "Info Storno Reserve neu",
			BillingStatus: "Status der Abrechnung",
			ManualBookings: "Manuelle Buchungen",
			PayoutCancellationReserve: "Auszahlung Storno Reserve",
			DepositCancellationReserve: "Einzahlung Stornoreserve",
			ValueAddedtax: "Umsatzsteuer",
			NoDateForThisPeriod:
				"Leider konnten wir die Datei nicht generieren. Für den ausgewählten Zeitraum sind keine Daten verfügbar.",
			Points: !MPC ? "Punkte" : "MPC",
			NoNegativePoints: `Bitte geben Sie einen positiven Wert für ${
				!MPC ? "Punkte" : "MPC"
			} ein`,
			PointsAchieved: `Erreichte ${!MPC ? "Punkte" : "MPC"}`,
			PointTarifAndProduct: `${!MPC ? "Punkte" : "MPC"} Tarif und Produkte`,
			TypeIsReq: "Typ ist erforderlich",
			MinimumPoints: `Minimale proprietäre ${!MPC ? "Punkte" : "MPC"}`,
			MinimumPointsTeamMember: `${!MPC ? "Mindestpunktzahl" : "Minimale MPC"} pro Team`,
			MonthlyMonitoringReport:
				"Monatlichen Monitoring Bericht zur Personalentwicklung und Qualifikation.",
			MonitoringTrainer: "Qualifizierung Überwachung Vorraussetzungen Ausbilder:",
			MonitoringLeader: "Qualifizierung Überwachung Vorraussetzungen Teamleiter:",
			MonitoringHoD: "Qualifizierung Überwachung Vorraussetzungen Niederlassungsleiter:",
			MaintainStatusTrainer: "Überwachung Status behalten Ausbilder:",
			MaintainStatusLeader: "Überwachung Status Teamleiter behalten:",
			MaintainStatusHoD: "Überwachung Status Niederlassungsleiter behalten:",
			fullname: "Vorname Nachname",
			PointsLastMonth: `${!MPC ? "Punkte" : "MPC"} letzter Monat`,
			PointsLast3Months: "Durchschnitt letzte 3 Monate",
			QualificationTrainer: "Qualifikation Ausbilder",
			PointsLastMonthTrainer: `Azubi mit 40-71 ${
				!MPC ? "Punkte" : "MPC"
			} monatlich letzter Monat`,
			sizeInterns: `Azubi mit 40-71 ${
				!MPC ? "Punkte" : "MPC"
			} monatlich seit Beginn Ausbilder`,
			averageOnTrainerPoints: `Eigene ${
				!MPC ? "Punkte" : "MPC"
			} je Tag Durchschnitt letze Monate`,
			NrTeamMembers: "Anzahl der Teammitglieder",
			trainersLongerThanSixMonths: "Davon Ausbilder länger als 6 Monate",
			teamTurnOver: "Teamumsatz im Monat nach Ernennung Niederlassungsleiter",
			qualificationHeadOfDepartment: "Qualifikation Niederlassungsleiter",
			trainersLongerThanSixMonthsSecond: `Azubi mit 40-71 ${
				!MPC ? "Punkte" : "MPC"
			} monatlich letzte 6 Monate`,
			statusConfirmed: "Status Bestätigt",
			teamTurnOverLeader: `Teamumsatz je Tagund Teammitglied letzter Monat in ${
				!MPC ? "Punkten" : "MPC"
			}`,
			teamTurnOverSinceBeginning: `Teamumsatz je Tagund Teammitglied letzte Monate in ${
				!MPC ? "Punkten" : "MPC"
			} im Durchschnitt`,
			teamTurnOverSixMonthsPerMonth: `Teamumsatz je Monat letzte 6 Monate in ${
				!MPC ? "Punkten" : "MPC"
			} im Durchschnitt`,
			Monitoring: "Überwachung",
			MinPoints: `${!MPC ? "Mindestpunktzahl" : "Minimaler MPC"}`,
			MinPointsIsReq: `${!MPC ? "Mindestpunktzahl" : "Minimaler MPC"} ist ein Pflichtfeld`,
			MaxPoints: `Maximale ${!MPC ? "Punktezahl" : "MPC"}`,
			MaxPointsIsReq: `Maximale ${!MPC ? "Punktezahl" : "MPC"} ist ein Pflichtfeld`,
			LeadDuplicated: "Lead erfolgreich dupliziert",
			AreYouSureToShow: "Sind Sie sicher, dass Sie es zeigen möchten",
			AreYouSureToHide: "Sind Sie sicher, dass Sie sich verstecken möchten?",
			SelectHeadOfDepartment: "Wählen Sie den Abteilungsleiter aus",
			HeadOfDepartment: "Abteilungsleiter",
			TeamInfo: "Teaminformationen",
			TeamName: "Teamname",
			SelectedUsers: "Ausgewählte Benutzer",
			MobilityBonus: "Staffelprämie",
			InductionBonus: "Einarbeitungsprämie",
			PersonnelDevelopment: "Personalentwicklungs",
			AddNewRow: "Neue Zeile hinzufügen",
			EditRow: "Zeile bearbeiten",
			DeleteRow: "Zeile löschen",
			Production: "Produktion",
			Specification: "Spezifikation",
			MinNrOfTP: "Mindestanzahl TeamProdunktion",
			amountTeam: "Betrag je Vertrag / Punkt Team",
			minNrTeamPoints: `Mindestanzahl der ${!MPC ? "Teampunkte" : "Team MPC"}`,
			amountPointTeam: "Betrag pro Vertrag / Punktmannschaft",
			BranchManager: "Niederlassungsleiter",
			"HEAD OF DEPARTMENT": "Niederlassungsleiter",
			withProductionTrainee: "Mit Produktionspraktikant",
			withoutProductionTrainee: "Ohne Produktionspraktikant",
			paymentAmountOnce: "Zahlungsbetrag einmal",
			startTime2: "Beginn",
			EndTime: "Ende",
			oneTimeAmount: "Einmalbetrag",
			startConditionBonus: "Start Prämie",
			countInternFrom: "Prod. Azubi von",
			countInternTo: "Prod. Azubi bis",
			selectionTeam: "Auswahl Team",
			selectionInstructors: "Auswahl Ausbilder",
			selectionTrainees: "Auswahl Azubi",
			duplicateRow: "Zeile duplizieren",
			beginIntern: "Beginn Azubi",
			beginInternPlus1Month: "Beginn Azubi + 31 Tage",
			OwnContracts: "Eigenverträge",
			TeamMember: "Teammitglied",
			TrainerOrInternAssigned:
				"Zu diesen Terminen werden Ausbilder oder Praktikanten einer anderen Zeile zugewiesen",
			uploadFile: "Eine Datei hochladen",
			Copied: "Kopiert",
			promotionTrainer: "Beförderung Ausbilder",
			promotionLeader: "Beförderung Teamleiter",
			mustBeGreaterThan: "muss größer sein als",
			monthlyFix: "Monatlich fix",
			unexpectedError: "Ein unerwarteter Fehler ist aufgetreten.",
			chooseDates: "Datum auswählen",
			categoryEdit: "Kategorie bearbeiten",
			clearFilters: "Filter löschen",
			monthlyFixedFee: "Monatlicher Fixbetrag",
			selectionWho: "Auswahl Wer",
			Produktion: "Produktion",
			selectionWhat: "Auswahl Was",
			inHouseProduction: "Eigenproduktion",
			minInHouseProduction: "Mind. Eigenproduktion",
			until: "Bis",
			paymentAmount: "Zahlungsbetrag",
			cancellations: "Stornos",
			overhead: "Overhead",
			manualBookings: "Manuelle Buchungen",
			totalAmount: "Gesamtbetrag der Auswahl!",
			uploadNew: "Neu hochladen",
			fileName: "Dateiname",
			ChooseType: "Wählen Sie Typ",
			Dragdata: "Daten hierher ziehen oder",
			receivedCommission: "Provision erhalten",
			receivedCommissionIsReq: "Eine erhaltene Provision ist erforderlich",
			receivedCommissionNoNegative:
				"Die erhaltene Provision darf keinen negativen Wert haben",
			contractDocuments: "Vertragsdokumente",
			additionalAgreements: "Zusatzvereinbarungen",
			TableSavedSuccessfully: "Tabelle wurde gespeichert",
			ContactName: "Ansprechpartner beim Versorger",
			ContactEmail: "E-Mailadresse des Ansprechpartners",
			ContactPhone: "Telefonnummer des Ansprechpartners",
			AllWithoutCategory: "Alle Ohne Kategorie",
			Dashboard1: "Dashboard1",
			Dashboard2: "Dashboard2",
			autoIndicator: "Automatischer Indikator",
			priorityType: "Prioritätstyp",
			validFrom: "Gültig ab",
			dateExpiry: "Ablaufdatum",
			documentUploads: "Upload Dokumente",
			revenueComissions: "Einnahmen Provisionen",
			canceledContracts: "Gekündigte Verträge",
			additionalRewards: "Zusätzliche Prämien",
			totalPerMonth: "Summe je Monat",
			selectYear: "Jahr auswählen",
			dashboardAdmin: "Cockpit Admin",
			dashboardManagment: "Cockpit Managment",
			firstNameNonEmpty: "Das Feld Vorname darf nicht leer sein.",
			lastNameNonEmpty: "Das Feld Nachname darf nicht leer sein.",
			Support: "Unterstützung",
			DangerBehindTheTarget:
				"Gefahr: Zielverfehlung, dringende Maßnahmen erforderlich, um Ziel zu erreichen.",
			WarningFallingShortOfTarget:
				"Warnung: Zielverfehlung, Aufmerksamkeit erforderlich, um Zielverfehlung zu vermeiden.",
			GoodProgressingWellToWardsTheTarget:
				"Gut: Gute Fortschritte in Richtung Ziel, Dynamik beibehalten.",
			ExcellentOnTrackToMeetOrExceedTheTarget:
				"Exzellent: Auf Kurs, Ziel zu erreichen oder zu übertreffen, aktuelle Bemühungen fortsetzen.",
			SuccessGoalAchievedOrSurpassed:
				"Erfolg: Ziel erreicht oder übertroffen, Glückwunsch zum Erreichen des Ziels!",
			DownloadAll: "Alles herunterladen",
			ChangedAt: "Geändert am",
			dateOfContract: "Date Of Contract",
			Marketplace: "Marktplatz",
			apiKeyMissing: "API-Schlüssel fehlt",
			apiKeyMissingInfo:
				"Ups! Es sieht so aus, als hätten Sie keinen API-Schlüssel angegeben. Um diese Funktion weiterhin zu nutzen, fügen Sie bitte Ihren API-Schlüssel im Marktplatz hinzu.",
			addNewApiKey: "Neuen API-Schlüssel hinzufügen",
			Hide: "Verstecken",
			SecondShow: "Anzeigen",
			areYouSureDeleteMsg: "Sind Sie sicher, dass Sie diese Nachricht löschen möchten?",
			delete: "Löschen",
			copy: "Kopieren",
			reply: "Antworten",
			RowEditSuccess: "Zeile erfolgreich bearbeitet!",
			RowDeleteSuccess: "Zeile erfolgreich gelöscht!",
			RowCreateSuccess: "Zeile erfolgreich erstellt!",
			settleOrders: "Aufträge abrechnen",
			chooseYear: "Jahr auswählen",
			chooseMonth: "Monat auswählen",
			yearReq: "Jahr ist erforderlich.",
			monthReq: "Monat ist erforderlich.",
			descriptionMaxLen: "Die Beschreibung darf höchstens 70 Zeichen lang sein.",
			allSettlements: "Alle Berechnungen",
			LockedText: "Bitte wenden Sie sich an Ihren Administrator, um Zugriff anzufordern.",
			LockedAccount: "Gesperrtes Konto",
			TenantLockSuccess: "Mandant erfolgreich gesperrt!",
			AreYouSureToLocked: "Sind Sie sicher, dass Sie den Mieter sperren möchten?",
			LockClient: "Kunde sperren",
			UnlockClient: "Kunde entsperren",
			companyInfo: "Firmeninformation",
			billingDetailsInfo: "Rechnungsinformationen, Logo hochladen, Themen",
			emailTemplate: "E-Mail-Vorlage",
			profilePic: "Profilbild",
			changeLang: "Sprache ändern",
			selectLang: "Wählen Sie Ihre bevorzugte Sprache",
			emailTemplatesUpdated: "E-Mail-Vorlagen wurden erfolgreich aktualisiert!",
			upload: "Hochladen",
			UploadedFiles: "Hochgeladene Dateien",
			ChooseMatchType: "Übereinstimmungstyp auswählen",
			noNegative: "darf keinen negativen Wert haben",
			fieldNonEmpty: "Das Feld darf nicht nur Leerzeichen enthalten.",
			productInfo: "Zusatzprodukte Provisionen",
			tariffInfo: "Tarife",
			ChangePhoto: "Foto ändern",
			Statuses: "Status",
			checkStatusHistory: "Statusverlauf prüfen",
			inProgress: "IM_GANGE",
			youDeletedMsg: "Sie haben eine Nachricht gelöscht",
			otherDeleteMsg: "Ein anderer Benutzer hat eine Nachricht gelöscht",
			OrdersStatus: "Bestellstatus",
			CreateOrders: "Aufträge anlegen",
			CreateLeads: "Leads erstellen",
			ChangeOrderByImport: "Auftragsstatus per Import ändern",
			CreateOrdersByImport: "Aufträge per Import anlegen",
			CreateLeadsByImport: "Leads per Import erstellen",
			ChangeOrderStatus: "Auftragsstatus ändern",
			AddImport: "Importieren Hinzufügen",
			ImportedContracts: "Importierte Verträge",
			ActualContracts: "Aktuelle Verträge",
			Proceed: "Fortfahren",
			tariffProvision: "Tarife Provisionen",
			lastModified: "Zuletzt geändert von: ",
			ImportPublished: "Import wurde veröffentlicht",
			orderHaveToRevert:
				"Zuerst sollte die Bestellung rückgängig gemacht werden. Dann kann sie auf 'Vertrag reaktiviert' gesetzt werden.",
			ValidDatesError: "Das Datumsfeld sollte im Standardformat DD.MM.YYYY vorliegen",
			orderStatus: "Status",
			AreYouSureUnMatch: "Möchten Sie die Zuordnung wirklich aufheben",
			categoryIsReq: "Kategorie ist erforderlich",
			subCategoryIsReq: "Unterkategorie ist erforderlich",
			reactivateContract: "Vertrag reaktivieren",
			reactivate: "Reaktivieren",
			newStatus: "Eingehender Status",
			bonusIsReq: "Bonus ist erforderlich",
			bonusSettled: "Bonus Abwickelt",
			targetClientsReq: "Zielclients sind erforderlich",
			ShowChanges: "Änderungen anzeigen",
			CurrentVersion: "aktuelle Version",
			chooseBonus: "Bonus wählen",
			Relations: "Beziehungen",
			totalLeads: "Total Potential",
			chooseSettlementNumber: "Abrechnungsnummer auswählen",
			bonusSettleRules: `Um Abrechnungen für diese Bonusverträge zu erstellen, folgen Sie bitte diesen Schritten:
			1. Filtern Sie nach Kunde und wählen Sie eine Projektkategorie aus.
			2. Filtern Sie anschließend nach Benutzer, um fortzufahren.
			`,
			BONUS_SETTLED: "Bonus Abgerechnet",
			MatchFieldsError:
				"Um fortzufahren, müssen alle Felder aus dem Import abgeglichen werden",
			EXPORTIERT: "EXPORTIERT",
			ANGENOMMEN: "ANGENOMMEN",
			"UNTERSCHRIFT-FEHLT": "UNTERSCHRIFT-FEHLT",
			SalesOrganizations: "Verkaufsorganisationen",
			thisCategoryIsDeleted: "Diese Kategorie wurde gelöscht!",
			TrainingCreateSuccess: "Training erfolgreich erstellt!",
			ThisFieldIsReq: "Dieses Feld ist erforderlich",
			CantCreateSales: "Verkaufsorganisation kann nicht erstellt werden",
			CantEditSales: "Verkaufsorganisation kann nicht bearbeitet werden",
			EditSalesOrganization: "Verkaufsorganisation bearbeiten",
			DeleteSalesOrganization: "Verkaufsorganisation löschen",
			CreateSalesOrganization: "Verkaufsorganisation erstellen",
			HeadOfSales: "Leiter der Vertriebsorganisation",
			SetHeadOfSales: "Leiter Vertrieb festlegen",
			DDMMYYYY: "TT:MM:JJJJ",
			Deduction1: "GB-Gebühr §4 (1) Abzug",
			Deduction2: "GB-Gebühr §4 (2) Abzug",
			CompensationIncomingCommission: "Vergütung Eingangsprovision",
			AmountPerContract: "Betrag je Vertrag",
			LumpSumAmount: "Pauschalbetrag",
			ConditionsHeadOfSalesOrganisation: "Bedigung Leiter Vertriebsorganisation",
			ToBeUsed: "Anzuwenden für",
			ManagerVO: "Manager VO",
			chooseClientType: "Wählen Sie den Kundentyp aus",
			addRow: "Neue Zeile hinzufügen",
			settleSalesOrgRules: `Um Abrechnungen für den Vertriebsleiter zu erstellen, befolgen Sie bitte diese Schritte:
			1. Filtern Sie nach Auftragsstatus "Abgerechnet“ und nach "Vertriebsorganisation“.
			2. Wählen Sie Verträge aus.`,
			fibreOrders: "Faseraufträge",
			energyOrders: "Energieaufträge",
			supervisor: "Supervisorin",
			supervisorIsReq: "Betreuer erforderlich",
			stufeIsReq: "Stufe ist erforderlich",
			structureIsReq: "Struktur ist gefragt",
			fibreSettlements: "Faserabrechnungen",
			energySettlements: "Energieabrechnungen",
			SalesEdited: "Verkaufsorganisation wurde bearbeitet",
			SalesDeleted: "Verkaufsorganisation wurde gelöscht",
			SalesCreated: "Verkaufsorganisation wurde erstellts",
			ThisSalesOrganization: "Diese Vertriebsorganisation",
			standardTariff: "Standard (Alle Tarife anzeigen)",
			heatingCurrentPump: "Heizstrom Wärmepumpe",
			heatingNight: "Heizstrom Nachtspeicher",
			chargingElectricity: "Ladestrom / Autostrom",
			tariffDigitalSignature: "Tarife mit Digitaler Unterschrift",
			includeBonus: "Jahrespreis inkl. Bonus",
			priceDate: "Price Datum",
			orderInformation:
				"Parken Sie Ihren Auftrag, um zu einem späteren Zeitpunkt weitere Daten zu ergãnzen. Bitte beachten Sie, dass Aufräge nur exportiert werden, wenn Sie aus dem Parken Status genommen werden.",
			StructureEditedSuccess: "Struktur erfolgreich bearbeitet",
			StruccessCreatedSuccess: "Struktur erfolgreich erstellt",
			StuffeEditedSuccess: "Stuffe erfolgreich bearbeitet",
			StuffeCreatedSuccess: "Stuffe erfolgreich erstellt",
			StruccessDeletedSuccess: "Struktur erfolgreich gelöscht",
			StuffeDeletedSuccess: "Stuffe erfolgreich gelöscht",
			Check: "prüfen",
			OrderCreatedError: "Bestellung konnte nicht erstellt werden!",
			OrderCreatedSuccess: "Bestellung wurde erfolgreich erstellt",
			EditConsumption: "Verbrauch bearbeiten",
			AddRowToConsumption: "Zeile zum Verbrauch hinzufügen",
			CreateConsumption: "Verbrauch erstellen",
			SettlementCreateError: "Abrechnung konnte nicht erstellt werden!",
			SettlementCreateSuccess: "Abrechnung wurde erstellt",
			HasSettlementCreated:
				"Mit dieser Bestellung wurde eine Abrechnung für den Vertriebsleiter erstellt!",
			LeadCreated: "Lead erfolgreich erstellt",
			LeadEdited: "Lead erfolgreich bearbeitet",
			ChangeUser: "Benutzer erfolgreich geändert",
			LocationEdited: "Standort erfolgreich bearbeitet!",
			DocumentUploaded: "Dokument erfolgreich hochgeladen",
			startConversation: "Konversation beginnen",
			check: "prüfen",
			dateError: "Bitte wählen Sie ein Datum, das mindestens",
			yearsAgo: "Jahre zurückliegt.",
			SetDate: "Datum einstellen",
			orderStatusSelection: "Bestellstatus Auswahl",
			orderStatusSelectionInfo:
				"Wählen Sie den Standardstatus für neue Kundenbestellungen. Diese Einstellung bestimmt, wie neue Bestellungen anfänglich bearbeitet werden.",
			profile: "Profil",
			SelectAnOption: "Wählen Sie eine Option",
			orderForm: "Bestellformular",
			fieldLabelEn: "Feldname auf Englisch",
			fieldLabelDe: "Feldname auf Deutsch",
			addSection: "Sektion hinzufügen",
			editSection: "Sektion bearbeiten",
			linkPdfToSection: "PDF mit Sektion verknüpfen",
			editOrderField: "Bestellfeld-Einstellungen bearbeiten",
			fieldOptions: "Feldoptionen anpassen",
			exportSortNumber: "Position in der Exportreihenfolge",
			orderSortNumber: "Position in der Anzeigereihenfolge",
			showAs: "Anzeigen als",
			requestType: "Anfragetyp",
			includeTariffInCalculation: "Tarif in die Berechnung einbeziehen?",
			defaultValue: "Standardwert",
			isDisabled: "Ist Deaktiviert",
			isRequiredOnOfferEmail: "Ist erforderlich beim Angebot per E-Mail",
			isAReqField: "Ist ein Pflichtfeld",
			minCharacters: "Minimale Zeichen",
			maxCharacters: "Maximale Zeichen",
			hideWeb: "Im Web-App ausblenden",
			hideMobile: "Im Mobile-App ausblenden",
			showOnOrderSummary: "Im Bestellübersicht anzeigen",
			hideOnPrivateTariffs: "Bei privaten Tarifen ausblenden",
			fixedNoteEn: "Zusätzliche Informationen (Englisch)",
			fixedNoteDe: "Zusätzliche Informationen (Deutsch)",
			dependencyFields: "Abhängige Felder",
			displayOnlySelectedTariff: "Nur ausgewählten Tarif anzeigen",
			showSection: "Sektion anzeigen",
			collapse: "Sektion zusammenklappbar machen",
			sectionNameEn: "Bereichsname (Englisch)",
			sectionNameDe: "Bereichsname (Deutsch)",
			sectionDeleted: "Abschnitt erfolgreich entfernt!",
			sectionCreated: "Abschnitt erfolgreich erstellt!",
			sectionUpdated: "Abschnitt erfolgreich aktualisiert!",
			areYouSureDeleteField: "Sind Sie sicher, dass Sie dieses Bestellfeld löschen möchten?",
			areYouSureDeleteSection:
				"Sind Sie sicher, dass Sie diesen Abschnitt entfernen möchten?",
			orderFieldDeleted: "Bestellfeld erfolgreich gelöscht!",
			fieldLabelEnInfo: "Der Feldname, wenn die Anwendung auf Englisch eingestellt ist.",
			fieldLabelDeInfo: "Der Feldname, wenn die Anwendung auf Deutsch eingestellt ist.",
			exportSortNumberInfo: "Bestimmt die Reihenfolge dieses Feldes im Auftrags-Export.",
			orderSortNumberInfo:
				"Bestimmt die Anzeigereihenfolge dieses Feldes in der Web- und Mobilanwendung.",
			fieldOptionsInfo:
				"Legt die verfügbaren Optionen für dieses Feld fest (durch Kommas getrennt).",
			showAsInfo: `Bestimmt, wie das Feld angezeigt wird (Optionen: Wert, Preis, Kontrollkästchen, Gesamt).

			Wert: Zeigt den tatsächlichen Wert des Feldes an.
			Preis: Zeigt den Preis an, besonders nützlich für Tarif- oder Produktfelder.
			Kontrollkästchen: Zeigt ein Kontrollkästchen an, wenn das Feld tarif-/produktbezogen ist und ein Produkt ausgewählt wurde.
			Gesamt: Berechnet und zeigt den Gesamtpreis der zugewiesenen Tarife/Produkte an.`,
			requestTypeInfo: "Bestimmt die Art der Anfrage, die dieses Feld ausführt.",
			assignProductsToCalcInfo: "Wählt aus, welche Produkte in diesem Feld berechnet werden.",
			includeTariffInCalculationInfo:
				"Aktiviert oder deaktiviert die Berücksichtigung des Tarifs in der Berechnung.",
			AssignProductInfo: "Weist diesem Feld ein Produkt zu.",
			autoFillInputInfo:
				"Bestimmt, aus welchem Lead-Feld dieses Feld automatisch ausgefüllt wird.",
			assignInputToInfo: "Wählt aus, welche anderen Felder dieses Feld automatisch ausfüllt.",
			defaultValueInfo:
				"Legt einen Standardwert für das Feld fest, der nicht entfernt werden kann.",
			isDisabledInfo: "Deaktiviert dieses Feld bei der Auftragserstellung.",
			isRequiredOnOfferEmailInfo:
				"Macht das Feld erforderlich, wenn ein Angebots-E-Mail gesendet wird.",
			isAReqFieldInfo: "Markiert dieses Feld als erforderlich.",
			isMultipleInfo: "Bestimmt, ob im Dropdown mehrere Auswahlen erlaubt sind.",
			displayOnlySelectedTariffInfo: "Zeigt nur den vom Benutzer ausgewählten Tarif an.",
			minCharactersInfo: "Legt die Mindestanzahl von Zeichen fest.",
			maxCharactersInfo: "Legt die Höchstanzahl von Zeichen fest.",
			dependencyFieldsInfo:
				"Wählt Felder aus, die von diesem Feld abhängig sind (ausgeblendet, es sei denn, dieses Feld hat einen Wert).",
			hideWebInfo: "Blendet dieses Feld in der Web-Anwendung aus.",
			hideMobileInfo: "Blendet dieses Feld in der Mobilanwendung aus.",
			showOnOrderSummaryInfo:
				"Zeigt dieses Feld in der Auftragszusammenfassung in der Mobilanwendung an.",
			hideOnPrivateTariffsInfo:
				"Blendet dieses Feld bei privaten Tarifen aus (nur Energie-Kunden).",
			fixedNoteEnInfo: "Zeigt Informationen auf Englisch an, wenn dieses Feld aktiv ist.",
			fixedNoteDeInfo: "Zeigt Informationen auf Deutsch an, wenn dieses Feld aktiv ist.",
			isMultiple: "Mehrfachauswahl erlauben",
			staticSection: "Statische Sektion",
			checkbox: "Kontrollkästchen",
			dropdown: "Dropdown",
			number: "Nummer",
			signature: "Unterschrift",
			textarea: "Textbereich",
			title: "Titel",
			tariff: "Tarif",
			birthdate: "Geburtsdatum",
			bic: "BIC",
			bankname: "Bankname",
			DeleteTable: "Tabelle löschen",
			EditStructure: "Struktur bearbeiten",
			CreateStructure: "Struktur erstellen",
			EditStufe: "Stufe bearbeiten",
			CreateStufe: "Stufe erstellen",
			PayoutModelTwoProvisionPercentage: "Auszahlungsmodell zwei Provisionsprozentsatz",
			PayoutModelTwoName: "Name des Auszahlungsmodells zwei",
			PayoutModelTwoStatuses: "Status des Auszahlungsmodells zwei",
			PayoutModelOneName: "Name des Auszahlungsmodells eins",
			PayoutModelOneProvisionPercentage:
				"Prozentsatz der Provision für Auszahlungsmodell eins",
			ProvisionName: "Provisionsname",
			PayoutModelOneStatuses: "Status des Auszahlungsmodells eins",
			Entrance: "Eingang",
			CommissionAmount: "Provisionshöhe",
			PayoutModel: "Auszahlungsmodell",
			SetDateForInterns: "Legen Sie ein Startdatum für alle Praktikanten fest",
			GridView: "Rasteransicht",
			ListView: "Listenansicht",
			PriorityView: "Prioritätsansicht",
			StatusView: "Statusansicht",
			SelectGoalsToViewActions:
				"Wählen Sie ein oder mehrere Ziele aus, um Aktionen anzuzeigen*",
			CheckEmailFormat: "E-Mail-Format prüfen",
			GroupView: "Gruppenansicht",
			GroupName: "Gruppenname",
			CreateGroupView: "Gruppenansicht erstellen",
			EditGroupView: "Gruppenansicht bearbeiten",
			Groups: "Gruppen",
			DateBased: "Datum Erf.",
			removeFromMobile: "Vom Mobilgerät entfernen",
			AreYouSureTo: "Sind Sie sicher, dass",
			removeCard: "Karte entfernen",
			addCard: "Karte hinzufügen",
			fromMobile: "von Mobile",
			toMobile: "zu Mobile",
			fromDashboard: "von Dashboard",
			toDashboard: "zu Dashboard",
			Elements: "Elemente",
			LOW: "NIEDRIG",
			NONE: "KEINE",
			MEDIUM: "MITTEL",
			HIGH: "HOCH",
			CANCELLED_INCORRECT_ORDER: "Auftrag Fehlerhaft",
			CANCELLED_PROVIDER: "Auftrag vom Anbieter storniert",
			CANCELLED_CUSTOMER: "Widerruf Kunde",
			CONTRACT_REACTIVATED: "Vertrag reaktiviert",
			dateTypeInfo:
				"Wählen Sie die Zeiteinheit für den Datumsvergleich, die in der Validierung verwendet wird, um sicherzustellen, dass die ausgewählten Daten die angegebenen Kriterien erfüllen.",
			minDate: "Mindestdatum",
			minDateInfo:
				"Geben Sie das Mindestdatum ein, das erfüllt werden muss. Der zulässige Datumsbereich wird basierend auf dem ausgewählten Datumstyp angepasst.",
			maxDate: "Höchstdatum",
			maxDateInfo:
				"Geben Sie das Höchstdatum ein, das ausgewählt werden kann. Dies setzt eine obere Grenze für die Datenauswahl, die durch den gewählten Datumstyp bestimmt wird.",
			daily: "Täglich",
			weekly: "Wöchentlich",
			monthly: "Monatlich",
			yearly: "Jährlich",
			maxAgeError:
				"Das Höchstdatum kann nicht 0 sein, wenn das Mindestdatum 0 ist. Bitte geben Sie ein gültiges Höchstdatum an.",
			dndTargetError:
				"Das Feld wurde außerhalb des zulässigen Bereichs abgelegt. Bitte versuchen Sie es erneut und legen Sie das Feld im vorgesehenen Bereich ab.",
			assignTariff: "Tarif zuweisen",
			assignTariffInfo: "Wählen Sie einen Tarif aus der Liste, um ihn dem Feld zuzuweisen.",
			TargetOrders: "Zielaufträge",
			displayCard: "Karte anzeigen",
			SelectedAgencies: "Ausgewählte Agenturen",
			SelectedTeams: "Ausgewählte Teams",
			SelectedClients: "Ausgewählte Kunden",
			SelectAll: "Alles auswählen",
			DeselectAll: "Alles abwählen",
			checked: "Markiert",
			unchecked: "Nicht markiert",
			pdfSettings: "PDF-Einstellungen",
			linkTariffToPdf: "Tarif mit PDF verknüpfen",
			pdfDefaultSwitchInfo: "Dieses Feld bestimmt, ob dieses PDF",
			sendEmailInfo: "per E-Mail gesendet wird, wenn eine Bestellung erstellt wird",
			sendOfferEmailInfo: "per E-Mail mit dem Angebot gesendet wird",
			saveOnServerInfo: "auf dem Server gespeichert wird",
			saveOnClientServerInfo: "auf dem Client-Server gespeichert wird",
			insertHorizontalLine: "Horizontale Linie einfügen",
			insertVerticalLine: "Vertikale Linie einfügen",
			pdfName: "PDF Name",
			editWidth: "Breite anpassen",
			editX: "X-Position anpassen",
			editY: "Y-Position anpassen",
			editAlign: "Ausrichtung anpassen",
			cloneField: "Feld duplizieren",
			editField: "Feld bearbeiten",
			editFields: "Felder bearbeiten",
			removeField: "Feld entfernen",
			editPdf: "PDF bearbeiten",
			ShowOn: "Anzeigen auf: ",
			Mobile: "Mobile",
			GoalExpired: "Zielfrist ist abgelaufen ",
			InActive: "Inaktive",
			dateStatus: "Datumsstatus",
			SelectGroupView: "Gruppenansicht auswählen",
			GoalCreatedSuccessfully: "Ziel erfolgreich erstellt",
			GoalEditedSuccessfully: "Ziel erfolgreich bearbeitet",
			RemoveAllUsers: "Alle Benutzer entfernen",
			AreYouSureRemoveAll: "Möchten Sie wirklich alle Benutzer entfernen?",
			SelectAllStatuses: "Alle Status auswählen*",
			fieldOutsidePdfError:
				"Platzierungsfehler: Bitte halten Sie das PDF-Feld innerhalb der Grenzen des PDFs.",
			fontSize: "Schriftgröße (in Pixeln)",
			width: "Elementbreite (in Pixeln)",
			height: "Elementhöhe (in Pixeln)",
			horizontal: "Horizontale Position (X-Achse)",
			vertical: "Vertikale Position (Y-Achse)",
			alignment: "Ausrichtung des Feldinhalts",
			areYouSureDeleteFile: "Sind Sie sicher, dass Sie diese Datei löschen möchten?",
			UploadQRCode: "QR-Code hochladen",
			UploadQRCodeInfo: "Der QR-Code muss als SVG oder PNG hochgeladen werden.",
			SystemMessages: "Systemmeldungen",
			daysAgo: "vor Tagen",
			aDayAgo: "vor einem Tag",
			AppointmentDescription: "Sie haben morgen einen Termin im",
			AppointmentReminder: "Terminerinnerung",
			CANCELLED_DOUBLE: "Abgesagt doppelt",
			CancelledDouble: "Abgesagt doppelt",
			CLARIFICATION: "KLÄRUNG",
			Clarification: "Klärung",
			StatusOne: "Status Einz",
			StatusTwo: "Status Zwei",
			ChooseImportStatus: "Importstatus wählen",
			CANCELLED_ALL: "ALLE_STORNO",
			CancelledAll: "Alle Storno",
			Energy: "Energie",
			statusView: "Ansicht nach Status",
			streetView: "Ansicht nach Straße",
			firstLastName: "Vor- und Nachname",
			assignedUsers: "Zugewiesene Benutzer",
			editLead: "Lead bearbeiten",
			duplicateLead: "Lead duplizieren",
			deleteLead: "Lead löschen",
			NewNotification: "Sie haben eine neue Benachrichtigung!",
			leadsFrom: "Leads von",
			TariffModel: "Tarifmodell",
			DistributionModel: "Verteilungsmodell",
			EnergyDistributionModel: "Energieverteilungsmodell",
			provisionType: "Bereitstellungstyp",
			Percentage: "Prozentual",
			Fixed: "Behoben",
			CareerConditions: "Karrierebedingungen",
			fromStufe: "Von Stufe",
			toStufe: "An Stufe",
			targetDays: "Zieltage",
			cycle: "Zyklus",
			averagePerDay: "Durchschnitt pro Tag",
			conditionFor: "Bedingung für",
			StufeLevel: "Stuffe-Level",
			CreateConditionTable: "Bedingungstabelle erstellen",
			EditConditionTable: "Bedingungstabelle bearbeiten",
			OverheadFor: "Overhead für",
			Own: "Eigen",
			OWN: "Eigen",
			TEAM: "Team",
			USER_ROLES: "Nutzer-Rolle",
			DISTRIBUTION_CHANNEL: "Vertriebsweg",
			mapView: "Nach Karte anzeigen",
			replacePdf: "PDF ersetzen",
			StructureCreateFail: "Struktur konnte nicht erstellt werden",
			StructureEditFail: "Struktur konnte nicht bearbeitet werden",
			currentStatus: "Aktueller Status",
			noBiggerthan: "Darf nicht größer sein als:",
			BillingNextMonth: "Abrechnung Folgemonat",
			BILLING_NEXT_MONTH: "Abrechnung Folgemonat",
			CANCELLED_CREDIT_WORTH: "Storno Bonität",
			CancelledCreditWorth: "Storno Bonität",
			DifferenceProvision: "Differenzprovision",
			activities: "Aktivitäten",
			lastWeek: "Letzte Woche:",
			lastMonth: "Letzter Monat:",
			last3Months: "Letzte 3 Monate:",
			lastYear: "Letztes Jahr:",
			older: "Älter:",
			FAILED_AVERAGE: "Durchschnittlich Fehlgeschlagen",
			FAILED_STUFE: "Phase Fehlgeschlagen",
			COMPLETED: "Abgeschlossen",
			FAILED: "Fehlgeschlagen",
			CyclesCompleted: "Abgeschlossene Zyklen",
			StartingOn: "Beginn am",
			RequirementAchievedOn: "Anforderung erreicht am",
			CareerStages: "Karrierestufen",
			DISTRIBUTION_MODEL: "Verteilungsmodell",
			TARIFF_MODEL: "Tarifmodell",
			ENERGY_DISTRIBUTION_MODEL: "Energieverteilungsmodell",
			businessLicenseUrl: "Business License",
			idNumber: "Personalausweis",
			vatEntitlement: "MwSt-Berechtigung",
			taxNumberUrl: "Steuernummer",
			vatNumberUrl: "MwSt Nummer",
			GenerateSettlementID: "Abrechnungs-ID generieren",
			True: "Wahr",
			False: "Falsch",
			weeksAgo: "Wochen her.",
			monthsAgo: "Monate her.",
			dateRangeError: "Das Datum muss im angegebenen Bereich liegen.",
			dateErrorAtLeast: "Bitte wählen Sie ein Datum, das mindestens",
			dateErrorAtMost: "Bitte wählen Sie ein Datum, das vorher liegt.",
			EnergyType: "Energietyp",
			ClientType: "Kundentyp",
			deleteStructureInfo:
				"Das Löschen dieser Struktur entfernt dauerhaft alle zugehörigen Karrierekonditionen. Möchten Sie den Vorgang wirklich fortsetzen?",
			excludeFromSettlement: "Nicht abrechnen",
			EXCLUDE_SETTLEMENT: "Nicht abrechnen",
			changedBy: "Geändert von",
			falseEmail: "Diese E-Mail existiert nicht!",
			CheckEmail: "Überprüfen Sie das E-Mail-Format",
			vpNumberOnlyNumbers: "Die VP-Nummer darf nur Zahlen enthalten",
			dataNotFound: "Die angeforderten Daten konnten nicht gefunden werden.",
			settlementNumberFormat:
				"Leerzeichen sind nur erlaubt, wenn sie direkt vor oder nach einem Komma stehen.",
			Imported: "Importiert",
			tariffsInProcess:
				"Wir bearbeiten Ihre Anfrage und generieren die Tarife im Hintergrund. Bitte haben Sie einen Moment Geduld.",
			chooseExternalClient: "Wählen Sie einen Kunden aus dem externen Service",
			doYouWantToCreateExternalTariffs:
				"Möchten Sie Tarife aus dem externen Service erstellen?",
			createExternalTariffs: "Tarife aus dem externen Service erstellen",
			StufeOrStructureNotFound: "Der Benutzer hat keine Stufe oder Struktur",
			EditAgencySP: "Agentur-SP bearbeiten",
			MakeGroupLeader: "Gruppenleiter ernennen",
			GroupLeader: "Gruppenleiter",
			AgencyEditedSuccess: "Agentur erfolgreich bearbeitet",
			AgencyEditedError: "Agenturbearbeitung fehlgeschlagen",
		},
	},
};

const _language = localStorage.getItem("language");
export const langVar = localStorage.getItem("language");

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: "de",
		lng: _language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
