import produce from "immer";
import {put, takeLatest, select} from "redux-saga/effects";
import {actions as createTeamActions} from "@sagas/teams/create";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import {ToastErrorComponent, ToastSuccesComponent} from "@src/common/ToastComponent/ToastComponent";
import i18n from "@src/i18n";
const logger = new Logger("teams client");
const PREFIX = "@Dixi App/store/sagas/app/teams/";
// clients
export const FETCH_TEAMS = `${PREFIX}FETCH_TEAMS`;
export const FETCH_TEAMS_SUCCESS = `${PREFIX}FETCH_TEAMS_SUCCESS`;
export const FETCH_AGENCIES = `${PREFIX}FETCH_AGENCIES`;
export const FETCH_AGENCIES_SUCCESS = `${PREFIX}FETCH_AGENCIES_SUCCESS`;
export const LOADING = `${PREFIX}LOADING`;
export const ADD_TEAM_ON_STATE = `${PREFIX}ADD_TEAM_ON_STATE`;
export const EDIT_TEAM_ON_STATE = `${PREFIX}EDIT_TEAM_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS = `${PREFIX}FILTERS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const CHANGE_IS_TEAM = `${PREFIX}CHANGE_IS_TEAM`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const TOTAL_SIZE_INCREASE = `${PREFIX}TOTAL_SIZE_INCREASE`;
export const TOTAL_SIZE_DECREASE = `${PREFIX}TOTAL_SIZE_DECREASE`;
export const DELETE_TEAM_ON_STATE = `${PREFIX}DELETE_TEAM_ON_STATE`;
export const DELETE_AGENCY_ON_STATE = `${PREFIX}DELETE_AGENCY_ON_STATE`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const SET_ROWS = `${PREFIX}SET_ROWS`;
export const GET_TEAM_BY_ID = `${PREFIX}GET_TEAM_BY_ID`;
export const SET_MODAL_LOADING = `${PREFIX}SET_MODAL_LOADING`;
export const EDIT_AGENCY = `${PREFIX}EDIT_AGENCY`;
export const EDIT_AGENCY_SUCCESS = `${PREFIX}EDIT_AGENCY_SUCCESS`;

const _state = {
	teams: [],
	agencies: [],
	isLoading: false,
	page: 1,
	size: 30,
	totalSize: 5,
	search: "",
	isTeam: "",
	totalPages: 1,
	rows: [],
	teamsModalLoading: false,
};

const reducer = (state = _state, {type, payload}) =>
	produce(state, (draft) => {
		switch (type) {
			case FETCH_TEAMS_SUCCESS:
				draft.teams = state?.page > 1 ? [...state?.teams, ...payload] : payload;
				const correctTeams = payload?.map((team) => ({
					...team,
					expanded: false,
					type: "MASTER",
				}));
				draft.rows = state?.page > 1 ? [...state?.rows, ...correctTeams] : correctTeams;
				break;
			case FETCH_AGENCIES_SUCCESS:
				draft.agencies = state?.page > 1 ? [...state?.agencies, ...payload] : payload;
				const correctAgencies = payload?.map((team) => ({
					...team,
					expanded: false,
					type: "MASTER",
				}));
				draft.rows =
					state?.page > 1 ? [...state?.rows, ...correctAgencies] : correctAgencies;
				break;
			case ADD_TEAM_ON_STATE:
				const updatedMembers = payload?.teamMembers?.map((item) => {
					if (item?.id === payload?.teamLeader) {
						return {
							...item,
							leader: true,
						};
					}
					return item;
				});
				const updatedPayload = {...payload, members: updatedMembers};
				draft.teams = [updatedPayload].concat(state.teams);
				draft.rows = [{...updatedPayload, expanded: false, type: "MASTER"}, ...state?.rows];
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = payload;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = payload;
				break;
			case EDIT_PAGE:
				draft.page = payload;
				break;
			case CHANGE_IS_TEAM:
				draft.isTeam = payload?.isTeam;
				break;
			case FILTERS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.search = payload.value;
				break;
			case SIZE_EDIT:
				draft.size = payload;
				break;
			case TOTAL_SIZE_INCREASE:
				draft.totalSize = state.totalSize + 1;
				break;
			case TOTAL_SIZE_DECREASE:
				draft.totalSize = state.totalSize - 1;
				break;
			case EDIT_TEAM_ON_STATE:
				const teamIndex = state.teams.findIndex((team) => team.id === payload.id);
				const rowIndex = state.rows.findIndex(
					(row) => row.id === payload.id && row.type === "MASTER",
				);

				if (teamIndex !== -1) {
					draft.teams[teamIndex] = payload;
				}

				if (rowIndex !== -1) {
					const isExpanded = state.rows[rowIndex].expanded;

					draft.rows[rowIndex] = {
						...payload,
						type: "MASTER",
						expanded: isExpanded,
						members: payload?.teamMembers,
					};
					if (isExpanded) {
						const detailRowIndex = state.rows.findIndex(
							(row) => row.parentId === payload.id && row.type === "DETAIL",
						);

						if (detailRowIndex !== -1) {
							draft.rows[detailRowIndex] = {
								type: "DETAIL",
								members: payload?.teamMembers,
								parentId: payload?.id,
								id: payload?.teamLeaderId,
								teamLeaderId: payload?.teamLeaderId,
							};
						} else {
							draft.rows.splice(rowIndex + 1, 0, {
								type: "DETAIL",
								members: payload?.teamMembers,
								parentId: payload?.id,
								id: payload?.teamLeaderId,
								teamLeaderId: payload?.teamLeaderId,
							});
						}
					} else {
						draft.rows = draft.rows.filter(
							(row) => row.parentId !== payload.id || row.type !== "DETAIL",
						);
					}
				}
				break;

			case DELETE_TEAM_ON_STATE:
				const filteredTeams = state.teams.filter((team) => team.id !== payload);
				if (state?.page > 1 && filteredTeams.length === 0) {
					draft.page = state?.page - 1;
				} else {
					draft.teams = filteredTeams;
					draft.rows = filteredTeams.map((item) => ({
						...item,
						expanded: false,
						type: "MASTER",
					}));
				}
				break;
			case DELETE_AGENCY_ON_STATE:
				const filteredAgencies = state.rows.filter(
					(agency) => agency.teamLeaderId !== payload,
				);
				if (state.page > 1 && filteredAgencies.length === 0) {
					draft.page = state?.page - 1;
				} else {
					draft.rows = filteredAgencies;
					draft.size = draft?.rows?.[0] ? draft.rows.length : 30;
					draft.totalSize = draft.rows?.[0] ? state.totalSize : 0;
				}
				break;
			case LOADING:
				draft.isLoading = payload;
				break;
			case SET_ROWS:
				draft.rows = payload;
				break;
			case SET_MODAL_LOADING:
				draft.teamsModalLoading = payload;
				break;
			case EDIT_AGENCY_SUCCESS:
				const updatedRows = state.rows.map((i) => ({
					...i,
					members: i.members.map((it) => {
						if (payload.agencyGroupLeaderId === it.id) {
							return {
								...it,
								isGroupLeader: true,
							};
						}
						if (payload.agencySpUsers.includes(it.id)) {
							return {...it, groupLeaderId: payload.agencyGroupLeaderId};
						}
						return {...it};
					}),
				}));
				draft.rows = updatedRows;
				break;
			default:
				return state;
		}
	});

export default reducer;

export const actions = {
	fetchTeams: (payload) => createAction(FETCH_TEAMS, {payload}),
	fetchTeamsSuccess: (payload) => createAction(FETCH_TEAMS_SUCCESS, {payload}),
	fetchAgencies: (payload) => createAction(FETCH_AGENCIES, {payload}),
	fetchAgenciesSuccess: (payload) => createAction(FETCH_AGENCIES_SUCCESS, {payload}),
	setLoading: (payload) => createAction(LOADING, {payload}),
	addTeamOnState: (payload) => createAction(ADD_TEAM_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterTeams: (payload) => createAction(FILTERS, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	changeIsTeam: (payload) => createAction(CHANGE_IS_TEAM, {payload}),
	totalSizeIncrease: (payload) => createAction(TOTAL_SIZE_INCREASE, {payload}),
	totalSizeDecrease: (payload) => createAction(TOTAL_SIZE_DECREASE, {payload}),
	editTeamOnState: (payload) => createAction(EDIT_TEAM_ON_STATE, {payload}),
	deleteTeamOnState: (payload) => createAction(DELETE_TEAM_ON_STATE, {payload}),
	deleteAgencyOnState: (payload) => createAction(DELETE_AGENCY_ON_STATE, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	setRows: (payload) => createAction(SET_ROWS, {payload}),
	getTeamById: (payload) => createAction(GET_TEAM_BY_ID, {payload}),
	setModalLoading: (payload) => createAction(SET_MODAL_LOADING, {payload}),
	editAgency: (payload) => createAction(EDIT_AGENCY, {payload}),
	editAgencySuccess: (payload) => createAction(EDIT_AGENCY_SUCCESS, {payload}),
};

export const sagas = {
	*fetchTeams({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size} = yield select((state) => state.app.teams.index);
			const {page} = yield select((state) => state.app.teams.index);
			const response = yield axios.get(
				`team/by/role?page=${page}&size=${payload?.all ? 1000 : size}&agency=${
					payload?.isTeam
				}`,
			);
			yield put(actions.fetchTeamsSuccess(response?.data?.data.data));
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*fetchAgencies({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {size} = yield select((state) => state.app.teams.index);
			const {page} = yield select((state) => state.app.teams.index);
			const {totalSize} = yield select((state) => state.app.teams.index);
			const response = yield axios.get(
				`team/by/role?page=${page}&size=${payload?.all ? 1000 : size}&agency=${
					payload?.isTeam
				}`,
			);
			yield put(actions.fetchAgenciesSuccess(response?.data?.data.data));
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getTeamById({payload}) {
		yield put(actions.setModalLoading(true));
		try {
			const response = yield axios.get(`/team/${payload}`);
			yield put(createTeamActions.fillTeam(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setModalLoading(false));
		}
	},
	*editAgency({payload}) {
		yield put(actions.setModalLoading(true));
		const {values, setModal, agencyId} = payload;
		try {
			yield axios.post(`/user/agency-group-leader`, values);
			yield put(actions.editAgencySuccess({...values, agencyId}));
			setModal(false);
			ToastSuccesComponent(i18n.t("AgencyEditedSuccess"));
		} catch (error) {
			ToastErrorComponent(i18n.t("AgencyEditedError"));
			logger.error(error);
		} finally {
			yield put(actions.setModalLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(FETCH_TEAMS, sagas.fetchTeams);
	yield takeLatest(FETCH_AGENCIES, sagas.fetchAgencies);
	yield takeLatest(GET_TEAM_BY_ID, sagas.getTeamById);
	yield takeLatest(EDIT_AGENCY, sagas.editAgency);
};
