/* eslint-disable import/no-cycle */
import produce from "immer";
import {put, select, takeLatest} from "redux-saga/effects";
import createAction from "@utils/action-creator";
import Logger from "@utils/logger";
import axios from "@utils/axios";
import _ from "lodash";
import moment from "moment";
import {actions as marketPlaceActions} from "@sagas/marketplace/index";
import {ENDPOINT} from "@src/utils/endpoint";
import {encryptData} from "@src/utils/encode-role-data";
import i18n from "@src/i18n";
import {actions as createUserActions} from "./create";
import {actions as tenantSettingsActions} from "../../userProfile/settings";
import {actions as navigation} from "../../navigation/index";
import {actions as logoutActions} from "../../auth/logout";
import {wsConnect} from "../../../../../ws/index";
import {saveTenantFeatures} from "../../tenants/utils";
import {saveTenantFeatureEndpointData} from "./utils";

const logger = new Logger("Users index");

const PREFIX = "@app/users/user/index";
export const GET_USERS = `${PREFIX}GET_USERS`;
export const GET_USERS_BY_ID = `${PREFIX}GET_USERS_BY_ID`;
export const GET_MONTHLY_DATA_USER = `${PREFIX}GET_MONTHLY_DATA_USER`;
export const GET_MONTHLY_DATA_USER_SUCCESS = `${PREFIX}GET_MONTHLY_DATA_USER_SUCCESS`;
export const GET_STATISTIK_DATA_USER = `${PREFIX}GET_STATISTIK_DATA_USER`;
export const GET_STATISTIK_DATA_USER_SUCCESS = `${PREFIX}GET_STATISTIK_DATA_USER_SUCCESS`;
export const GET_STATISTIK_STATUS_COUNT = `${PREFIX}GET_STATISTIK_STATUS_COUNT`;
export const GET_STATISTIK_STATUS_COUNT_SUCCESS = `${PREFIX}GET_STATISTIK_STATUS_COUNT_SUCCESS`;
export const SET_LOADING = `${PREFIX}SET_LOADING`;
export const FETCH_USERS_SUCCESS = `${PREFIX}FETCH_USERS_SUCCESS`;
export const FETCH_USERS_BY_ID_SUCCESS = `${PREFIX}FETCH_USERS_BY_ID_SUCCESS`;
export const FETCH_SINGLE_USER = `${PREFIX}FETCH_SINGLE_USER`;
export const FETCH_SINGLE_USER_SUCCESS = `${PREFIX}FETCH_SINGLE_USER_SUCCESS`;
export const ADD_USER_ON_STATE = `${PREFIX}ADD_USER_ON_STATE`;
export const EDIT_USER_ON_STATE = `${PREFIX}EDIT_USER_ON_STATE`;
export const DELETE_USER_ON_STATE = `${PREFIX}DELETE_USER_ON_STATE`;
export const SIZE_EDIT = `${PREFIX}SIZE_EDIT`;
export const FILTERS_WITH_STATUS = `${PREFIX}FILTERS_WITH_STATUS`;
export const EDIT_PAGE = `${PREFIX}EDIT_PAGE`;
export const EDIT_TOTAL_SIZE = `${PREFIX}EDIT_TOTAL_SIZE`;
export const FETCH_LOGGED_USER = `${PREFIX}FETCH_LOGGED_USER`;
export const FETCH_LOGGED_USER_SUCCESS = `${PREFIX}FETCH_LOGGED_USER_SUCCESS`;
export const CLEAR_LOGGED_USER_SUCCESS = `${PREFIX}CLEAR_LOGGED_USER_SUCCESS`;
export const EDIT_USER_SETTINGS_SUCCESS = `${PREFIX}EDIT_USER_SETTINGS_SUCCESS`;
export const GET_USERS_DROPDOWN = `${PREFIX}GET_USERS_DROPDOWN`;
export const GET_USERS_DROPDOWN_SUCCESS = `${PREFIX}GET_USERS_DROPDOWN_SUCCESS`;
export const GET_USERS_BY_TENANT = `${PREFIX}GET_USERS_BY_TENANT`;
export const GET_USERS_BY_TENANT_SUCCESS = `${PREFIX}GET_USERS_BY_TENANT_SUCCESS`;
export const GET_USERS_SP = `${PREFIX}GET_USERS_SP`;
export const GET_USERS_SP_SUCCESS = `${PREFIX}GET_USERS_SP_SUCCESS`;
export const GET_EMPLOYEE_SP = `${PREFIX}GET_EMPLOYEE_SP`;
export const GET_EMPLOYEE_SP_SUCCESS = `${PREFIX}GET_EMPLOYEE_SP_SUCCESS`;
export const GET_USERS_NO_TEAM = `${PREFIX}GET_USERS_NO_TEAM`;
export const GET_USERS_NO_TEAM_SUCCESS = `${PREFIX}GET_USERS_NO_TEAM_SUCCESS`;
export const CLEAR_USER_INITIAL_VALUES = `${PREFIX}CLEAR_USER_INITIAL_VALUES`;
export const SET_TOTAL_PAGES = `${PREFIX}SET_TOTAL_PAGES`;
export const SET_TENANT_FEATURES_SUCCESS = `${PREFIX}SET_TENANT_FEATURES_SUCCESS`;
export const SET_DETAILS_QUERY = `${PREFIX}SET_DETAILS_QUERY`;
export const FETCH_LOADER = `${PREFIX}FETCH_LOADER`;
export const GET_AVAILABLE_SUPERVISORS = `${PREFIX}GET_AVAILABLE_SUPERVISORS`;
export const GET_AVAILABLE_SUPERVISORS_SUCCESS = `${PREFIX}GET_AVAILABLE_SUPERVISORS_SUCCESS`;
export const GET_USERS_BY_LEADER_ID = `${PREFIX}GET_USERS_BY_LEADER_ID`;
export const GET_USERS_BY_LEADER_ID_SUCCESS = `${PREFIX}GET_USERS_BY_LEADER_ID_SUCCESS`;
export const GET_USER_ACTIVITIES = `${PREFIX}GET_USER_ACTIVITIES`;
export const GET_USER_ACTIVITIES_SUCCESS = `${PREFIX}GET_USER_ACTIVITIES_SUCCESS`;
export const SET_USER_ORDERS_IN_PROGRESS = `${PREFIX}SET_USER_ORDERS_IN_PROGRESS`;

const fromDate = new Date();
fromDate.setDate(fromDate.getDate() - 30);

const _state = {
	users: [],
	usersByTenant: [],
	usersOptions: [],
	usersById: [],
	usersSp: [],
	monthlyDataUser: [],
	statistikDataUser: [],
	statistikStatusCount: {},
	employeeSp: [],
	singleUser: {},
	loggedUser: {},
	tenantFeatures: [],
	userInitialValues: {
		firstName: "",
		lastName: "",
		phoneNumber: "",
		country: "",
		city: "",
	},
	loading: false,
	page: 1,
	size: 30,
	totalSize: 5,
	search: "",
	status: "",
	loggedUserId: 0,
	noTeamUsers: [],
	totalPages: 1,
	query: {
		clientId: null,
		fromDate: moment().startOf("month").valueOf(),
		toDate: moment().endOf("month").valueOf(),
	},
	detailsQuery: "2024",
	fetchLoader: false,
	availableSupervisors: [],
	endpointPermissions: {},
	userActivities: [],
};

const reducer = (state = _state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case EDIT_PAGE:
				draft.page = action.payload;
				break;
			case SET_TOTAL_PAGES:
				draft.totalPages = action.payload;
				break;
			case FILTERS_WITH_STATUS:
				if (state?.page !== 1) {
					draft.page = 1;
				}
				draft.status = action.payload.value;
				break;
			case EDIT_TOTAL_SIZE:
				draft.totalSize = action.payload;
				break;
			case FETCH_USERS_SUCCESS:
				const users =
					state?.page > 1 ? [...state.users, ...action.payload] : action.payload;
				draft.users = users;
				break;
			case FETCH_USERS_BY_ID_SUCCESS:
				draft.usersById = action.payload;
				break;
			case FETCH_SINGLE_USER_SUCCESS:
				draft.singleUser = action.payload;
				break;

			case SIZE_EDIT:
				draft.size = action.payload;

				break;
			case ADD_USER_ON_STATE:
				draft.users = [action.payload].concat(state.users);
				break;
			case EDIT_USER_ON_STATE:
				const index = state.users.findIndex((c) => c.id === action.payload.id);
				draft.users[index] = action.payload;

				break;
			case DELETE_USER_ON_STATE:
				draft.users = state.users.filter((item) => item?.id !== action?.payload?.user?.id);
				draft.page = 1;
				draft.size = draft?.users?.[0] ? draft.users.length : 30;
				draft.totalSize = draft.users?.[0] ? state.totalSize : 0;
				break;
			case FETCH_LOGGED_USER_SUCCESS:
				draft.loggedUser = action.payload;
				draft.endpointPermissions = saveTenantFeatureEndpointData(
					action?.payload?.tenantFeatureEndpointData,
				);
				if (!state.userInitialValues.firstName) {
					draft.userInitialValues = {
						...state.userInitialValues,
						...action.payload,
					};
				}
				draft.loggedUserId = action.payload.id;

				break;
			case CLEAR_LOGGED_USER_SUCCESS:
				draft.loggedUser = {};
				draft.loggedUserId = 0;
				break;
			case CLEAR_USER_INITIAL_VALUES:
				draft.userInitialValues = {
					firstName: "",
					lastName: "",
					phoneNumber: "",
					country: "",
					city: "",
				};
				break;
			case EDIT_USER_SETTINGS_SUCCESS:
				draft.userInitialValues = action.payload;
				break;
			case SET_LOADING:
				draft.loading = action.payload;
				break;
			case GET_USERS_DROPDOWN_SUCCESS:
				draft.usersOptions = action.payload.sort((a, b) => {
					if (a.company && !b.company) return -1;
					if (!a.company && b.company) return 1;
					return 0;
				});
				break;
			case GET_USERS_BY_TENANT_SUCCESS:
				draft.usersByTenant = action.payload;
				break;
			case GET_USERS_SP_SUCCESS:
				draft.usersSp = action.payload;
				break;
			case GET_EMPLOYEE_SP_SUCCESS:
				draft.employeeSp = action.payload;
				break;
			case GET_USERS_NO_TEAM_SUCCESS:
				draft.noTeamUsers = _.uniqBy(action.payload, "id");
				break;
			case SET_TENANT_FEATURES_SUCCESS:
				draft.tenantFeatures = action.payload;
				break;
			case GET_MONTHLY_DATA_USER_SUCCESS:
				draft.monthlyDataUser = action.payload;
				break;
			case GET_STATISTIK_DATA_USER_SUCCESS:
				if (state.page > 1) {
					draft.statistikDataUser = [...state.statistikDataUser, ...action.payload.data];
				} else {
					draft.statistikDataUser = action.payload.data;
				}
				draft.totalSize = action?.payload?.totalSize;
				break;
			case GET_STATISTIK_STATUS_COUNT_SUCCESS:
				draft.statistikStatusCount = action.payload;
				break;
			case SET_DETAILS_QUERY:
				draft.detailsQuery = action.payload || "2024";
				break;
			case FETCH_LOADER:
				draft.fetchLoader = action.payload;
				break;
			case GET_AVAILABLE_SUPERVISORS_SUCCESS:
				draft.availableSupervisors = action.payload;
				break;
			case GET_USERS_BY_LEADER_ID_SUCCESS:
				draft.usersOptions = action.payload?.map((i) => ({
					...i,
					label: i?.name,
					value: i?.value,
					name: `${i?.firstName} ${i?.lastName}`,
				}));
				break;
			case GET_USER_ACTIVITIES_SUCCESS:
				draft.userActivities = action.payload;
				break;
			case SET_USER_ORDERS_IN_PROGRESS:
				const payloadIds = action.payload.map((it) => it.id);
				const newOrdersArray = state.statistikDataUser.map((order) => {
					if (payloadIds?.includes(order?.id)) {
						return {
							...order,
							systemStatusView: {
								...order.systemStatusView,
								color: "#FF8A00",
								statusName: i18n.t("InProgress"),
								orderStatus: i18n.t("InProgress"),
							},
						};
					}
					return order;
				});
				draft.statistikDataUser = newOrdersArray;
				break;
			default:
				return state;
		}
	});
export default reducer;

export const actions = {
	getUsers: (payload) => createAction(GET_USERS, {payload}),
	getUsersById: (payload) => createAction(GET_USERS_BY_ID, {payload}),
	getMonthlyDataUser: (payload) => createAction(GET_MONTHLY_DATA_USER, {payload}),
	getMonthlyDataUserSuccess: (payload) => createAction(GET_MONTHLY_DATA_USER_SUCCESS, {payload}),
	getStatistikDataUser: (payload) => createAction(GET_STATISTIK_DATA_USER, {payload}),
	getStatistikDataUserSuccess: (payload) =>
		createAction(GET_STATISTIK_DATA_USER_SUCCESS, {payload}),
	getStatistikStatusCount: (payload) => createAction(GET_STATISTIK_STATUS_COUNT, {payload}),
	getStatistikStatusCountSuccess: (payload) =>
		createAction(GET_STATISTIK_STATUS_COUNT_SUCCESS, {payload}),
	setLoading: (payload) => createAction(SET_LOADING, {payload}),
	fetchUsersSuccess: (payload) => createAction(FETCH_USERS_SUCCESS, {payload}),
	fetchUsersByIdSuccess: (payload) => createAction(FETCH_USERS_BY_ID_SUCCESS, {payload}),
	getSingleUser: (payload) => createAction(FETCH_SINGLE_USER, {payload}),
	getSingleUserSucces: (payload) => createAction(FETCH_SINGLE_USER_SUCCESS, {payload}),
	addUserOnState: (payload) => createAction(ADD_USER_ON_STATE, {payload}),
	editUserOnState: (payload) => createAction(EDIT_USER_ON_STATE, {payload}),
	deleteUserOnState: (payload) => createAction(DELETE_USER_ON_STATE, {payload}),
	editSize: (payload) => createAction(SIZE_EDIT, {payload}),
	filterUsersWithStatus: (payload) => createAction(FILTERS_WITH_STATUS, {payload}),
	editPage: (payload) => createAction(EDIT_PAGE, {payload}),
	editTotalSize: (payload) => createAction(EDIT_TOTAL_SIZE, {payload}),
	getLoggedUser: (payload) => createAction(FETCH_LOGGED_USER, {payload}),
	getLoggedUserSucces: (payload) => createAction(FETCH_LOGGED_USER_SUCCESS, {payload}),
	clearLoggedUser: (payload) => createAction(CLEAR_LOGGED_USER_SUCCESS, {payload}),
	editUserSettingsSuccess: (payload) => createAction(EDIT_USER_SETTINGS_SUCCESS, {payload}),
	getUserDropdown: (payload) => createAction(GET_USERS_DROPDOWN, {payload}),
	getUserDropdownSuccess: (payload) => createAction(GET_USERS_DROPDOWN_SUCCESS, {payload}),
	getUsersByTenant: (payload) => createAction(GET_USERS_BY_TENANT, {payload}),
	getUsersByTenantSuccess: (payload) => createAction(GET_USERS_BY_TENANT_SUCCESS, {payload}),
	getUsersSp: (payload) => createAction(GET_USERS_SP, {payload}),
	getUsersSpSuccess: (payload) => createAction(GET_USERS_SP_SUCCESS, {payload}),
	getEmployeeSp: (payload) => createAction(GET_EMPLOYEE_SP, {payload}),
	getEmployeeSpSuccess: (payload) => createAction(GET_EMPLOYEE_SP_SUCCESS, {payload}),
	getUsersNoTeam: (payload) => createAction(GET_USERS_NO_TEAM, {payload}),
	getUsersNoTeamSuccess: (payload) => createAction(GET_USERS_NO_TEAM_SUCCESS, {payload}),
	clearUserInitialValues: (payload) => createAction(CLEAR_USER_INITIAL_VALUES, {payload}),
	setTotalPages: (payload) => createAction(SET_TOTAL_PAGES, {payload}),
	setTenantFeaturesSuccess: (payload) => createAction(SET_TENANT_FEATURES_SUCCESS, {payload}),
	setDetailsQuery: (payload) => createAction(SET_DETAILS_QUERY, {payload}),
	setFetchLoader: (payload) => createAction(FETCH_LOADER, {payload}),
	getAvailableSupervisors: (payload) => createAction(GET_AVAILABLE_SUPERVISORS, {payload}),
	getAvailableSupervisorsSuccess: (payload) =>
		createAction(GET_AVAILABLE_SUPERVISORS_SUCCESS, {payload}),
	getUsersByLeaderId: (payload) => createAction(GET_USERS_BY_LEADER_ID, {payload}),
	getUsersByLeaderIdSuccess: (payload) => createAction(GET_USERS_BY_LEADER_ID_SUCCESS, {payload}),
	getUserActivities: (payload) => createAction(GET_USER_ACTIVITIES, {payload}),
	getUserActivitiesSuccess: (payload) => createAction(GET_USER_ACTIVITIES_SUCCESS, {payload}),
	setUserOrdersInProgress: (payload) => createAction(SET_USER_ORDERS_IN_PROGRESS, {payload}),
};

export const sagas = {
	*getUsers({payload}) {
		yield put(actions.setFetchLoader(true));
		const {size} = yield select((state) => state.app.users.index);
		const {page} = yield select((state) => state.app.users.index);
		const {totalSize} = yield select((state) => state.app.users.index);
		try {
			const response = yield axios.get(
				`/user/all/?page=${page}&size=${payload?.all ? totalSize : size}&search=${
					payload?.search || ""
				}`,
			);

			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchUsersSuccess(response?.data?.data?.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setFetchLoader(false));
		}
	},
	*getUsersById(id) {
		yield put(actions.setLoading(true));
		const {size} = yield select((state) => state.app.users.index);
		const {search} = yield select((state) => state.app.users.index);
		const {page} = yield select((state) => state.app.users.index);
		try {
			const response = yield axios.get(
				`/user/all/${id?.payload}/?page=${page}&size=${size}&search=${search}`,
			);
			yield put(actions.editTotalSize(response.data.data.totalSize));
			yield put(actions.fetchUsersByIdSuccess(response?.data?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getStatistikDataUser({payload}) {
		yield put(actions.setLoading(true));

		const {size, page, status, totalSize} = yield select((state) => state.app.users.index);
		const {query, id, all} = payload;
		const {fromDate, toDate, clientId, search} = query;
		const fromTimestamp = fromDate
			? moment(fromDate).startOf("day").valueOf()
			: moment().startOf("month").valueOf();
		const toTimestamp = toDate
			? moment(query?.toDate).endOf("day").valueOf()
			: moment().endOf("month").valueOf();
		const params = {fromDate: fromTimestamp, toDate: toTimestamp, clientId};
		try {
			const response = yield axios.post(
				`/order/statistics/users/${id}?page=${page}&size=${
					all ? totalSize : size
				}&search=${search}&status=${status}`,
				params,
			);
			yield put(actions.getStatistikStatusCount({params, id}));
			yield put(actions.getStatistikDataUserSuccess(response.data?.data));
			yield put(actions.setTotalPages(response?.data?.data?.totalPages));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getStatistikStatusCount({payload}) {
		yield put(actions.setLoading(true));

		const {params, id} = payload;
		try {
			const response = yield axios.post(`/order/statistics/users/${id}/count`, params);
			yield put(actions.getStatistikStatusCountSuccess(response.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getMonthlyDataUser(id) {
		yield put(actions.setLoading(true));
		try {
			const {detailsQuery} = yield select((state) => state.app.users.index);
			const fromDate = moment(detailsQuery).startOf("year").valueOf();
			const toDate = moment(detailsQuery).endOf("year").valueOf();
			const response = yield axios.post(`/user/${id?.payload}/statistics`, {
				fromDate,
				toDate,
			});

			yield put(actions.getMonthlyDataUserSuccess(response.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getUserDropdown({payload}) {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/user/drop-down?roles=${payload}`);
			yield put(actions.getUserDropdownSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getUsersByTenant() {
		yield put(actions.setLoading(true));

		try {
			const response = yield axios.get(`/user/fetch/chat`);

			yield put(actions.getUsersByTenantSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getSingleUser(id) {
		yield put(actions.setLoading(true));

		try {
			const response = yield axios.get(`/user/${id.payload}`);
			yield put(actions.getSingleUserSucces(response?.data?.data));
			yield put(createUserActions.fillUsers(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getUsersSp() {
		try {
			const response = yield axios.get(`/user/all_sp/`);
			yield put(actions.getUsersSpSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getEmployeeSp() {
		yield put(actions.setLoading(true));

		try {
			const response = yield axios.get(`/user/all/employee/sp`);
			yield put(actions.getEmployeeSpSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getLoggedUser() {
		yield put(actions.setLoading(true));
		try {
			const response = yield axios.get(`/user/me`);
			yield put(actions.getLoggedUserSucces(response?.data?.data));
			yield put(marketPlaceActions.getMarketPlaceApps());
			const {
				role,
				tenantFeatures,
				tenantTitle,
				tenantLogo,
				hoverColor,
				primaryColor,
				secondaryColor,
				locked,
			} = response?.data?.data;
			if (locked) {
				yield put(navigation.navigate("/locked"));
			} else {
				yield put(actions.setTenantFeaturesSuccess(tenantFeatures));
				const themeObj = {
					primary: primaryColor,
					secondary: secondaryColor,
					hover: hoverColor,
				};
				saveTenantFeatures(tenantFeatures);
				const encryptedRole = encryptData(role);
				localStorage.setItem("roleData", encryptedRole);
				localStorage.setItem("title", tenantTitle);
				localStorage.setItem("theme", JSON.stringify(themeObj));
				localStorage.setItem("tenantLogo", tenantLogo);
				yield put(tenantSettingsActions.getBillingDetails());
				yield put(tenantSettingsActions.getEmailSettings());
				wsConnect();
			}
		} catch (error) {
			logger.error(error);
			yield put(logoutActions.logout());
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*editUserSettingsSuccess(obj) {
		yield put(actions.setLoading(true));
		try {
			yield put(actions.editUserSettingsSuccess(obj));
		} catch (error) {
			logger.error(error);
		} finally {
			yield put(actions.setLoading(false));
		}
	},
	*getUsersNoTeam() {
		try {
			const response = yield axios.get(`/user/sp-no-team`);
			yield put(actions.getUsersNoTeamSuccess(response?.data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getAvailableSupervisors({payload}) {
		try {
			const {stufe, structure} = payload;
			const {data} = yield axios.get(
				ENDPOINT.GET_AVAILABLE_SUPERVISORS.replace(":structure", structure).replace(
					":stufe",
					stufe,
				),
			);
			yield put(actions.getAvailableSupervisorsSuccess(data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getUsersByLeaderId({payload}) {
		try {
			const {data} = yield axios.get(`/team/leader-or-head/${payload}`);
			yield put(actions.getUsersByLeaderIdSuccess(data?.data));
		} catch (error) {
			logger.error(error);
		}
	},
	*getUserActivities({payload}) {
		yield put(actions.setLoading(true));
		try {
			const {data} = yield axios.get(`/career-histories/${payload}`);
			yield put(actions.getUserActivitiesSuccess(data?.data));
			yield put(actions.setLoading(false));
		} catch (error) {
			logger.error(error);
			yield put(actions.setLoading(false));
		}
	},
};

export const watcher = function* w() {
	yield takeLatest(GET_USERS, sagas.getUsers);
	yield takeLatest(GET_USERS_BY_ID, sagas.getUsersById);
	yield takeLatest(GET_MONTHLY_DATA_USER, sagas.getMonthlyDataUser);
	yield takeLatest(GET_STATISTIK_DATA_USER, sagas.getStatistikDataUser);
	yield takeLatest(GET_STATISTIK_STATUS_COUNT, sagas.getStatistikStatusCount);
	yield takeLatest(FETCH_SINGLE_USER, sagas.getSingleUser);
	yield takeLatest(FETCH_LOGGED_USER, sagas.getLoggedUser);
	yield takeLatest(GET_USERS_DROPDOWN, sagas.getUserDropdown);
	yield takeLatest(GET_USERS_BY_TENANT, sagas.getUsersByTenant);
	yield takeLatest(GET_USERS_SP, sagas.getUsersSp);
	yield takeLatest(GET_EMPLOYEE_SP, sagas.getEmployeeSp);
	yield takeLatest(GET_USERS_NO_TEAM, sagas.getUsersNoTeam);
	yield takeLatest(GET_AVAILABLE_SUPERVISORS, sagas.getAvailableSupervisors);
	yield takeLatest(GET_USERS_BY_LEADER_ID, sagas.getUsersByLeaderId);
	yield takeLatest(GET_USER_ACTIVITIES, sagas.getUserActivities);
};
