import React, {useEffect, useState} from "react";
import DataGridTable from "@src/common/table/DataGridTable";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {actions as orderStatusAction} from "@sagas/orders/statuses";
import {actions as editOrderActions} from "@sagas/orders/editOrder";
import {actions as orderActions} from "@sagas/orders";
import {ToastInfoComponent} from "@src/common/ToastComponent/ToastComponent";
import Modal from "@common/modal";
import AddPrice from "@src/components/dashboard/orders/AddPrice";
import CreateEditOrder from "@src/components/dashboard/orders/createEditOrder";
import {actions as userActions} from "@sagas/users/users";
import EditOrder from "@src/components/dashboard/orders/createEditOrder/EditOrder";
import {actionsFormatter, columns} from "./helper";

const BottomTableStatistik = ({
	editSize,
	editPage,
	toggleEditOrderModal,
	getOrderPreview,
	downloadOrderById,
	showEditModal,
	cleanQuery,
	showTimeline,
	userState,
	getOrderStatuses,
}) => {
	const {loading, page, size, statistikDataUser, totalPages, totalSize} = userState;
	const {t} = useTranslation();
	const [selectedPds, setSelectedPds] = useState([]);
	const [previewRow, setPreviewRow] = useState(false);
	const [showPriceModal, setShowPriceModal] = useState(false);
	const [priceRow, setCurrentPriceRow] = useState(null);
	const [reEditPage, setReEditPage] = useState(true); // State for re-editing page
	const handlePreviewEdit = (id, type) => {
		setPreviewRow(type === "preview");
		toggleEditOrderModal(true);
		getOrderPreview(id);
	};
	const handlePrice = (id) => {
		setShowPriceModal(true);
		setCurrentPriceRow(id);
	};
	const downloadCsv = async (data, type) => {
		if (type === "single") {
			if (data.exportPdf && data.fileNames?.[0]) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "multiple") {
			const filteredData = statistikDataUser?.filter(
				(item) => item?.exportPdf && data?.includes(item.id) && item?.fileNames?.[0],
			);
			if (filteredData.length > 0) {
				downloadOrderById({filteredData, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}
		} else if (type === "homeNetMultiplePdf") {
			if (data.exportPdf && data.fileNames && data.fileNames.length > 0) {
				downloadOrderById({data, type, pdfs: selectedPds});
			} else {
				ToastInfoComponent(t("NoPdfsAvailable"));
			}

			setSelectedPds([]);
		}
	};
	const actions = {
		key: "actions",
		name: t("Actions"),
		formatter: ({row}) =>
			actionsFormatter(row, handlePreviewEdit, handlePrice, downloadCsv, setSelectedPds),
		sortable: false,
		width: 50,
	};

	useEffect(() => {
		getOrderStatuses();
		return () => {
			cleanQuery();
		};
	}, []);
	return (
		<>
			<Modal
				className="add_edit_user"
				open={showPriceModal}
				handleClose={() => setShowPriceModal(false)}
			>
				<AddPrice setShowPriceModal={setShowPriceModal} currentRow={priceRow} />
			</Modal>
			{previewRow ? (
				<Modal
					open={showEditModal}
					handleClose={() => toggleEditOrderModal(false)}
					disableEnforceFocus
					className="add_edit_user preview"
					width={showTimeline ? 1000 : 600}
					positionModal="right"
					overflow="hidden"
					border={previewRow && "none"}
					padding={previewRow && 0}
					display={previewRow && "flex"}
				>
					<CreateEditOrder
						setShowEditModal={toggleEditOrderModal}
						previewMode={previewRow}
						setReEditPage={setReEditPage}
					/>
				</Modal>
			) : (
				<EditOrder open={showEditModal} onClose={() => toggleEditOrderModal(false)} />
			)}
			<div className="loader__and__table-orderspage">
				<DataGridTable
					columns={[...columns, actions]?.map((col) => ({
						...col,
						resizable: col.key !== "actions",
						headerCellClass: "headerStyles",
					}))}
					data={statistikDataUser || []}
					rows={statistikDataUser || []}
					enableCellSelect={false}
					loading={loading}
					size={size}
					page={page}
					totalPages={totalPages}
					totalSize={totalSize}
					pagination
					setReEditPage={setReEditPage}
					reEditPage={reEditPage}
					editSize={editSize}
					editPage={editPage}
					showEditModal={false}
					previewRow={false}
				/>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	userState: state.app.users.index,
	showEditModal: state.app.orders.edit.showModal,
	showTimeline: state.app.orders.edit.showTimeline,
});

const mapDispatchToProps = {
	getOrderPreview: orderActions.getOrderPreview,
	downloadOrderById: orderActions.downloadOrderById,
	toggleEditOrderModal: editOrderActions.toggleModal,
	editSize: userActions.editSize,
	editPage: userActions.editPage,
	getOrderStatuses: orderStatusAction.getOrderStatuses,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BottomTableStatistik));
