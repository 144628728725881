/* eslint-disable no-await-in-loop */
import axios from "@utils/axios";

const defaultConsumptions = [
	{
		from: 1735689600000,
		to: 1743465599999,
		consumptionMin: 1,
		consumptionMax: 10000,
		price: 100,
		isBestand: false,
	},
	{
		from: 1735689600000,
		to: 1743465599999,
		consumptionMin: 10001,
		consumptionMax: 100000,
		price: 200,
		isBestand: false,
	},
];

async function createTariffsAndConsumptions({tariffs, clientId, id, setModal, setLoading}) {
	try {
		for (let i = 0; i < tariffs.length; i++) {
			const tariff = tariffs[i];
			const {data} = await axios.post("/tariff_commission/create", {
				name: tariff?.name,
				cancellationReserve: "0",
				tariffPrice: 0,
				points: 0,
				clientType: null,
				energyType: null,
				client: clientId,
				clientId: id,
				tables: [],
				cancellationReserveType: "PERCENTAGE",
				externalId: tariff?.id,
			});
			const tariffId = data?.data?.id;
			const modifiedConsumptionsArr = defaultConsumptions?.map((item) => ({
				...item,
				tariffId,
			}));
			for (let j = 0; j < modifiedConsumptionsArr.length; j++) {
				const item = modifiedConsumptionsArr[j];
				await axios.post("/v1/energy/tariff-history/create", item);
			}
		}
	} catch (error) {
		return error;
	} finally {
		setModal(false);
		if (setLoading) {
			setLoading(false);
		}
	}
}

export default async function generateTariffs({
	tariffs,
	clientId,
	id,
	setModal,
	setLoading,
	callback,
}) {
	if (setLoading) {
		setLoading(true);
	}
	try {
		await createTariffsAndConsumptions({
			tariffs,
			clientId,
			id,
			setModal,
			setLoading,
		});
		if (callback) {
			callback();
		}
	} catch (error) {
		return error;
	} finally {
		setModal(false);
		if (setLoading) {
			setLoading(false);
		}
	}
}
