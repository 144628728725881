/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import LocationAccordion from "@common/LocationAccordion";
import {actions as locationACtions} from "@sagas/locations";
import {actions as projectCategoryACtions} from "@sagas/projectCategories";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import EditableTable from "@common/editableTable";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import InputComponent from "@common/input";
import {actions as clientActions} from "@sagas/client";
import {actions as createTariffByLocationsActions} from "@sagas/tariff/tariffByLocations/create";
import {actions as tariffHistoryActions} from "@sagas/tariff/tariffByLocations/history";
import {actions as locationTariffActions} from "@sagas/tariff/tariffByLocations/index";
import DataGrid from "react-data-grid";
import {actions as navigation} from "@sagas/navigation";
import PageList from "@common/PageList";
import NavigationPrompt from "react-router-navigation-prompt";
import {ToastErrorComponent} from "@common/ToastComponent/ToastComponent";
import Button from "@common/components/button";
import BackButtonComponents from "@src/common/BackButton";
import {useUrlParams} from "@src/utils/useUrlParams";
import moment from "moment";
import Form from "@src/common/Form";
import CustomPopover from "@src/common/Popover";
import {DeleteRowIcon, EditRowIcon, PlusCircleIcon, ThreeDotsIcon} from "@src/assets/SvgComponents";
import {ENDPOINT} from "@src/utils/endpoint";
import DeleteModal from "@src/common/modal/deleteModal/deleteModal";
import {getValueInMulitpleConditions} from "@src/utils/getValueInMulitpleConditions";
import CustomSelect from "@src/common/Select";
import {
	cancellationType,
	clientTypes,
	consumptionInputs,
	energyTypes,
	tariffHistoryColumns,
	validationSchema,
} from "./helper";
import plusLogoColor from "../../../assets/img/plusIconBackground.svg";
import LeavePageAlert from "../Trainings/Add/LeavePageAlert";
import saveIcon from "../../../assets/img/save-icon.svg";
import CreateTarrifTable from "./CreateTarrifTable";

function CreateTariff(props) {
	const {
		fetchLocations,
		match,
		fetchProjectCategories,
		setLocationId,
		createTariff,
		singleTariff,
		getTariffByLocations,
		cleanSingleTariffByLocations,
		cleanDeletedTableByTariff,
		cleanInitialValues,
		navigate,
		editSize,
		cleanTarrifState,
		getDeletedTableByTariff,
		deletedDataTable,
		projectCategories,
		clearTarriffTable,
		fillTarriffTable,
		client,
		getClient,
		getTariffHistory,
		tariffHistories,
		clearTariffHistory,
		createTariffHistory,
		deleteTariffHistory,
		modalLoading,
		tariffByLocationsState,
	} = props;
	const {tarrifTable, tariffIntialValues, loading} = tariffByLocationsState;
	const {t} = useTranslation();
	const {getUrlParams, setModal} = useUrlParams();
	const [consumptionDates, setConsumptionDates] = useState({
		from: null,
		to: null,
	});
	const {modal, id} = getUrlParams("modal", "id");
	const copy = modal === "copy";
	const [canNav, setCanNav] = useState(true);
	const isEnergyClient = client?.clientContractType === "ENERGY";
	const isDistributionModel = client?.clientPayoutModel === "DISTRIBUTION_MODEL";
	useEffect(() => {
		// set size to this number when we fetch locations in create tariff
		// because we have pagination in this endpoint
		editSize(1000);
		fetchLocations(match.params.id);
		fetchProjectCategories(match.params.id);
		getClient(match?.params?.id);
		getDeletedTableByTariff(match.params.tariffId);

		// set size to 10 after we leave this page
		return () => {
			editSize(10);
			cleanTarrifState();
		};
	}, []);

	useEffect(() => {
		if (tarrifTable?.length > 0) {
			if (id) {
				const foundedTable = tarrifTable?.find((table) => table.tableUUID === `${id}`);
				if (modal === "copy") {
					fillTarriffTable({
						...foundedTable,
						from: null,
						to: null,
						tableName: `Copy of ${foundedTable?.tableName}`,
					});
				} else if (modal !== "copyModal") {
					const index = tarrifTable?.findIndex((table) => table.tableUUID === `${id}`);
					fillTarriffTable({...foundedTable, index});
				}
			} else {
				clearTarriffTable();
			}
		}
	}, [tarrifTable, modal, id]);

	useEffect(
		() => () => {
			cleanDeletedTableByTariff();
			cleanSingleTariffByLocations();
			cleanInitialValues();
			clearTariffHistory();
		},
		[match.params.tariffId],
	);

	useEffect(() => {
		if (match.params.tariffId) {
			if (!isEnergyClient) {
				getTariffByLocations(match.params.tariffId);
			} else {
				getTariffHistory(match.params.tariffId);
			}
		}
	}, [isEnergyClient, match.params.tariffId]);

	const handleGoBack = () =>
		navigate(
			`/admin/clients/${match?.params?.id}/${match?.params?.clientId}?tab=tariff&table-tab=active&query=%7B"search"%3A""%7D`,
		);

	const handleSubmitTariff = ({values}) => {
		const obj = {
			...values,

			clientId: +match?.params?.id,
			tariffId: +match?.params.tariffId || undefined,
			client: match?.params?.clientId,
			handleGoBack,
		};
		createTariff(obj);
		setModal(false);
		setCanNav(false);
	};

	const pageList = [
		{
			name: t("ClientsList"),
			fn: () => navigate(`/admin/clients`),
		},
		{
			name: match?.params?.clientId,
			fn: () => handleGoBack(),
		},
		{
			name: tariffIntialValues?.isEditing ? tariffIntialValues?.name : "Add Tariff",
			fn: () => null,
		},
	];
	const handleShowModal = () => {
		if (isEnergyClient) {
			setModal("consumption");
		} else if (projectCategories?.length > 0) {
			setModal("table");
		} else {
			ToastErrorComponent(t("AddProjectCategories"));
		}
	};
	const formData = {
		inputs: consumptionInputs?.map((item) => {
			if (item.inputType === "rangePicker") {
				return {
					...item,
					defaultValue: [consumptionDates?.from, consumptionDates?.to],
					disabled: [!!consumptionDates?.from, !!consumptionDates?.to],
				};
			}
			return item;
		}),
		title: getValueInMulitpleConditions({
			baseCondition: {condition: id, value: t("EditConsumption")},
			secondCondition: {
				condition: !!consumptionDates?.from && !!consumptionDates?.to,
				value: t("AddRowToConsumption"),
			},
			defaultValue: t("CreateConsumption"),
		}),
		id,
		getUrl: ENDPOINT.FETCH_SINGLE_TARIFF_HISTORY,
		submitFn: (values) =>
			createTariffHistory({
				values: {...values, tariffId: +match.params.tariffId},
				setModal,
			}),
	};
	const handleAction = (type, id) => {
		if (type === "edit") {
			setModal("consumption", id);
		} else {
			setModal("deleteConsumption", id);
		}
	};
	const actionFormatter = (row) => {
		const items = [
			{
				key: "edit",
				icon: <EditRowIcon stroke="#252525" />,
				label: t("EditRow"),
			},
			{
				key: "delete",
				icon: <DeleteRowIcon stroke="#252525" />,
				label: t("DeleteRow"),
			},
		];
		return (
			<CustomPopover
				menu={{items, onClick: ({key}) => handleAction(key, row?.id)}}
				customClass="popover__actions-wrapper"
			>
				<div className="three-dots-icon">
					<ThreeDotsIcon width="12.7px" height="2.7px" fill="#252525" />
				</div>
			</CustomPopover>
		);
	};
	const tariffHistoryColumnsWithActions = [
		...tariffHistoryColumns,
		{
			key: "",
			name: "Actions",
			cellClass: "borderLess__actions__cell",
			headerCellClass: "borderLess__actions__cell",
			formatter: ({row}) => actionFormatter(row),
			width: 50,
		},
	];
	const handleAddRow = (item) => {
		setConsumptionDates({
			from: item?.startDate,
			to: item?.endDate,
		});
		setModal("consumption");
	};
	const closeModal = () => {
		setModal(false);
		setConsumptionDates({from: null, to: null});
	};
	return (
		<>
			<DeleteModal
				open={modal === "deleteConsumption"}
				handleClose={closeModal}
				calculationModal
				newTitle="Are you sure you want to delete this Consumption?"
				confirm={() => deleteTariffHistory({id, setModal: closeModal})}
				setShowDeleteModal={closeModal}
				loading={modalLoading}
				disabled={modalLoading}
			/>
			<div className="create__tariff__page">
				<CreateTarrifTable
					setShowModal={closeModal}
					copy={copy}
					open={modal === "table" || modal === "copy"}
					id={id}
				/>
				<Form
					formData={formData}
					open={modal === "consumption"}
					handleClose={closeModal}
					loading={modalLoading}
				/>
				<Formik
					enableReinitialize
					initialValues={{
						...tariffIntialValues,
						tariffPrice: singleTariff.price,
						points: singleTariff.points,
					}}
					validationSchema={validationSchema}
					onSubmit={(values, actions) =>
						handleSubmitTariff({values, formActions: actions})
					}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<form
							className="edit__and__create__form"
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
						>
							<div className="create__tariff__page__top">
								<div className="back__button__create__user">
									<BackButtonComponents
										onClick={handleGoBack}
										className="back__button_client"
									/>
									<PageList
										list={pageList}
										newClass="create__tariff__page__top__site__map"
									/>
								</div>
								<div className="create__tariff__actions">
									<Button
										text={t("AddNewTable")}
										icon={plusLogoColor}
										handleChange={handleShowModal}
										leftRounded
										backgroundColor="#6C1EB0"
										smallButtonWithTooltip
										type="button"
									/>

									<Button
										text={t("Save")}
										disabled={loading}
										type="submit"
										rightRounded
										icon={saveIcon}
										smallButtonWithTooltip
										placement="bottom-end"
									/>
								</div>
							</div>
							<div className="create__tariff__inputs">
								<InputComponent
									name="name"
									placeholder={t("TariffName")}
									type="text"
									errorClass="errorClass"
									errors={errors.name}
									values={values?.name}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched.name}
								/>
								{!isEnergyClient && (
									<div className="create__tariff__inputs__tariffReserveTypeContent">
										<p className="form_input__label">
											{t("cancellationReserve")}
										</p>
										<CustomSelect
											options={cancellationType}
											onClear={() => setFieldValue("cancellationReserve", "")}
											onSelect={(value, option) =>
												setFieldValue("cancellationReserve", option)
											}
											showSearch
											allowClear
											value={values?.cancellationReserve}
											placeholder={t("AssignNewAdmin")}
											optionFilterProp="label"
											errors={errors?.cancellationReserve}
											touched={touched?.cancellationReserve}
										/>
									</div>
								)}
								<InputComponent
									name="tariffPrice"
									placeholder={t("TariffPrice")}
									type="number"
									errorClass="errorClass"
									errors={errors.tariffPrice}
									values={values?.tariffPrice}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched.tariffPrice}
								/>
								<InputComponent
									name="points"
									placeholder={t("Points")}
									type="number"
									errorClass="errorClass"
									errors={errors.points}
									values={values?.points}
									handleBlur={handleBlur}
									handleChange={handleChange}
									touched={touched.points}
								/>
								{isEnergyClient && !values?.isEditing && (
									<>
										<div className="create__tariff__inputs__tariffReserveTypeContent">
											<p className="form_input__label">{t("Client Type")}</p>
											<CustomSelect
												options={clientTypes}
												onClear={() => setFieldValue("clientType", "")}
												onSelect={(value, option) =>
													setFieldValue("clientType", option)
												}
												showSearch
												allowClear
												value={values?.clientType}
												placeholder={t("Client Type")}
												optionFilterProp="label"
												errors={errors?.clientType}
												touched={touched?.clientType}
											/>
										</div>
										<div className="create__tariff__inputs__tariffReserveTypeContent">
											<p className="form_input__label">{t("Energy Type")}</p>
											<CustomSelect
												options={energyTypes}
												onClear={() => setFieldValue("energyTypes", "")}
												onSelect={(value, option) =>
													setFieldValue("energyTypes", option)
												}
												showSearch
												allowClear
												value={values?.energyTypes}
												placeholder={t("Energy Type")}
												optionFilterProp="label"
												errors={errors?.energyTypes}
												touched={touched?.energyTypes}
											/>
										</div>
									</>
								)}
							</div>
						</form>
					)}
				</Formik>
				<div className="create__tariff__accordion">
					{(isEnergyClient ? [...tariffHistories] : [...tarrifTable])?.map(
						(item, index) => (
							<LocationAccordion
								expandable={!isDistributionModel || undefined}
								setShowModal={setModal}
								item={item}
								from={isEnergyClient ? null : moment(item.from).utc()}
								to={isEnergyClient ? null : moment(item.to).utc()}
								classNames="create__tariff__single__accordion"
								accordionHeaderClassNames="create__tariff__single__accordion header"
								accordionCommissionClassNames="create__tariff__single__accordion receivedCommission"
								accordionDatesClassNames="create__tariff__single__accordion dates"
								accordionLocationsClassNames="create__tariff__single__accordion locations"
								accordionItemsClassNames="create__tariff__single__accordion items"
								accordioniconsClassNames="create__tariff__single__accordion icons"
								locations={
									isEnergyClient ? [] : item?.locations || item?.locationNames
								}
								receivedCommission={item?.receivedCommission || 0}
								index={index}
								name={
									isEnergyClient
										? `${moment(item.startDate)
												.utc()
												.format("DD.MM.YYYY")} - ${moment(item.endDate)
												.utc()
												.format("DD.MM.YYYY")}`
										: item?.tableName
								}
								id={item.locations?.map((i) => i?.value)}
								setLocationId={setLocationId}
								disable={isEnergyClient}
								showReceivedCommission={!isEnergyClient}
							>
								{isEnergyClient ? (
									<>
										<DataGrid
											className="rdg-light"
											columns={tariffHistoryColumnsWithActions}
											rows={item?.data || []}
											enableCellSelect
											style={{height: item?.data?.length * 50 + 60}}
											rowHeight={44}
											headerRowHeight={50}
										/>
										<div
											className="add__new__row"
											onClick={() => handleAddRow(item)}
										>
											<div className="add__new__row-content">
												<span>
													<PlusCircleIcon />
												</span>
												<span>{t("addRow")}</span>
											</div>
										</div>
									</>
								) : (
									<EditableTable
										id={item?.id}
										data={item}
										index={index}
										locationId={item?.locations?.map((i) => i?.value)}
										clientId={match.params.id}
										disable={false}
									/>
								)}
							</LocationAccordion>
						),
					)}
					{deletedDataTable?.tables?.[0] && <h3>{t("DeactivatedTables")}</h3>}
					{deletedDataTable?.tables?.map((item, index) => (
						<LocationAccordion
							setShowModal={setModal}
							item={item}
							from={isEnergyClient ? null : moment(item.from).utc()}
							to={isEnergyClient ? null : moment(item.to).utc()}
							classNames="create__tariff__single__accordion"
							accordionHeaderClassNames="create__tariff__single__accordion header"
							accordionDatesClassNames="create__tariff__single__accordion dates"
							accordionLocationsClassNames="create__tariff__single__accordion locations"
							accordionItemsClassNames="create__tariff__single__accordion items"
							locations={item?.locations || item?.locationNames}
							index={index}
							name={item?.tableName}
							id={item.locations?.map((i) => i?.value)}
							setLocationId={setLocationId}
							disable
							showReceivedCommission={false}
						>
							<EditableTable
								id={item?.id}
								data={item}
								index={index}
								locationId={item?.locations?.map((i) => i?.value)}
								clientId={match.params.id}
								disable
							/>
						</LocationAccordion>
					))}
				</div>
				<NavigationPrompt
					renderIfNotActive
					when={(currentLoc, nextLocation) =>
						canNav && currentLoc.pathname !== nextLocation.pathname
					}
				>
					{({isActive, onCancel, onConfirm}) =>
						isActive && (
							<LeavePageAlert open handleClose={onCancel} handleAgree={onConfirm} />
						)
					}
				</NavigationPrompt>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	locations: state.app.locations.index.locations,
	projectCategories: state.app.projectCategories.index.optionsProjectCategories,
	singleTariff: state.app.tariffByLocations.index.singleTariff,
	tariffByLocationsState: state.app.tariffByLocations.create,
	client: state.app.client.index.client,
	deletedDataTable: state.app.tariffByLocations.index.deletedDataTable,
	tariffHistories: state.app.tariffByLocations.history.tariffHistories,
	modalLoading: state.app.tariffByLocations.history.modalLoading,
});

const mapDispatchToProps = {
	setLocationId: locationACtions.setLocationId,
	fetchLocations: locationACtions.fetchLocations,
	fetchProjectCategories: projectCategoryACtions.fetchProjectCategoriesByDropdown,
	createTariff: createTariffByLocationsActions.createTariff,
	cleanInitialValues: createTariffByLocationsActions.cleanTariffsInitialValues,
	cleanTarrifState: createTariffByLocationsActions.cleanTarrifState,
	getTariffByLocations: locationTariffActions.getSingleTariffByLocation,
	getDeletedTableByTariff: locationTariffActions.getDeletedTableByTariff,
	cleanSingleTariffByLocations: locationTariffActions.cleanSingleTariffByLocations,
	navigate: navigation.navigate,
	editSize: locationACtions.editSize,
	cleanDeletedTableByTariff: locationTariffActions.cleanDeletedTableByTariff,
	clearTarriffTable: createTariffByLocationsActions.clearTarriffTable,
	fillTarriffTable: createTariffByLocationsActions.fillTarriffTable,
	getClient: clientActions.fetchClient,
	getTariffHistory: tariffHistoryActions.fetchTariffHistory,
	clearTariffHistory: tariffHistoryActions.clearTariffHistory,
	createTariffHistory: tariffHistoryActions.createTariffHistory,
	deleteTariffHistory: tariffHistoryActions.deleteTariffHistory,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTariff));
