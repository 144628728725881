import {DATE_FORMAT} from "@common/constants";
import {EditRowIcon, HideRowIcon, ShowRowIcon} from "@src/assets/SvgComponents";
import StatusBox from "@src/common/components/status-box";
import DataGridTable from "@src/common/table/DataGridTable";
import moment from "moment";
import React from "react";
import HtmlTooltip from "@src/common/htmlTooltip";
import i18n from "../../../i18n";

const getPosition = ({row, type}) => {
	switch (true) {
		case row.trainer:
			return "Trainer";
		case row.internship:
			return "Internship";
		case row.headOfDepartment:
			return "HeadOfDepartment";
		case row.leader:
			return "TeamLeader";
		case type === "AGENCY":
			if (row?.isLeader) {
				return "Agency";
			}
			if (row?.isGroupLeader) {
				return "GroupLeader";
			}
			return "AgencySP";
		default:
			return "Employee";
	}
};

const teamDetailColumns = [
	{
		key: "firstName",
		name: i18n.t("FirstName"),
	},
	{
		key: "lastName",
		name: i18n.t("LastName"),
	},
	{
		key: "position",
		name: "Position",
		formatter: ({row}) => {
			const position = getPosition({
				row,
				type: row?.isAgency ? "AGENCY" : "TEAM",
			});
			return <StatusBox status={i18n.t(position)} />;
		},
	},
];

export const clientColumnsRemote = [
	{
		key: "expanded",
		name: "",
		minWidth: 30,
		cellClass: "custom__cell-expander",
		width: 30,
		colSpan(args) {
			return args.type === "ROW" && args.row.type === "DETAIL" ? 5 : undefined;
		},
		formatter({row, tabIndex, onRowChange}) {
			if (row.type === "DETAIL") {
				return (
					<DataGridTable
						stopPropagation
						wrapperStyles={{
							borderRadius: 0,
							paddingTop: 10,
						}}
						data={row.members}
						columns={teamDetailColumns}
						style={{
							height: row?.members?.length * 44 + 53,
						}}
					/>
				);
			}
			return (
				<div
					style={{
						float: "right",
						display: "table",
						blockSize: "100%",
					}}
				>
					<span
						onClick={() => {
							onRowChange({...row, expanded: !row?.expanded});
						}}
					>
						<span tabIndex={tabIndex}>
							{row.expanded ? <HideRowIcon /> : <ShowRowIcon />}
						</span>
					</span>
				</div>
			);
		},
	},
	{
		key: "name",
		name: i18n.t("Name"),
		resizable: true,
		sortable: true,
		formatter: ({row}) => <span>{row?.name ? row?.name : ""}</span>,
	},
	{
		key: "from",
		name: i18n.t("from"),
		resizable: true,
		sortable: true,
		formatter: ({row}) => <span>{row?.from ? moment(row?.from).format(DATE_FORMAT) : ""}</span>,
	},
	{
		key: "to",
		name: i18n.t("to"),
		sortable: true,
		formatter: ({row}) => <span>{row?.to ? moment(row?.to).format(DATE_FORMAT) : ""}</span>,
	},
];
export const clientColumnsRemoteAgency = (setModal) => [
	{
		key: "expanded",
		name: "",
		minWidth: 30,
		cellClass: "custom__cell-expander",
		width: 30,
		colSpan(args) {
			return args.type === "ROW" && args.row.type === "DETAIL" ? 3 : undefined;
		},
		formatter({row, tabIndex, onRowChange}) {
			if (row.type === "DETAIL") {
				const members = row?.members.map((item) => {
					if (item.id === row.teamLeaderId) {
						return {
							...item,
							isAgency: true,
							isLeader: true,
						};
					}
					return {
						...item,
						isAgency: true,
						isLeader: false,
					};
				});
				return (
					<DataGridTable
						stopPropagation
						data={members?.sort((a, b) => {
							if (a.isLeader === b.isLeader) {
								return 0;
							}
							return a.isLeader ? -1 : 1;
						})}
						wrapperStyles={{
							borderRadius: 0,
							paddingTop: 10,
						}}
						columns={[
							...teamDetailColumns,
							{
								key: "actions",
								name: i18n.t("Actions"),
								sortable: false,
								resizable: false,
								width: 50,
								formatter: ({row: detailRow}) => (
									<>
										{!detailRow.groupLeaderId && !detailRow.isLeader && (
											<HtmlTooltip
												title={<span>{i18n.t("EditAgencySP")}</span>}
												placement="bottom-end"
											>
												<span
													onClick={() =>
														setModal("agency", detailRow.id, [
															{agencyId: row?.parentId},
														])
													}
												>
													<EditRowIcon />
												</span>
											</HtmlTooltip>
										)}
									</>
								),
							},
						]}
						style={{
							height: row?.members?.length * 44 + 53,
						}}
					/>
				);
			}
			return (
				<div
					style={{
						float: "right",
						display: "table",
						blockSize: "100%",
					}}
				>
					<span
						onClick={() => {
							onRowChange({...row, expanded: !row?.expanded});
						}}
					>
						<span tabIndex={tabIndex}>
							{row.expanded ? <HideRowIcon /> : <ShowRowIcon />}
						</span>
					</span>
				</div>
			);
		},
	},
	{
		name: i18n.t("Name"),
		key: "name",
		sortable: true,
		formatter: ({row}) => <span>{row?.name}</span>,
	},
];
